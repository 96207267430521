// 芬兰语

let obj = {};
obj = {
  // 语言
  languageVersion: [
    'English',  // 英语
    '日本語', // 日语
    'Português', // 葡萄牙语
    '繁體中文', // 繁体中文
    'Français', // 法语
    'Deutsch', // 德语
    'Türk', // 土耳其语
    'Español', // 西班牙语
    'عربي', // 阿拉伯语
    '한국인', // 韩语
    'Suomi', // 芬兰语
    'Русский', // 俄语
    'italiano', // 意大利语
    'แบบไทย', // 泰语
    'Nederlands', // 荷兰语
  ],
  'footer': {
    home: "Koti",
  },
  'setDrawer': {
    label1: "Monikieliasetukset",
    label2: 'Rekisteröityminen myymälään',
    label3: 'Kirjautuminen kauppaan',
    label4: 'asiakaspalvelu',
    label5: 'Lataa Android-sovellus',
    label6: 'Lataa ios-sovellus',
    optionsBtn: 'Peruuta'
  },
  'appHome': {
    searchPlace: 'Hae...',
    recommendTitle: 'Suosittele',
    viewAll: 'näytä kaikki',
    usedTitle: 'Käytetty',
    shopTitle: "Kauppa",
    shopOption: "Näytä nyt",
    hotTitle: "Hot List",
    classTag1: 'Koti',
    classTag2: "Naiset",
    classTag3: "Miehet",
    classTag4: "Lapset",
    classTag5: "Elektroniikka",
    classTag6: 'Lemmikit',
    buyNow: "Osta nyt",

    // buyNow: 'Buy Now',
    // ranking: 'Sales Ranking',
    // preSaleTitle: 'Presale',
    // preSaleTabs1: 'Started',
    // preSaleTabs2: 'Not started',
    // preSaleTabs3: 'Ended',
    // groupTitle: 'Group Buying',
    // peopleGroupBuying: '人参与团购',
    // flashSaleTitle: 'Flash Sale',
  },
  'appSetLanguage': {
    navTitle: 'Monikieliset asetukset',
    affirm: 'vahvista',
  },
  "appService": {
    navTitle: 'Asiakaspalvelu',
    customerService: "Yksinomaista asiakaspalvelua",
    helloTitle: "Hei",
    welcomeTitle: 'Olen sinun<, voit ottaa minuun yhteyttä seuraavien kanavien kautta',
    iconInformation: 'Valitse viestintäohjelmisto konsultaatiota varten',
    whatsApp: 'whatsApp ',
    telegram: 'telegram ',
    line: 'line',
  },
  // 移动分类页
  'appClassification': {
    navTitle: 'Hyödykeluokitus',
    viewAll: 'Näytä kaikki',
  },
  'appHotList': {
    navTitle: 'Hot List',
    ranking: 'Myynnin ranking',
  },
  'appHotRanking': {
    navTitle: "Aksenttien myyntisijoitus",
    searchPlace: 'Hae...',
    payment: 'maksu',
    optionBtn1: 'Kerää',
    optionBtn2: "Osta nyt",
    errorMessage: 'Kirjaudu sisään',
  },
  'appUsedList': {
    navTitle: 'Käytetty',
    recommendNavTitle: 'Suosittele',
    classNavTitle: 'Luokittelu',
    searchNavTitle: 'Haku',
    searchPlace: 'Hae...',
    select1: 'Kattava',
    select2: 'Myyntivolyymi',
    select3: "Uusi tuote",
    select4: "Hinta korkeasta alhaiseen",
    select5: 'Hinta matalasta korkeaan',
    select6: 'Pistemäärä',
    classAll: "Kaikki",
    listTag1: 'Ilmainen toimitus',
    paymentTitle: 'Maksu'
  },
  'appRecommendList': {
    navTitle: 'Suosittele',
    searchPlace: 'Hae...',
    select1: 'Kattava',
    select2: 'Myyntivolyymi',
    select3: "Uusi tuote",
    select4: "Hinta korkeasta alhaiseen",
    select5: 'Hinta matalasta korkeaan',
    select6: 'Pistemäärä',
    classAll: "Kaikki",
    listTag1: 'Ilmainen toimitus',
    paymentTitle: 'Maksu'
  },
  'appLogin': {
    navTitle: 'Kirjaudu',
    navTitleHint: "Kirjaudu nyt ja aloita kaupankäynti ystävien kanssa kaikkialta maailmasta!",
    singUp: "Rekisteröidy",
    emailLabel: 'Puhelinnumero',
    emailPlaceholder: 'Anna puhelinnumero',
    emailErrorText: 'Virhekehote',
    emailNullVerify: 'Syötä puhelinnumero',//请输入邮箱
    passwordLabel: 'Salasanasi',
    passPlaceholder: "Syötä salasana",
    passNullVerify: "Anna salasanasi",//请输入密码
    passErrorVerify: 'Salasana on alle 6 merkkiä',//密码小于6位
    placeholder: 'Please Input',
    emailRegistration: 'Puhelinnumeron rekisteröinti',
    forgetPassTitle: 'Unohditko salasanan?',
    submitBtnTitle: "Kirjaudu sisään",
    errorMessage: "Väärä käyttäjätunnus tai salasana",
    // emailErrorVerify: '邮箱格式错误！',//邮箱格式错误
  },
  'appForgotPass': {
    navTitle: 'Unohtunut salasana',
    navTitleHint: 'Kirjoita muistiin matkapuhelinnumerosi saadaksesi vahvistuskoodin uuden salasanan asettamista varten.',
    emailLabel: 'Puhelinnumero',
    emailPlaceholder: 'Anna puhelinnumero',
    emailErrorText: 'Syötä puhelinnumero',//请输入邮箱
    emailNullVerify: 'Syötä puhelinnumero',//请输入邮箱
    emailErrorVerify: "Väärä matkapuhelinnumero",//邮箱格式错误
    verificationCode: 'Vahvistuskoodi',
    verificationPlace: "Vahvistuskoodi",
    verificationCodeError: 'Anna vahvistuskoodi',
    sendTitle: 'Vahvista matkapuhelinnumero',
    sendText: 'Revalidation',
    passwordLabel: 'Salasana',
    passPlaceholder: "Syötä salasana",
    confirmPassLabel: 'Vahvista salasana',
    confirmPlace: 'paikkamerkki, vahvista salasana',
    passNullVerify: "Anna salasanasi",//请输入密码
    passErrorVerify: 'Salasana on alle 6 merkkiä',//密码小于6位
    passCompareErrorVerify: 'Salasanat ovat epäjohdonmukaisia',
    passCompareErrorNull: 'Anna salasana uudelleen',
    emailRegistration: 'Puhelinnumeron rekisteröinti',
    forgetPassTitle: 'Unohditko salasanan?',
    submitBtnTitle: 'Palauta salasana',
    submitErrorMessage: "Väärä vahvistuskoodi"
  },
  'appRegister': {
    navTitle: 'Rekisteröidy',
    navTitleHint: "Luo tili, jotta pääset käyttämään kaikkia ominaisuuksia!",
    firstNameLabel: 'First Name',
    firstNamePlace: 'Syötä',
    firstNameErrorVerify: "Anna etunimesi",
    lastNameLabel: 'Last Name',
    lastNameErrorVerify: "Anna sukunimesi",
    nickNameLabel: 'Niminimi',
    nickNameErrorVerify: 'Anna lempinimesi',
    namePlace: 'Syötä',
    gender: "Gender",
    gender1: "Poika",
    gender2: "tyttö",
    gender3: "Salaisuus",
    genderError: 'Anna sukupuolesi',
    emailLabel: 'Puhelinnumero',
    emailPlaceholder: 'Anna puhelinnumero!',
    emailErrorText: 'Virheilmoitus!',//请输入邮箱
    emailNullVerify: 'Anna puhelinnumero!',//请输入邮箱
    verificationCode: 'Vahvistuskoodi',
    verificationPlace: "Vahvistuskoodi",
    sendTitle: 'Vahvista matkapuhelinnumero',
    sendText: 'Revalidation',
    verificationCodeError: 'Vahvistuskoodi ei voi olla tyhjä',
    passwordLabel: 'Salasana',
    passPlaceholder: "Syötä salasana",
    confirmPassLabel: 'Vahvista salasana',
    confirmPlace: 'paikkamerkki, vahvista salasana',
    passNullVerify: 'kirjoita salasanasi',//请输入密码
    passErrorVerify: 'Salasana on alle 6 merkkiä！',//密码小于6位
    passCompareErrorVerify: 'Salasanat ovat epäjohdonmukaisia!',
    passCompareErrorNull: 'Anna salasana uudelleen',
    registerHint: 'Onko sinulla jo tili?',
    registerHintLogin: 'Kirjaudu',
    submitBtnTitle: "Rekisteröi",
    submitErrorMessage: "Väärä vahvistuskoodi"
    // emailErrorVerify: '邮箱格式错误!',//邮箱格式错误
  },
  "appShopList": {
    navTitle: 'Kauppalista',
    searchPlace: 'Hae...'
  },
  'appGoodsDetail': {
    salesTitle: 'Myynti',
    conditionLabel: 'Condition:',
    conditionContent: 'Category-Supportedpayments',
    agreement: "Ostajan suojasopimus",
    specSelect: 'Valitse tekniset tiedot',
    payLabel1: 'saldo',
    payLabel2: 'PayPal',
    payLabel3: 'Visa',
    commentTitle: "Kommentoi",
    frightLabel: "Rahti",
    frightContent: "Lähetä keskimäärin 8 päivässä",
    deliverTime: "Arvioi toimitusaika ja päivät",
    productQuantity: 'Quantity',
    soldQuantity: "Myyntimäärä",
    collections: "Tilauksen määrä",
    since: "Jäsen vuodesta",
    shipper: "Fast shipper",
    responder: "Nopea vastaus",
    reliable: "Luotettava",
    btnContact: 'Ota yhteyttä',
    enter: 'Enter',
    followBtn: 'Seuraa',
    guessLike: "Arvaa, että pidät siitä",
    buyNow: "Osta nyt",
    dialogOverviewTitle: 'Yleiskatsaus',
    overConditionLabel: 'kunto',
    overConditionContent: 'New',
    overBrandLabel: 'Brand',
    overCategoryLabel: 'Category',
    overPayMethodsLabel: "Tuetut maksut",
    payTitle: "Tuetut maksut",
    payHint: 'Väri on kaunis, se näyttää hyvältä ja tyylikkäältä. Voidaan käyttää kaikenlaisten vaatteiden kanssa. Se tekee siitä erittäin kauniin Se sopii täydellisesti rennosti ja muodikkaat vaatteet',
    overCategoryEnter: 'Enter',
    dialogFreightTitle: 'Rahti',
    dialogAgreementTitle: 'Ostajan suojasopimus',
    commitContent: "Järjestelmän oletusarvoinen kiitos",
    footerLeft: 'Lisää ostoskoriin',
    footerRight: "Osta nyt"
  },
  'specificationDialog': {
    inventory: "Inventory",
    countLabel: 'Number',
    specLabel: 'Oletus',
    options1: 'Lisää ostoskoriin',
    options2: "Osta nyt",

  },
  "appShopsDetail": {
    select1: 'Kattava',
    select2: 'Myyntivolyymi',
    select4: "Hinta korkeasta alhaiseen",
    select5: 'Hinta matalasta korkeaan',
    select6: 'Pistemäärä',
    follow: 'Seuraa',
    timeDay: 'Arvioi toimitusaika ja päivät',
    productQuantity: 'Quantity',
    shippedQuantity: "Myynnin määrä",
    collections: "Tilauksen määrä",
    commodity: 'tavara',
    classAll: "Kaikki"
  },
  // 产品评论页
  'appProductReviews': {
    navTitle: 'Kommentoi',
    tags1: 'kaikki',
    tags2: '5 tähteä',
    tags3: '4 tähteä',
    tags4: '3 tähteä',
    tags5: '2 tähteä',
    tags6: '1 tähti',
    tags7: 'median kanssa',
    commitContent: "Järjestelmän oletusarvoinen kiitos"
  },
  // app升级配置文件语言
  configLanguage: {
    upgradePrompt: 'Version päivitys, vahvista? ',
    loadingTitle: 'Ladataan...',
    systemUpgrade: "Järjestelmän päivitys",
    upgradeImmediately: 'Vahvista',
  },
  // 公共提示
  messageLanguage: {
     loadingTitle: "Ladataan", // Ladataan
     finishedText: "Ei mitään", //Ei mitään
     loadingText: "Ladataan", // Ladataan
     nullText: "Ei vielä tietoja",
     loadingFinished: "Lataus valmis",
     listNullText: "Ei enää",
     pullIngText: 'Vedä alas päivittääksesi',
     loosingText: 'Release to refresh',
     pullingSuccess: 'Vedä lataus onnistui'
  },
  // 'appSearchHistory': {
  //   navTitle: 'Search',
  //   searchPlace: 'Search...',
  //   recommendTitle: 'Recommend'
  // },
  // 'appClassList': {
  //   searchPlace: 'Search...',
  //   select1: 'Comprehensive',
  //   select2: 'Sales Volume',
  //   select3: 'New product',
  //   select4: 'Price High to Low',
  //   select5: 'Price Low to High',
  //   select6: 'Score',
  //   paymentTitle: 'payment',
  //   listTag1: 'Free Shipping',
  //   favoriteTitle: 'Favorite Quantity &',
  //   shopBtn: 'View Closets',
  //   recommendNavTitle: 'Recommend',
  //   usedNavTitle: 'Used',
  //   shopNavTitle: 'Shop'
  // },

  // 'appPreSale': {
  //   navTitle: 'Presale',
  //   tabs1: 'Started',
  //   tabs2: 'Not Started',
  //   tabs3: 'Ended',
  //   searchPlace: 'Search...',
  //   select1: 'Comprehensive',
  //   select2: 'Sales Volume',
  //   select3: 'New product',
  //   select4: 'Price High to Low',
  //   select5: 'Price Low to High',
  //   select6: 'Score',

  //   preSale: 'Presale',
  //   freeShipping: 'Free Shipping',
  //   payment: 'payment',
  //   preSale: 'presale',
  //   freeShipping: "Free Shipping"
  // },
  // "appGroupBuying": {
  //   navTitle: 'Group Buying',
  //   searchPlace: 'Search...',
  //   select1: 'Comprehensive',
  //   select2: 'Sales Volume',
  //   select3: 'New product',
  //   select4: 'Price High to Low',
  //   select5: 'Price Low to High',
  //   select6: 'Score',

  //   preSale: 'Presale',
  //   freeShipping: 'Free Shipping',
  //   payment: 'payment',
  //   btn1: 'Group Buying',
  //   btn2: "Free Shipping"
  // },
  // "appFlashSale": {
  //   navTitle: 'Flash Sale',
  //   searchPlace: 'Search...',
  //   select1: 'Comprehensive',
  //   select2: 'Sales Volume',
  //   select3: 'New product',
  //   select4: 'Price High to Low',
  //   select5: 'Price Low to High',
  //   select6: 'Score',

  //   preSale: 'Presale',
  //   freeShipping: 'Free Shipping',
  //   payment: 'payment',
  //   preSale: 'presale',
  //   freeShipping: "Free Shipping"
  // },
};
export default obj;
