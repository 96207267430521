// 日语
let obj = {};
obj = {
    // 语言
    languageVersion: [
        'English',  // 英语
        '日本語', // 日语
        'Português', // 葡萄牙语
        '繁體中文', // 繁体中文
        'Français', // 法语
        'Deutsch', // 德语
        'Türk', // 土耳其语
        'Español', // 西班牙语
        'عربي', // 阿拉伯语
        '한국인', // 韩语
        'Suomi', // 芬兰语
        'Русский', // 俄语
        'italiano', // 意大利语
        'แบบไทย', // 泰语
        'Nederlands', // 荷兰语
    ],
    'footer': {
        home: "家",
    },
    'setDrawer': {
        label1: '多言語設定',
        label2: 'ストア登録',
        label3: 'ストアログイン',
        label4: 'カスタマーサービス',
        label5: 'Android アプリケーションをダウンロード',
        label6: 'iOS アプリケーションをダウンロード',
        optionsBtn: 'キャンセル'
    },
    'appHome': {
        searchPlace: '検索...',
        recommendTitle: 'おすすめ',
        viewAll: 'すべて表示',
        usedTitle: '中古',
        shopTitle: 'ショップ',
        shopOption: '今すぐ表示',
        hotTitle: 'ホットリスト',
        classTag1: 'ホーム',
        classTag2: '女性',
        classTag3: '男性',
        classTag4: '子供',
        classTag5: 'エレクトロニクス',
        classTag6: 'ペット',
        buyNow: '今すぐ購入',

        // ranking: 'Sales Ranking',
        // preSaleTitle: 'Presale',
        // preSaleTabs1: 'Started',
        // preSaleTabs2: 'Not started',
        // preSaleTabs3: 'Ended',
        // groupTitle: 'Group Buying',
        // peopleGroupBuying: '人参与团购',
        // flashSaleTitle: 'Flash Sale',
    },
    'appSetLanguage': {
        navTitle: '多言語設定',
        affirm: '確認する',
    },
    "appService": {
        navTitle: 'カスタマーサービス',
        customerService: '専用のカスタマーサービス',
        helloTitle: 'こんにちは',
        welcomeTitle: '私はあなたのものです<,次のチャネルを通じて私に連絡できます',
        iconInformation: '相談する通信ソフトウェアを選択してください',
        whatsApp: 'whatsApp ',
        telegram: 'telegram ',
        line: 'line',
    },
    // 移动分类页
    'appClassification': {
        navTitle: '商品分類',
        viewAll: 'すべて表示',
    },
    'appHotList': {
        navTitle: 'ホットリスト',
        ranking: '売上ランキング',
    },
    'appHotRanking': {
        navTitle: 'アクセント売上ランキング',
        searchPlace: '検索...',
        payment: '支払い',
        optionBtn1: '収集',
        optionBtn2: '今すぐ購入',
        errorMessage: 'サインインしてください',
    },
    'appUsedList': {
        navTitle: '中古',
        recommendNavTitle: '推奨',
        classNavTitle: '分類',
        searchNavTitle: '検索',
        searchPlace: '検索...',
        select1: '総合',
        select2: '売上高',
        select3: '新製品',
        select4: '価格の高値から低値',
        select5: '価格の安い順',
        select6: 'スコア',
        classAll: 'すべて',
        listTag1: '送料無料',
        paymentTitle: '支払い'
    },
    'appRecommendList': {
        navTitle: 'おすすめ',
        searchPlace: '検索...',
        select1: '総合',
        select2: '売上高',
        select3: '新製品',
        select4: '価格の高値から低値',
        select5: '価格の安い順',
        select6: 'スコア',
        classAll: 'すべて',
        listTag1: '送料無料',
        paymentTitle: '支払い'
    },
    'appLogin': {
        navTitle: 'ログイン',
        navTitleHint: '今すぐログインして,世界中の友達と取引を始めましょう!',
        singUp: 'サインアップ',
        emailLabel: '電話番号',
        emailPlaceholder: '電話番号を入力してください',
        emailErrorText: 'エラー プロンプト',
        emailNullVerify: '電話番号を入力してください',//请输入邮箱
        passwordLabel: 'あなたのパスワード',
        passPlaceholder: 'パスワードを入力してください',
        passNullVerify: 'パスワードを入力してください',//请输入密码
        passErrorVerify: 'パスワードは 6 文字未満です',//秘密コード小≦6 桁
        placeholder: '入力してください',
        emailRegistration: '電話番号登録',
        forgetPassTitle: 'パスワードをお忘れですか?',
        submitBtnTitle: 'ログイン',
        errorMessage: 'ユーザー名またはパスワードが正しくありません',
        // emailErrorVerify: '邮箱格式错误！',//邮箱格式错误
    },
    'appForgotPass': {
        navTitle: 'パスワードを忘れた場合',
        navTitleHint: '新しいパスワードを設定するための確認コードを受け取るために,携帯電話番号を書き留めてください。',
        emailLabel: '電話番号',
        emailPlaceholder: '電話番号を入力してください',
        emailErrorText: '電話番号を入力してください',//请输入邮箱
        emailNullVerify: '電話番号を入力してください',//请输入邮箱
        emailErrorVerify: '携帯電話番号が違います',//邮箱格式错误
        verificationCode: '検証コード',
        verificationPlace: '検証コード',
        verificationCodeError: '確認コードを入力してください',
        sendTitle: '携帯電話番号を確認',
        sendText: '再検証',
        passwordLabel: 'パスワード',
        passPlaceholder: 'パスワードを入力してください',
        confirmPassLabel: 'パスワードの確認',
        confirmPlace: 'プレースホルダー確認パスワード',
        passNullVerify: 'パスワードを入力してください',//请输入密码
        passErrorVerify: 'パスワードは 6 文字未満です',//秘密コード小未満 6 桁
        passCompareErrorVerify: 'パスワードが一致しません',
        passCompareErrorNull: 'パスワードをもう一度入力してください',
        emailRegistration: '電話番号登録',
        forgetPassTitle: 'パスワードをお忘れですか?',
        submitBtnTitle: 'パスワードをリセット',
        submitErrorMessage: '検証コードが正しくありません'
    },
    'appRegister': {
        navTitle: '登録',
        navTitleHint: 'すべての機能にアクセスするにはアカウントを作成してください!',
        firstNameLabel: '名',
        firstNamePlace: '入力してください',
        firstNameErrorVerify: '名を入力してください',
        lastNameLabel: '姓',
        lastNameErrorVerify: '姓を入力してください',
        nickNameLabel: 'ニックネーム',
        nickNameErrorVerify: 'ニックネームを入力してください',
        namePlace: '入力してください',
        gender: '性別',
        gender1: '男の子',
        gender2: '女の子',
        gender3: '秘密',
        genderError: '性別を入力してください',
        emailLabel: '電話番号',
        emailPlaceholder: '電話番号を入力してください!',
        emailErrorText: 'エラー メッセージ!',//请输入邮箱
        emailNullVerify: '電話番号を入力してください!',//请输入邮箱
        verificationCode: '検証コード',
        verificationPlace: '検証コード',
        sendTitle: '携帯電話番号を確認',
        sendText: '再検証',
        verificationCodeError: '検証コードを空にすることはできません',
        passwordLabel: 'パスワード',
        passPlaceholder: 'パスワードを入力してください',
        confirmPassLabel: 'パスワードの確認',
        confirmPlace: 'プレースホルダー確認パスワード',
        passNullVerify: 'パスワードを入力してください',//请输入密码
        passErrorVerify: 'パスワードは 6 文字未満です！',//暗号小未満 6 桁
        passCompareErrorVerify: 'パスワードが一致しません！',
        passCompareErrorNull: 'パスワードをもう一度入力してください',
        registerHint: 'すでにアカウントをお持ちですか?',
        registerHintLogin: 'ログイン',
        submitBtnTitle: '登録',
        submitErrorMessage: '検証コードが正しくありません'
        // emailErrorVerify: '邮箱格式错误!',//邮箱格式错误
    },
    "appShopList": {
        navTitle: 'ショップリスト',
        searchPlace: '検索...'
    },
    'appGoodsDetail': {
        salesTitle: '販売',
        conditionLabel: '条件:',
        conditionContent: 'カテゴリでサポートされる支払い',
        agreement: '購入者保護契約',
        specSelect: '仕様を選択',
        payLabel1: '残高',
        payLabel2: 'ペイパル',
        payLabel3: 'ビザ',
        commentTitle: 'コメント',
        frightLabel: '貨物',
        frightContent: '平均 8 日以内に発送',
        deliverTime: '配達時間と日数を評価する',
        productQuantity: '数量 ',
        soldQuantity: "販売数量",
        collections: '注文数量',
        since: '以降のメンバー',
        shipper: '高速配送業者',
        responder: '高速応答者',
        reliable: '信頼できる',
        btnContact: '連絡先',
        enter: '入力',
        followBtn: 'フォロー',
        guessLike: '気に入ったと思います',
        buyNow: '今すぐ購入',
        dialogOverviewTitle: '概要',
        overConditionLabel: '条件',
        overConditionContent: '新規',
        overBrandLabel: 'ブランド',
        overCategoryLabel: 'カテゴリ',
        overPayMethodsLabel: 'サポートされている支払い',
        payTitle: 'サポートされている支払い',
        payHint: 'この色はとても上品でおしゃれです。幅広い服に合わせることができます。とても美しく,カジュアルでファッショナブルな服と合わせるのに最適です。',
        overCategoryEnter: 'Enter',
        dialogFreightTitle: '貨物',
        dialogAgreementTitle: '購入者保護契約',
        commitContent: 'システムのデフォルトの賞賛',
        footerLeft: 'カートに追加',
        footerRight: '今すぐ購入'
    },
    'specificationDialog': {
        inventory: '在庫',
        countLabel: '数値',
        specLabel: 'デフォルト',
        options1: 'カートに追加',
        options2: '今すぐ購入'


    },
    "appShopsDetail": {
        select1: '総合',
        select2: '売上高',
        select4: '価格の高値から低値',
        select5: '価格の安い順',
        select6: 'スコア',
        follow: 'フォローする',
        timeDay: '配達時間と日数を評価',
        productQuantity: '数量 ',
        shippedQuantity: "販売数量",
        collections: '注文数量',
        commodity: '商品',
        classAll: 'すべて'
    },
    // 产品评论页
    'appProductReviews': {
        navTitle: 'コメント',
        tags1: 'すべて',
        tags2: '5つ星',
        tags3: '4つ星',
        tags4: '3つ星',
        tags5: '2つ星',
        tags6: '1 つ星',
        tags7: 'メディアあり',
        commitContent: 'システムのデフォルトの賞賛'
    },
    // app升级配置文件语言
    configLanguage: {

        upgradePrompt: 'バージョンのアップグレード、確認してください? ',
        loadingTitle: 'ダウンロード中...',
        systemUpgrade: 'システム アップグレード',
        upgradeImmediately: '確認',
    },
    // 公共提示
    messageLanguage: {
        loadingTitle: "読み込み中", // 読み込み中
        finishedText: "None", // なし
        loadingText: "読み込み中", // 読み込み中
        nullText: 'まだデータがありません',
        loadingFinished: "読み込みが完了しました",
        listNullText: 'これ以上はありません',
        pullIngText: 'プルダウンして更新',
        loosingText: 'リリースして更新',
        pullingSuccess: 'プルダウンの読み込みに成功しました'
    },
    // 'appSearchHistory': {
    //   navTitle: 'Search',
    //   searchPlace: 'Search...',
    //   recommendTitle: 'Recommend'
    // },
    // 'appClassList': {
    //   searchPlace: 'Search...',
    //   select1: 'Comprehensive',
    //   select2: 'Sales Volume',
    //   select3: 'New product',
    //   select4: 'Price High to Low',
    //   select5: 'Price Low to High',
    //   select6: 'Score',
    //   paymentTitle: 'payment',
    //   listTag1: 'Free Shipping',
    //   favoriteTitle: 'Favorite Quantity &',
    //   shopBtn: 'View Closets',
    //   recommendNavTitle: 'Recommend',
    //   usedNavTitle: 'Used',
    //   shopNavTitle: 'Shop'
    // },

    // 'appPreSale': {
    //   navTitle: 'Presale',
    //   tabs1: 'Started',
    //   tabs2: 'Not Started',
    //   tabs3: 'Ended',
    //   searchPlace: 'Search...',
    //   select1: 'Comprehensive',
    //   select2: 'Sales Volume',
    //   select3: 'New product',
    //   select4: 'Price High to Low',
    //   select5: 'Price Low to High',
    //   select6: 'Score',

    //   preSale: 'Presale',
    //   freeShipping: 'Free Shipping',
    //   payment: 'payment',
    //   preSale: 'presale',
    //   freeShipping: "Free Shipping"
    // },
    // "appGroupBuying": {
    //   navTitle: 'Group Buying',
    //   searchPlace: 'Search...',
    //   select1: 'Comprehensive',
    //   select2: 'Sales Volume',
    //   select3: 'New product',
    //   select4: 'Price High to Low',
    //   select5: 'Price Low to High',
    //   select6: 'Score',

    //   preSale: 'Presale',
    //   freeShipping: 'Free Shipping',
    //   payment: 'payment',
    //   btn1: 'Group Buying',
    //   btn2: "Free Shipping"
    // },
    // "appFlashSale": {
    //   navTitle: 'Flash Sale',
    //   searchPlace: 'Search...',
    //   select1: 'Comprehensive',
    //   select2: 'Sales Volume',
    //   select3: 'New product',
    //   select4: 'Price High to Low',
    //   select5: 'Price Low to High',
    //   select6: 'Score',

    //   preSale: 'Presale',
    //   freeShipping: 'Free Shipping',
    //   payment: 'payment',
    //   preSale: 'presale',
    //   freeShipping: "Free Shipping"
    // },
};
export default obj;
