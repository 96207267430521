// 英文
let obj = {};
obj = {
  // 语言
  languageVersion: [
    'English',  // 英语
    '日本語', // 日语
    'Português', // 葡萄牙语
    '繁體中文', // 繁体中文
    'Français', // 法语
    'Deutsch', // 德语
    'Türk', // 土耳其语
    'Español', // 西班牙语
    'عربي', // 阿拉伯语
    '한국인', // 韩语
    'Suomi', // 芬兰语
    'Русский', // 俄语
    'italiano', // 意大利语
    'แบบไทย', // 泰语
    'Nederlands', // 荷兰语
  ],
  'footer': {
    home: "Home",
  },
  'setDrawer': {
    label1: 'Multi-language settings',
    label2: 'Store registration',
    label3: 'Store login',
    label4: 'Customer Service',
    label5: 'Download Android application',
    label6: 'Download ios application',
    optionsBtn: 'Cancel'
  },
  'appHome': {
    searchPlace: 'Search...',
    recommendTitle: 'Recommend',
    viewAll: 'view all',
    usedTitle: 'Used',
    shopTitle: 'Shop',
    shopOption: 'View Now',
    hotTitle: 'Hot List',
    classTag1: 'Home',
    classTag2: 'Women',
    classTag3: 'Men',
    classTag4: 'Kids',
    classTag5: 'Electronics',
    classTag6: 'Pets',
    buyNow: 'Buy Now',

    // ranking: 'Sales Ranking',
    // preSaleTitle: 'Presale',
    // preSaleTabs1: 'Started',
    // preSaleTabs2: 'Not started',
    // preSaleTabs3: 'Ended',
    // groupTitle: 'Group Buying',
    // peopleGroupBuying: '人参与团购',
    // flashSaleTitle: 'Flash Sale',
  },
  'appSetLanguage': {
    navTitle: 'Multi-language settings',
    affirm: 'confirm',
  },
  "appService": {
    navTitle: 'Customer Service',
    customerService: 'Exclusive customer service',
    helloTitle: 'Hello',
    welcomeTitle: 'I am yours<, you can contact me through the following channels',
    iconInformation: 'Please select communication software for consultation',
    whatsApp: 'whatsApp ',
    telegram: 'telegram ',
    line: 'line',
  },
  // 移动分类页
  'appClassification': {
    navTitle: 'Commodity Classification',
    viewAll: 'View All',
  },
  'appHotList': {
    navTitle: 'Hot List',
    ranking: 'Sales Ranking',
  },
  'appHotRanking': {
    navTitle: 'Sales Ranking',
    searchPlace: 'Search...',
    payment: 'payment',
    optionBtn1: 'Collect',
    optionBtn2: 'Buy Now',
    errorMessage: 'Please sign in',
  },
  'appUsedList': {
    navTitle: 'Used',
    recommendNavTitle: 'Recommend',
    classNavTitle: 'Classification',
    searchNavTitle: 'Search',
    searchPlace: 'Search...',
    select1: 'Comprehensive',
    select2: 'Sales Volume',
    select3: 'New product',
    select4: 'Price High to Low',
    select5: 'Price Low to High',
    select6: 'Score',
    classAll: 'All',
    listTag1: 'Free Shipping',
    paymentTitle: 'Payment'
  },
  'appRecommendList': {
    navTitle: 'Recommend',
    searchPlace: 'Search...',
    select1: 'Comprehensive',
    select2: 'Sales Volume',
    select3: 'New product',
    select4: 'Price High to Low',
    select5: 'Price Low to High',
    select6: 'Score',
    classAll: 'All',
    listTag1: 'Free Shipping',
    paymentTitle: 'Payment'
  },
  'appLogin': {
    navTitle: 'Login',
    navTitleHint: 'Login now and start trading with friends from all over the world!',
    singUp: 'Sign up',
    emailLabel: 'Phone number',
    emailPlaceholder: 'Please enter phone number',
    emailErrorText: 'Error prompt',
    emailNullVerify: 'Please enter phone number',//请输入邮箱
    passwordLabel: 'Your Password',
    passPlaceholder: 'Please input password',
    passNullVerify: 'Please enter your password',//请输入密码
    passErrorVerify: 'The password is less than 6 characters',//密码小于6位
    placeholder: 'Please Input',
    emailRegistration: 'Phone number registration',
    forgetPassTitle: 'Forgot Password?',
    submitBtnTitle: 'Login',
    errorMessage: 'Incorrect username or password',
    // emailErrorVerify: '邮箱格式错误！',//邮箱格式错误
  },
  'appForgotPass': {
    navTitle: 'Forgot Password',
    navTitleHint: 'Please write down your mobile phone number to receive a confirmation code to set a new password.',
    emailLabel: 'Phone number',
    emailPlaceholder: 'Please enter phone number',
    emailErrorText: 'Please enter phone number',//请输入邮箱
    emailNullVerify: 'Please enter phone number',//请输入邮箱
    emailErrorVerify: 'Wrong mobile number',//邮箱格式错误
    verificationCode: 'Verification Code',
    verificationPlace: 'Verification Code',
    verificationCodeError: 'Please enter verification code',
    sendTitle: 'Confirm mobile number',
    sendText: 'Revalidation',
    passwordLabel: 'Password',
    passPlaceholder: 'Please input password',
    confirmPassLabel: 'Confirm Password',
    confirmPlace: 'placeholder confirm password',
    passNullVerify: 'Enter your password',//请输入密码
    passErrorVerify: 'Password is less than 6 characters',//密码小于6位
    passCompareErrorVerify: 'Passwords are inconsistent',
    passCompareErrorNull: 'Please enter password again',
    emailRegistration: 'Phone number registration',
    forgetPassTitle: 'Forgot Password?',
    submitBtnTitle: 'Reset Password',
    submitErrorMessage: 'Incorrect verification code'
  },
  'appRegister': {
    navTitle: 'Register',
    navTitleHint: 'Create an account to access all features!',
    firstNameLabel: 'First Name',
    firstNamePlace: 'Please enter',
    firstNameErrorVerify: 'Please enter your first name',
    lastNameLabel: 'Last Name',
    lastNameErrorVerify: 'Please enter your last name',
    nickNameLabel: 'Nick Name',
    nickNameErrorVerify: 'Please enter your nick name',
    namePlace: 'Please enter',
    gender: 'Gender',
    gender1: 'Boy',
    gender2: 'Girl',
    gender3: 'Secrecy',
    genderError: 'Please enter your gender',
    emailLabel: 'Phone number',
    emailPlaceholder: 'Please enter phone number!',
    emailErrorText: 'Error message!',//请输入邮箱
    emailNullVerify: 'Please enter phone number!',//请输入邮箱
    verificationCode: 'Verification Code',
    verificationPlace: 'Verification Code',
    sendTitle: 'Confirm mobile number',
    sendText: 'Revalidation',
    verificationCodeError: 'Verification codecannot be empty',
    passwordLabel: 'Password',
    passPlaceholder: 'Please input password',
    confirmPassLabel: 'Confirm Password',
    confirmPlace: 'placeholder confirm password',
    passNullVerify: 'enter your password',//请输入密码
    passErrorVerify: 'Password is less than 6 characters！',//密码小于6位
    passCompareErrorVerify: 'Passwords are inconsistent！',
    passCompareErrorNull: 'Please enter password again',
    registerHint: 'Already have an account?',
    registerHintLogin: 'Login',
    submitBtnTitle: 'Register',
    submitErrorMessage: 'Incorrect verification code'
    // emailErrorVerify: '邮箱格式错误!',//邮箱格式错误
  },
  "appShopList": {
    navTitle: 'Shop List',
    searchPlace: 'Search...'
  },
  'appGoodsDetail': {
    salesTitle: 'Sales',
    conditionLabel: 'Condition:',
    conditionContent: 'Category-Supportedpayments',
    agreement: 'Buyer Protection Agreement',
    specSelect: 'Select specifications',
    payLabel1: 'Balance',
    payLabel2: 'PayPal',
    payLabel3: 'Visa',
    commentTitle: 'Comment',
    frightLabel: 'Freight',
    frightContent: 'Ships within 8 days on average',
    deliverTime: 'Evaluate Delivery time & days',
    productQuantity: 'Quantity ',
    soldQuantity: "Sales volume",
    collections: 'Quantity of order',
    since: 'Member since',
    shipper: 'Fast shipper',
    responder: 'Fast responder',
    reliable: 'Reliable',
    btnContact: 'Contact',
    enter: 'Enter',
    followBtn: 'Follow',
    guessLike: 'Guess You Like it',
    buyNow: 'Buy Now',
    dialogOverviewTitle: 'Overview',
    overConditionLabel: 'Condition',
    overConditionContent: 'New',
    overBrandLabel: 'Brand',
    overCategoryLabel: 'Category',
    overPayMethodsLabel: 'Supported payments',
    payTitle: 'Supported payments',
    payHint: 'The color is heautiful it Iooks vouno and stvlish It can he worn with allwiderangeofclothes.makingitverybeautiful Itisperfectforpairingwithcalsualandfashionableclothing',
    overCategoryEnter: 'Enter',
    dialogFreightTitle: 'Freight',
    dialogAgreementTitle: 'Buyer Protection Agreement',
    commitContent: 'System default praise',
    footerLeft: 'Add To Cart',
    footerRight: 'Buy Now'
  },
  'specificationDialog': {
    inventory: 'Inventory',
    countLabel: 'Number',
    specLabel: 'Default',
    options1: 'Add to Cart',
    options2: 'Buy Now',

  },
  "appShopsDetail": {
    select1: 'Comprehensive',
    select2: 'Sales Volume',
    select4: 'Price High to Low',
    select5: 'Price Low to High',
    select6: 'Score',
    follow: 'Follow',
    timeDay: 'Evaluate Delivery time & days',
    productQuantity: 'Quantity ',
    shippedQuantity: "Sales volume",
    collections: 'Quantity of order',
    commodity: 'Commodity',
    classAll: 'All'
  },
  // 产品评论页
  'appProductReviews': {
    navTitle: 'Comment',
    tags1: 'all',
    tags2: '5 stars',
    tags3: '4 stars',
    tags4: '3 stars',
    tags5: '2 stars',
    tags6: '1 star',
    tags7: 'With media',
    commitContent: 'System default praise'
  },
  // app升级配置文件语言
  configLanguage: {
    upgradePrompt: 'Version upgrade, please confirm? ',
    loadingTitle: 'Downloading...',
    systemUpgrade: 'System upgrade',
    upgradeImmediately: 'Confirm',
  },
  // 公共提示
  messageLanguage: {
    loadingTitle: "Loading", // Loading
    finishedText: "None", //None
    loadingText: "Loading", // Loading
    nullText: 'No data yet',
    loadingFinished: "Loading completed",
    listNullText: 'No more',
    pullIngText: 'Pull down to refresh',
    loosingText: 'Release to refresh',
    pullingSuccess: 'Pull-down loading successful'
  },
  // 'appSearchHistory': {
  //   navTitle: 'Search',
  //   searchPlace: 'Search...',
  //   recommendTitle: 'Recommend'
  // },
  // 'appClassList': {
  //   searchPlace: 'Search...',
  //   select1: 'Comprehensive',
  //   select2: 'Sales Volume',
  //   select3: 'New product',
  //   select4: 'Price High to Low',
  //   select5: 'Price Low to High',
  //   select6: 'Score',
  //   paymentTitle: 'payment',
  //   listTag1: 'Free Shipping',
  //   favoriteTitle: 'Favorite Quantity &',
  //   shopBtn: 'View Closets',
  //   recommendNavTitle: 'Recommend',
  //   usedNavTitle: 'Used',
  //   shopNavTitle: 'Shop'
  // },

  // 'appPreSale': {
  //   navTitle: 'Presale',
  //   tabs1: 'Started',
  //   tabs2: 'Not Started',
  //   tabs3: 'Ended',
  //   searchPlace: 'Search...',
  //   select1: 'Comprehensive',
  //   select2: 'Sales Volume',
  //   select3: 'New product',
  //   select4: 'Price High to Low',
  //   select5: 'Price Low to High',
  //   select6: 'Score',

  //   preSale: 'Presale',
  //   freeShipping: 'Free Shipping',
  //   payment: 'payment',
  //   preSale: 'presale',
  //   freeShipping: "Free Shipping"
  // },
  // "appGroupBuying": {
  //   navTitle: 'Group Buying',
  //   searchPlace: 'Search...',
  //   select1: 'Comprehensive',
  //   select2: 'Sales Volume',
  //   select3: 'New product',
  //   select4: 'Price High to Low',
  //   select5: 'Price Low to High',
  //   select6: 'Score',

  //   preSale: 'Presale',
  //   freeShipping: 'Free Shipping',
  //   payment: 'payment',
  //   btn1: 'Group Buying',
  //   btn2: "Free Shipping"
  // },
  // "appFlashSale": {
  //   navTitle: 'Flash Sale',
  //   searchPlace: 'Search...',
  //   select1: 'Comprehensive',
  //   select2: 'Sales Volume',
  //   select3: 'New product',
  //   select4: 'Price High to Low',
  //   select5: 'Price Low to High',
  //   select6: 'Score',

  //   preSale: 'Presale',
  //   freeShipping: 'Free Shipping',
  //   payment: 'payment',
  //   preSale: 'presale',
  //   freeShipping: "Free Shipping"
  // },
};
export default obj;
