// 阿拉伯语

let obj = {};
obj = {
  // 语言
  languageVersion: [
    'English',  // 英语
    '日本語', // 日语
    'Português', // 葡萄牙语
    '繁體中文', // 繁体中文
    'Français', // 法语
    'Deutsch', // 德语
    'Türk', // 土耳其语
    'Español', // 西班牙语
    'عربي', // 阿拉伯语
    '한국인', // 韩语
    'Suomi', // 芬兰语
    'Русский', // 俄语
    'italiano', // 意大利语
    'แบบไทย', // 泰语
    'Nederlands', // 荷兰语
  ],
  'footer': {
    home: "بيت",
  },
  'setDrawer': {
    label1: "إعدادات متعددة اللغات",
    label2: "تسجيل المتجر",
    label3: "تسجيل الدخول إلى المتجر",
    label4: "خدمة العملاء",
    label5: "تنزيل تطبيق Android",
    label6: "تنزيل تطبيق ios",
    optionsBtn: 'إلغاء',
  },
  'appHome': {
    searchPlace: "بحث...",
    recommendTitle: "التوصية",
    viewAll: 'عرض الكل',
    usedTitle: "مستعمل",
    shopTitle: "متجر",
    shopOption: "عرض الآن",
    hotTitle: "القائمة الساخنة",
    classTag1: "الصفحة الرئيسية",
    classTag2: "النساء",
    classTag3: "الرجال",
    classTag4: "أطفال",
    classTag5: "الإلكترونيات",
    classTag6: "الحيوانات الأليفة",
    buyNow: "اشتر الآن",
    // buyNow: 'Buy Now',
    // ranking: 'Sales Ranking',
    // preSaleTitle: 'Presale',
    // preSaleTabs1: 'Started',
    // preSaleTabs2: 'Not started',
    // preSaleTabs3: 'Ended',
    // groupTitle: 'Group Buying',
    // peopleGroupBuying: '人参与团购',
    // flashSaleTitle: 'Flash Sale',
  },
  'appSetLanguage': {
    navTitle: "إعدادات متعددة اللغات",
    affirm: 'يؤكد',
  },
  "appService": {
    navTitle: "خدمة العملاء",
    customerService: "خدمة العملاء الحصرية",
    helloTitle: "مرحبا",
    welcomeTitle: "أنا لك<، يمكنك التواصل معي عبر القنوات التالية",
    iconInformation: "يرجى تحديد برنامج الاتصال للتشاور",
    whatsApp: 'whatsApp ',
    telegram: 'telegram ',
    line: 'line',
  },
  // 移动分类页
  'appClassification': {
    navTitle: "تصنيف السلع",
    viewAll: "عرض الكل"
  },
  'appHotList': {
    navTitle: "القائمة الساخنة",
    ranking: "تصنيف المبيعات"
  },
  'appHotRanking': {
    navTitle: "تصنيف مبيعات اللهجات",
    searchPlace: "بحث...",
    payment: "الدفع",
    optionBtn1: "جمع",
    optionBtn2: "اشتر الآن",
    errorMessage: "الرجاء تسجيل الدخول"
  },
  'appUsedList': {
    navTitle: "مستعمل",
    recommendNavTitle: 'توصية',
    classNavTitle: "التصنيف",
    searchNavTitle: "بحث",
    searchPlace: "بحث...",
    select1: "شامل",
    select2: "حجم المبيعات",
    select3: "منتج جديد",
    select4: "السعر من الأعلى إلى الأقل",
    select5: "السعر من الأقل إلى الأعلى",
    select6: "النتيجة",
    classAll: 'الكل',
    listTag1: "الشحن المجاني",
    paymentTitle: "الدفع",
  },
  'appRecommendList': {
    navTitle: "التوصية",
    searchPlace: "بحث...",
    select1: "شامل",
    select2: "حجم المبيعات",
    select3: "منتج جديد",
    select4: "السعر من الأعلى إلى الأقل",
    select5: "السعر من الأقل إلى الأعلى",
    select6: "النتيجة",
    classAll: 'الكل',
    listTag1: "الشحن المجاني",
    paymentTitle: "قسط",
  },
  'appLogin': {
    navTitle: "تسجيل الدخول",
    navTitleHint: 'قم بتسجيل الدخول الآن وابدأ التداول مع الأصدقاء من جميع أنحاء العالم!',
    singUp: "التسجيل",
    emailLabel: "رقم الهاتف",
    emailPlaceholder: "الرجاء إدخال رقم الهاتف",
    emailErrorText: "موجه الخطأ",
    emailNullVerify: "الرجاء إدخال رقم الهاتف",//الرجاء إدخال رقم الهاتف
    passwordLabel: "كلمة المرور الخاصة بك",
    passPlaceholder: "الرجاء إدخال كلمة المرور",
    passNullVerify: "الرجاء إدخال كلمة المرور الخاصة بك",//请输入密码
    passErrorVerify: 'كلمة المرور أقل من 6 أحرف',//密码小于6位
    placeholder: "الرجاء الإدخال",
    emailRegistration: "تسجيل رقم الهاتف",
    forgetPassTitle: "هل نسيت كلمة المرور؟",
    submitBtnTitle: "تسجيل الدخول",
    errorMessage: "اسم المستخدم أو كلمة المرور غير صحيحة",
    // emailErrorVerify: '邮箱格式错误！',//邮箱格式错误
  },
  'appForgotPass': {
    navTitle: "نسيت كلمة المرور",
    navTitleHint: "الرجاء كتابة رقم هاتفك المحمول لتلقي رمز التأكيد لتعيين كلمة مرور جديدة.",
    emailLabel: "رقم الهاتف",
    emailPlaceholder: "الرجاء إدخال رقم الهاتف",
    emailErrorText: "الرجاء إدخال رقم الهاتف",//الرجاء إدخال رقم الهاتف
    emailNullVerify: "الرجاء إدخال رقم الهاتف",//الرجاء إدخال رقم الهاتف
    emailErrorVerify: "رقم الهاتف المحمول خاطئ",//邮箱格式错误
    verificationCode: "رمز التحقق",
    verificationPlace: "رمز التحقق",
    verificationCodeError: "الرجاء إدخال رمز التحقق",
    sendTitle: "تأكيد رقم الهاتف المحمول",
    sendText: "إعادة التحقق",
    passwordLabel: "كلمة المرور",
    passPlaceholder: "الرجاء إدخال كلمة المرور",
    confirmPassLabel: "تأكيد كلمة المرور",
    confirmPlace: "العنصر النائب لتأكيد كلمة المرور",
    passNullVerify: "أدخل كلمة المرور الخاصة بك",//الرجوع إلى 入密码
    passErrorVerify: 'كلمة المرور أقل من 6 أحرف',//密码小于6位
    passCompareErrorVerify: "كلمات المرور غير متناسقة",
    passCompareErrorNull: "الرجاء إدخال كلمة المرور مرة أخرى",
    emailRegistration: "تسجيل رقم الهاتف",
    forgetPassTitle: "هل نسيت كلمة المرور؟",
    submitBtnTitle: "إعادة تعيين كلمة المرور",
    submitErrorMessage: "رمز التحقق غير صحيح"
  },
  'appRegister': {
    navTitle: "التسجيل",
    navTitleHint: "أنشئ حسابًا للوصول إلى كافة الميزات!",
    firstNameLabel: "الاسم الأول",
    firstNamePlace: "الرجاء الدخول",
    firstNameErrorVerify: "الرجاء إدخال اسمك الأول",
    lastNameLabel: "الاسم الأخير",
    lastNameErrorVerify: "الرجاء إدخال اسمك الأخير",
    nickNameLabel: "اسم اللقب",
    nickNameErrorVerify: "الرجاء إدخال اسمك المستعار",
    namePlace: "الرجاء الدخول",
    gender: "الجنس",
    gender1: "صبي",
    gender2: "فتاة",
    gender3: "السرية",
    genderError: "الرجاء إدخال جنسك",
    emailLabel: "رقم الهاتف",
    emailPlaceholder: "الرجاء إدخال رقم الهاتف!",
    emailErrorText: "رسالة خطأ!",//العنوان الأصلي
    emailNullVerify: 'الرجاء إدخال رقم الهاتف!',//请输入邮箱
    verificationCode: "رمز التحقق",
    verificationPlace: "رمز التحقق",
    sendTitle: "تأكيد رقم الهاتف المحمول",
    sendText: "إعادة التحقق",
    verificationCodeError: "لا يمكن أن يكون رمز التحقق فارغًا",
    passwordLabel: "كلمة المرور",
    passPlaceholder: "الرجاء إدخال كلمة المرور",
    confirmPassLabel: "تأكيد كلمة المرور",
    confirmPlace: "العنصر النائب لتأكيد كلمة المرور",
    passNullVerify: "أدخل كلمة المرور الخاصة بك",//الرجوع إلى 入密码
    passErrorVerify: 'كلمة المرور أقل من 6 أحرف!',//密码小于6位
    passCompareErrorVerify: "كلمات المرور غير متناسقة!",
    passCompareErrorNull: "الرجاء إدخال كلمة المرور مرة أخرى",
    registerHint: "هل لديك حساب بالفعل؟",
    registerHintLogin: "تسجيل الدخول",
    submitBtnTitle: "التسجيل",
    submitErrorMessage: "رمز التحقق غير صحيح"
    // emailErrorVerify: '邮箱格式错误!',//邮箱格式错误
  },
  "appShopList": {
    navTitle: "قائمة المتاجر",
    searchPlace: "بحث..."
  },
  'appGoodsDetail': {
    salesTitle: "المبيعات",
    conditionLabel: 'الحالة:',
    conditionContent: "المدفوعات المدعومة بالفئة",
    agreement: "اتفاقية حماية المشتري",
    specSelect: "حدد المواصفات",
    payLabel1: "الرصيد",
    payLabel2: "PayPal",
    payLabel3: "تأشيرة",
    commentTitle: "تعليق",
    frightLabel: "الشحن",
    frightContent: "يتم الشحن خلال 8 أيام في المتوسط",
    deliverTime: "تقييم وقت التسليم وأيامه",
    productQuantity: "الكمية",
    soldQuantity: "حجم المبيعات",
    collections: "كمية الطلب",
    since: "عضو منذ",
    shipper: "الشاحن السريع",
    responder: "المستجيب السريع",
    reliable: "موثوق",
    btnContact: "اتصال",
    enter: "أدخل",
    followBtn: 'متابعة',
    guessLike: "أعتقد أنك ترغب في ذلك",
    buyNow: "اشتر الآن",
    dialogOverviewTitle: "نظرة عامة",
    overConditionLabel: "الحالة",
    overConditionContent: "جديد",
    overBrandLabel: "العلامة التجارية",
    overCategoryLabel: "الفئة",
    overPayMethodsLabel: "المدفوعات المدعومة",
    payTitle: "المدفوعات المدعومة",
    payHint: "اللون جميل ويبدو رائعًا وأنيقًا ويمكن ارتداؤه مع جميع مجموعة كبيرة من الملابس. مما يجعله جميلًا جدًا ومثاليًا للارتداء مع الملابس الكاجوال والعصرية",
    overCategoryEnter: "أدخل",
    dialogFreightTitle: "الشحن",
    dialogAgreementTitle: "اتفاقية حماية المشتري",
    commitContent: "الثناء الافتراضي للنظام",
    footerLeft: 'أضف إلى سلة التسوق',
    footerRight: "اشتر الآن"
  },
  'specificationDialog': {
    inventory: "المخزون",
    countLabel: 'الرقم',
    specLabel: "افتراضي",
    options1: "أضف إلى سلة التسوق",
    options2: "اشتر الآن",

  },
  "appShopsDetail": {
    select1: 'شامل',
    select2: 'حجم المبيعات',
    select4: 'حجم المبيعات',
    select5: 'السعر من الارخص للاعلى',
    select6: 'نتيجة',
    follow: 'يتبع',
    timeDay: 'تقييم وقت التسليم والأيام',
    productQuantity: "الكمية",
    shippedQuantity: "حجم المبيعات",
    collections: "كمية الطلب",
    commodity: "سلعة",
    classAll: 'الكل'
  },
  // 产品评论页
  'appProductReviews': {
    navTitle: "تعليق",
    tags1: "الكل",
    tags2: "5 نجوم",
    tags3: '4 نجوم',
    tags4: '3 نجوم',
    tags5: 'نجمتان',
    tags6: "نجمة واحدة",
    tags7: "مع وسائل الإعلام",
    commitContent: "الثناء الافتراضي للنظام"
  },
  // app升级配置文件语言
  configLanguage: {
    upgradePrompt: "ترقية الإصدار، يرجى التأكيد؟' ",
    loadingTitle: "جارٍ التنزيل...",
    systemUpgrade: "ترقية النظام",
    upgradeImmediately: "تأكيد",
  },
  // 公共提示
  messageLanguage: {
    loadingTitle: "جارٍ التحميل", // جاري التحميل
    finishedText: "لا شيء", // لا شيء
    loadingText: "جارٍ التحميل", // جارٍ التحميل
    nullText: "لا توجد بيانات بعد",
    loadingFinished: "اكتمل التحميل",
    listNullText: "لا أكثر",
    pullIngText: "اسحب للأسفل للتحديث",
    loosingText: "الإصدار للتحديث",
    pullingSuccess: "تم التحميل المنسدل بنجاح"
  },
  // 'appSearchHistory': {
  //   navTitle: 'Search',
  //   searchPlace: 'Search...',
  //   recommendTitle: 'Recommend'
  // },
  // 'appClassList': {
  //   searchPlace: 'Search...',
  //   select1: 'Comprehensive',
  //   select2: 'Sales Volume',
  //   select3: 'New product',
  //   select4: 'Price High to Low',
  //   select5: 'Price Low to High',
  //   select6: 'Score',
  //   paymentTitle: 'payment',
  //   listTag1: 'Free Shipping',
  //   favoriteTitle: 'Favorite Quantity &',
  //   shopBtn: 'View Closets',
  //   recommendNavTitle: 'Recommend',
  //   usedNavTitle: 'Used',
  //   shopNavTitle: 'Shop'
  // },

  // 'appPreSale': {
  //   navTitle: 'Presale',
  //   tabs1: 'Started',
  //   tabs2: 'Not Started',
  //   tabs3: 'Ended',
  //   searchPlace: 'Search...',
  //   select1: 'Comprehensive',
  //   select2: 'Sales Volume',
  //   select3: 'New product',
  //   select4: 'Price High to Low',
  //   select5: 'Price Low to High',
  //   select6: 'Score',

  //   preSale: 'Presale',
  //   freeShipping: 'Free Shipping',
  //   payment: 'payment',
  //   preSale: 'presale',
  //   freeShipping: "Free Shipping"
  // },
  // "appGroupBuying": {
  //   navTitle: 'Group Buying',
  //   searchPlace: 'Search...',
  //   select1: 'Comprehensive',
  //   select2: 'Sales Volume',
  //   select3: 'New product',
  //   select4: 'Price High to Low',
  //   select5: 'Price Low to High',
  //   select6: 'Score',

  //   preSale: 'Presale',
  //   freeShipping: 'Free Shipping',
  //   payment: 'payment',
  //   btn1: 'Group Buying',
  //   btn2: "Free Shipping"
  // },
  // "appFlashSale": {
  //   navTitle: 'Flash Sale',
  //   searchPlace: 'Search...',
  //   select1: 'Comprehensive',
  //   select2: 'Sales Volume',
  //   select3: 'New product',
  //   select4: 'Price High to Low',
  //   select5: 'Price Low to High',
  //   select6: 'Score',

  //   preSale: 'Presale',
  //   freeShipping: 'Free Shipping',
  //   payment: 'payment',
  //   preSale: 'presale',
  //   freeShipping: "Free Shipping"
  // },
};
export default obj;
