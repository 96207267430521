// 土耳其语
let obj = {};
obj = {
    // 语言
    languageVersion: [
        'English',  // 英语
        '日本語', // 日语
        'Português', // 葡萄牙语
        '繁體中文', // 繁体中文
        'Français', // 法语
        'Deutsch', // 德语
        'Türk', // 土耳其语
        'Español', // 西班牙语
        'عربي', // 阿拉伯语
        '한국인', // 韩语
        'Suomi', // 芬兰语
        'Русский', // 俄语
        'italiano', // 意大利语
        'แบบไทย', // 泰语
        'Nederlands', // 荷兰语
    ],
    'footer': {
        home: "Ev",
    },
    'setDrawer': {
        label1: 'Çoklu dil ayarları',
        label2: 'Mağaza kaydı',
        label3: 'Mağaza girişi',
        label4: 'Müşteri Hizmetleri',
        label5: 'Android uygulamasını indir',
        label6: 'ios uygulamasını indir',
        optionsBtn: 'İptal'
    },
    'appHome': {
        searchPlace: 'Ara...',
        recommendTitle: 'Tavsiye Ediyorum',
        viewAll: 'tümünü görüntüle',
        usedTitle: 'Kullanılmış',
        shopTitle: 'Mağaza',
        shopOption: 'Şimdi Görüntüle',
        hotTitle: 'Sıcak Liste',
        classTag1: 'Ana Sayfa',
        classTag2: 'Kadınlar',
        classTag3: 'Erkekler',
        classTag4: 'Çocuklar',
        classTag5: 'Elektronik',
        classTag6: 'Evcil Hayvanlar',
        buyNow: 'Şimdi Satın Al',

        // ranking: 'Sales Ranking',
        // preSaleTitle: 'Presale',
        // preSaleTabs1: 'Started',
        // preSaleTabs2: 'Not started',
        // preSaleTabs3: 'Ended',
        // groupTitle: 'Group Buying',
        // peopleGroupBuying: '人参与团购',
        // flashSaleTitle: 'Flash Sale',
    },
    'appSetLanguage': {
        navTitle: 'Çoklu dil ayarları',
        affirm: 'onayla',
    },
    "appService": {
        navTitle: 'Müşteri Hizmetleri',
        customerService: 'Özel müşteri hizmetleri',
        helloTitle: 'Merhaba',
        welcomeTitle: 'Ben seninim<, bana aşağıdaki kanallardan ulaşabilirsiniz',
        iconInformation: 'Lütfen danışmak için iletişim yazılımını seçin',
        whatsApp: 'whatsApp ',
        telegram: 'telegram ',
        line: 'line',
    },
    // 移动分类页
    'appClassification': {
        navTitle: 'Emtia Sınıflandırması',
        viewAll: 'Tümünü Görüntüle',
    },
    'appHotList': {
        navTitle: 'Sıcak Liste',
        ranking: 'Satış Sıralaması',
    },
    'appHotRanking': {
        navTitle: 'Satış Sıralamasını Vurguluyor',
        searchPlace: 'Ara...',
        payment: 'ödeme',
        optionBtn1: 'Topla',
        optionBtn2: 'Hemen Satın Al',
        errorMessage: 'Lütfen oturum açın',
    },
    'appUsedList': {
        navTitle: 'Kullanılmış',
        recommendNavTitle: 'Tavsiye Ediyorum',
        classNavTitle: 'Sınıflandırma',
        searchNavTitle: 'Ara',
        searchPlace: 'Ara...',
        select1: 'Kapsamlı',
        select2: 'Satış Hacmi',
        select3: 'Yeni ürün',
        select4: 'Fiyat Yüksekten Düşüğe',
        select5: 'Fiyat Düşükten Yükseğe',
        select6: 'Puan',
        classAll: 'Tümü',
        listTag1: 'Ücretsiz Kargo',
        paymentTitle: 'Ödeme'
    },
    'appRecommendList': {
        navTitle: 'Tavsiye Ediyorum',
        searchPlace: 'Ara...',
        select1: 'Kapsamlı',
        select2: 'Satış Hacmi',
        select3: 'Yeni ürün',
        select4: 'Fiyat Yüksekten Düşüğe',
        select5: 'Fiyat Düşükten Yükseğe',
        select6: 'Puan',
        classAll: 'Tümü',
        listTag1: 'Ücretsiz Kargo',
        paymentTitle: 'Ödeme'
    },
    'appLogin': {
        navTitle: 'Giriş Yap',
        navTitleHint: 'Şimdi giriş yapın ve dünyanın her yerinden arkadaşlarınızla ticarete başlayın!',
        singUp: 'Kaydol',
        emailLabel: 'Telefon numarası',
        emailPlaceholder: 'Lütfen telefon numarasını girin',
        emailErrorText: 'Hata istemi',
        emailNullVerify: 'Lütfen telefon numarasını girin',//请输入邮箱
        passwordLabel: 'Şifreniz',
        passPlaceholder: 'Lütfen şifreyi girin',
        passNullVerify: 'Lütfen şifrenizi girin',//请输入密码
        passErrorVerify: 'Şifre 6 karakterden az',//密码小于6位
        placeholder: 'Lütfen Giriş Yapın',
        emailRegistration: 'Telefon numarası kaydı',
        forgetPassTitle: 'Şifrenizi mi unuttunuz?',
        submitBtnTitle: 'Giriş',
        errorMessage: 'Yanlış kullanıcı adı veya şifre',
        // emailErrorVerify: '邮箱格式错误！',//邮箱格式错误
    },
    'appForgotPass': {
        navTitle: 'Şifremi Unuttum',
        navTitleHint: 'Yeni şifre belirleme onay kodunu almak için lütfen cep telefonu numaranızı yazınız.',
        emailLabel: 'Telefon numarası',
        emailPlaceholder: 'Lütfen telefon numarasını girin',
        emailErrorText: 'Lütfen telefon numarasını girin',//请输入邮箱
        emailNullVerify: 'Lütfen telefon numarasını girin',//请输入邮箱
        emailErrorVerify: 'Yanlış cep telefonu numarası',//邮箱格式错误
        verificationCode: 'Doğrulama Kodu',
        verificationPlace: 'Doğrulama Kodu',
        verificationCodeError: 'Lütfen doğrulama kodunu girin',
        sendTitle: 'Cep telefonu numarasını doğrula',
        sendText: 'Yeniden Doğrulama',
        passwordLabel: 'Şifre',
        passPlaceholder: 'Lütfen şifreyi girin',
        confirmPassLabel: 'Şifreyi Onayla',
        confirmPlace: 'yer tutucu şifreyi onayla',
        passNullVerify: 'Şifrenizi girin',//请输入密码
        passErrorVerify: 'Şifre 6 karakterden az',//密码小于6位
        passCompareErrorVerify: 'Şifreler tutarsız',
        passCompareErrorNull: 'Lütfen şifreyi tekrar girin',
        emailRegistration: 'Telefon numarası kaydı',
        forgetPassTitle: 'Şifrenizi mi unuttunuz?',
        submitBtnTitle: 'Şifreyi Sıfırla',
        submitErrorMessage: 'Yanlış doğrulama kodu'
    },
    'appRegister': {
        navTitle: 'Kayıt Ol',
        navTitleHint: 'Tüm özelliklere erişmek için bir hesap oluşturun!',
        firstNameLabel: 'Ad',
        firstNamePlace: 'Lütfen giriniz',
        firstNameErrorVerify: 'Lütfen adınızı girin',
        lastNameLabel: 'Soyadı',
        lastNameErrorVerify: 'Lütfen soyadınızı girin',
        nickNameLabel: 'Takma Ad',
        nickNameErrorVerify: 'Lütfen takma adınızı girin',
        namePlace: 'Lütfen girin',
        gender: 'Cinsiyet',
        gender1: 'Erkek çocuk',
        gender2: 'Kız',
        gender3: 'Gizlilik',
        genderError: 'Lütfen cinsiyetinizi girin',
        emailLabel: 'Telefon numarası',
        emailPlaceholder: 'Lütfen telefon numarasını girin!',
        emailErrorText: 'Hata mesajı!',//请输入邮箱
        emailNullVerify: 'Lütfen telefon numarasını girin!',//请输入邮箱
        verificationCode: 'Doğrulama Kodu',
        verificationPlace: 'Doğrulama Kodu',
        sendTitle: 'Cep telefonu numarasını doğrula',
        sendText: 'Yeniden Doğrulama',
        verificationCodeError: 'Doğrulama kodu boş olamaz',
        passwordLabel: 'Şifre',
        passPlaceholder: 'Lütfen şifreyi girin',
        confirmPassLabel: 'Şifreyi Onayla',
        confirmPlace: 'yer tutucu şifreyi onayla',
        passNullVerify: 'şifrenizi girin',//请输入密码
        passErrorVerify: 'Şifre 6 karakterden az！',//密码小于6位
        passCompareErrorVerify: 'Şifreler tutarsız！',
        passCompareErrorNull: 'Lütfen şifreyi tekrar girin',
        registerHint: 'Zaten bir hesabınız var mı?',
        registerHintLogin: 'Giriş Yap',
        submitBtnTitle: 'Kayıt Ol',
        submitErrorMessage: 'Yanlış doğrulama kodu'
        // emailErrorVerify: '邮箱格式错误!',//邮箱格式错误
    },
    "appShopList": {
        navTitle: 'Alışveriş Listesi',
        searchPlace: 'Ara...'
    },
    'appGoodsDetail': {
        salesTitle: 'Satış',
        conditionLabel: 'Durum:',
        conditionContent: 'Kategori Destekli ödemeler',
        agreement: 'Alıcı Koruma Sözleşmesi',
        specSelect: 'Teknik özellikleri seçin',
        payLabel1: 'Bakiye',
        payLabel2: 'PayPal',
        payLabel3: 'Vize',
        commentTitle: 'Yorum',
        frightLabel: 'Nakliye',
        frightContent: 'Ortalama 8 gün içinde kargoya verilir',
        deliverTime: 'Teslimat süresini ve günlerini değerlendirin',
        productQuantity: 'Miktar',
        soldQuantity: "Satış hacmi",
        collections: 'Sipariş miktarı',
        since: 'Şu tarihten beri üye',
        shipper: 'Hızlı gönderici',
        responder: 'Hızlı yanıtlayıcı',
        reliable: 'Güvenilir',
        btnContact: 'İletişim',
        enter: 'Giriş',
        followBtn: 'Takip et',
        guessLike: 'Sanırım Beğendin',
        buyNow: 'Şimdi Satın Al',
        dialogOverviewTitle: 'Genel Bakış',
        overConditionLabel: 'Koşul',
        overConditionContent: 'Yeni',
        overBrandLabel: 'Marka',
        overCategoryLabel: 'Kategori',
        overPayMethodsLabel: 'Desteklenen ödemeler',
        payTitle: 'Desteklenen ödemeler',
        payHint: 'Renk çok güzel, çok güzel ve şık görünüyor Her türlü kıyafetle giyilebilir.çok güzel oluyor Rahat ve modaya uygun kıyafetlerle kombinlemek için mükemmel',
        overCategoryEnter: 'Giriş',
        dialogFreightTitle: 'Nakliye',
        dialogAgreementTitle: 'Alıcı Koruma Sözleşmesi',
        commitContent: 'Sistem varsayılanı övgüsü',
        footerLeft: 'Sepete Ekle',
        footerRight: 'Şimdi Satın Al'
    },
    'specificationDialog': {
        inventory: 'Envanter',
        countLabel: 'Sayı',
        specLabel: 'Varsayılan',
        options1: 'Sepete Ekle',
        options2: 'Şimdi Satın Al',

    },
    "appShopsDetail": {
        select1: 'Kapsamlı',
        select2: 'Satış Hacmi',
        select4: 'Fiyat Yüksekten Düşüğe',
        select5: 'Fiyat Düşükten Yükseğe',
        select6: 'Puan',
        follow: 'takip',
        timeDay: 'Teslimat süresini ve günlerini değerlendirin',
        productQuantity: 'Miktar',
        shippedQuantity: "Satış hacmi",
        collections: 'Sipariş miktarı',
        commodity: 'Emtia',
        classAll: 'Tümü'
    },
    // 产品评论页
    'appProductReviews': {
        navTitle: 'Yorum',
        tags1: 'tümü',
        tags2: '5 yıldız',
        tags3: '4 yıldız',
        tags4: '3 yıldız',
        tags5: '2 yıldız',
        tags6: '1 yıldız',
        tags7: 'Medya ile',
        commitContent: 'Sistem varsayılanı övgüsü'
    },
    // app升级配置文件语言
    configLanguage: {
        upgradePrompt: 'Sürüm yükseltme, lütfen onaylayın mı? ',
        loadingTitle: 'İndiriliyor...',
        systemUpgrade: 'Sistem yükseltmesi',
        upgradeImmediately: 'Onayla',
    },
    // 公共提示
    messageLanguage: {
        loadingTitle: "Yükleniyor", // Yükleniyor
        finishedText: "Yok", //Yok
        loadingText: "Yükleniyor", // Yükleniyor
        nullText: 'Henüz veri yok',
        loadingFinished: "Yükleme tamamlandı",
        listNullText: 'Artık yok',
        pullIngText: 'Yenilemek için aşağı çekin',
        loosingText: 'Yenilemek için bırakın',
        pullingSuccess: 'Aşağı açılan yükleme başarılı'
    },
    // 'appSearchHistory': {
    //   navTitle: 'Search',
    //   searchPlace: 'Search...',
    //   recommendTitle: 'Recommend'
    // },
    // 'appClassList': {
    //   searchPlace: 'Search...',
    //   select1: 'Comprehensive',
    //   select2: 'Sales Volume',
    //   select3: 'New product',
    //   select4: 'Price High to Low',
    //   select5: 'Price Low to High',
    //   select6: 'Score',
    //   paymentTitle: 'payment',
    //   listTag1: 'Free Shipping',
    //   favoriteTitle: 'Favorite Quantity &',
    //   shopBtn: 'View Closets',
    //   recommendNavTitle: 'Recommend',
    //   usedNavTitle: 'Used',
    //   shopNavTitle: 'Shop'
    // },

    // 'appPreSale': {
    //   navTitle: 'Presale',
    //   tabs1: 'Started',
    //   tabs2: 'Not Started',
    //   tabs3: 'Ended',
    //   searchPlace: 'Search...',
    //   select1: 'Comprehensive',
    //   select2: 'Sales Volume',
    //   select3: 'New product',
    //   select4: 'Price High to Low',
    //   select5: 'Price Low to High',
    //   select6: 'Score',

    //   preSale: 'Presale',
    //   freeShipping: 'Free Shipping',
    //   payment: 'payment',
    //   preSale: 'presale',
    //   freeShipping: "Free Shipping"
    // },
    // "appGroupBuying": {
    //   navTitle: 'Group Buying',
    //   searchPlace: 'Search...',
    //   select1: 'Comprehensive',
    //   select2: 'Sales Volume',
    //   select3: 'New product',
    //   select4: 'Price High to Low',
    //   select5: 'Price Low to High',
    //   select6: 'Score',

    //   preSale: 'Presale',
    //   freeShipping: 'Free Shipping',
    //   payment: 'payment',
    //   btn1: 'Group Buying',
    //   btn2: "Free Shipping"
    // },
    // "appFlashSale": {
    //   navTitle: 'Flash Sale',
    //   searchPlace: 'Search...',
    //   select1: 'Comprehensive',
    //   select2: 'Sales Volume',
    //   select3: 'New product',
    //   select4: 'Price High to Low',
    //   select5: 'Price Low to High',
    //   select6: 'Score',

    //   preSale: 'Presale',
    //   freeShipping: 'Free Shipping',
    //   payment: 'payment',
    //   preSale: 'presale',
    //   freeShipping: "Free Shipping"
    // },
};
export default obj;
