// 韩语
let obj = {};
obj = {
    // 语言
    languageVersion: [
        'English',  // 英语
        '日本語', // 日语
        'Português', // 葡萄牙语
        '繁體中文', // 繁体中文
        'Français', // 法语
        'Deutsch', // 德语
        'Türk', // 土耳其语
        'Español', // 西班牙语
        'عربي', // 阿拉伯语
        '한국인', // 韩语
        'Suomi', // 芬兰语
        'Русский', // 俄语
        'italiano', // 意大利语
        'แบบไทย', // 泰语
        'Nederlands', // 荷兰语
    ],
    'footer': {
        home: "집",
    },
    'setDrawer': {
        label1: '다국어 설정',
        label2: '스토어 등록',
        label3: '스토어 로그인',
        label4: '고객 서비스',
        label5: 'Android 애플리케이션 다운로드',
        label6: 'ios 애플리케이션 다운로드',
        optionsBtn: '취소'
    },
    'appHome': {
        searchPlace: '검색...',
        recommendTitle: '추천',
        viewAll: '모두 보기',
        usedTitle: '중고',
        shopTitle: '상점',
        shopOption: '지금 보기',
        hotTitle: '핫 리스트',
        classTag1: '집',
        classTag2: '여성',
        classTag3: '남자',
        classTag4: '키즈',
        classTag5: '전자제품',
        classTag6: '애완동물',
        buyNow: '지금 구매',

        // ranking: 'Sales Ranking',
        // preSaleTitle: 'Presale',
        // preSaleTabs1: 'Started',
        // preSaleTabs2: 'Not started',
        // preSaleTabs3: 'Ended',
        // groupTitle: 'Group Buying',
        // peopleGroupBuying: '人参与团购',
        // flashSaleTitle: 'Flash Sale',
    },
    'appSetLanguage': {
        navTitle: '다국어 설정',
        affirm: '확인하다',
    },
    "appService": {
        navTitle: '고객 서비스',
        customerService: '독점적인 고객 서비스',
        helloTitle: '안녕하세요',
        welcomeTitle: '나는 당신의 것입니다<, 다음 채널을 통해 저에게 연락하실 수 있습니다',
        iconInformation: '상담을 위한 통신 소프트웨어를 선택해주세요',
        whatsApp: 'whatsApp ',
        telegram: 'telegram ',
        line: 'line',
    },
    // 移动分类页
    'appClassification': {
        navTitle: '상품 분류',
        viewAll: '모두 보기',
    },
    'appHotList': {
        navTitle: '핫 리스트',
        ranking: '판매 순위',
    },
    'appHotRanking': {
        navTitle: '액센트 판매 순위',
        searchPlace: '검색...',
        payment: '결제',
        optionBtn1: '수집',
        optionBtn2: '지금 구매',
        errorMessage: '로그인하세요',
    },
    'appUsedList': {
        navTitle: '사용됨',
        recommendNavTitle: '추천',
        classNavTitle: '분류',
        searchNavTitle: '검색',
        searchPlace: '검색...',
        select1: '종합',
        select2: '판매량',
        select3: '새 제품',
        select4: '가격이 높은 순으로',
        select5: '낮은 가격순',
        select6: '점수',
        classAll: '모두',
        listTag1: '무료 배송',
        paymentTitle: '결제'
    },
    'appRecommendList': {
        navTitle: '추천',
        searchPlace: '검색...',
        select1: '종합',
        select2: '판매량',
        select3: '새 제품',
        select4: '가격이 높은 순으로',
        select5: '낮은 가격순',
        select6: '점수',
        classAll: '모두',
        listTag1: '무료 배송',
        paymentTitle: '결제'
    },
    'appLogin': {
        navTitle: '로그인',
        navTitleHint: '지금 로그인하여 전 세계의 친구들과 거래를 시작하세요!',
        singUp: '가입',
        emailLabel: '전화번호',
        emailPlaceholder: '전화번호를 입력하세요',
        emailErrorText: '오류 프롬프트',
        emailNullVerify: '전화번호를 입력하세요',//请输入邮箱
        passwordLabel: '귀하의 비밀번호',
        passPlaceholder: '비밀번호를 입력하세요',
        passNullVerify: '비밀번호를 입력하세요',//请输入密码
        passErrorVerify: '비밀번호가 6자 미만입니다.',//密码小于6位
        placeholder: '입력하세요',
        emailRegistration: '전화번호 등록',
        forgetPassTitle: '비밀번호를 잊으셨나요?',
        submitBtnTitle: '로그인',
        errorMessage: '잘못된 사용자 이름 또는 비밀번호',
        // emailErrorVerify: '邮箱格式错误！',//邮箱格式错误
    },
    'appForgotPass': {
        navTitle: '비밀번호를 잊으셨나요?',
        navTitleHint: '새 비밀번호 설정을 위한 확인 코드를 받으려면 휴대전화 번호를 적어주세요.',
        emailLabel: '전화번호',
        emailPlaceholder: '전화번호를 입력하세요',
        emailErrorText: '전화번호를 입력하세요',//请输入邮箱
        emailNullVerify: '전화번호를 입력하세요',//请输入邮箱
        emailErrorVerify: '잘못된 휴대폰 번호',//邮箱格式错误
        verificationCode: '인증 코드',
        verificationPlace: '인증 코드',
        verificationCodeError: '인증 코드를 입력하세요',
        sendTitle: '휴대폰번호 확인',
        sendText: '재검증',
        passwordLabel: '비밀번호',
        passPlaceholder: '비밀번호를 입력하세요',
        confirmPassLabel: '비밀번호 확인',
        confirmPlace: '자리표시자 비밀번호 확인',
        passNullVerify: '비밀번호를 입력하세요',//请输入密码
        passErrorVerify: '비밀번호가 6자 미만입니다.',//密码小于6位
        passCompareErrorVerify: '비밀번호가 일치하지 않습니다',
        passCompareErrorNull: '비밀번호를 다시 입력하세요',
        emailRegistration: '전화번호 등록',
        forgetPassTitle: '비밀번호를 잊으셨나요?',
        submitBtnTitle: '비밀번호 재설정',
        submitErrorMessage: '잘못된 인증 코드'
    },
    'appRegister': {
        navTitle: '등록',
        navTitleHint: '모든 기능에 액세스하려면 계정을 만드세요!',
        firstNameLabel: '이름',
        firstNamePlace: '입력하세요',
        firstNameErrorVerify: '이름을 입력하세요',
        lastNameLabel: '성',
        lastNameErrorVerify: '성명을 입력하세요',
        nickNameLabel: '닉네임',
        nickNameErrorVerify: '닉네임을 입력하세요',
        namePlace: '입력하세요',
        gender: '성별',
        gender1: '소년',
        gender2: '소녀',
        gender3: '비밀',
        genderError: '성별을 입력하세요',
        emailLabel: '전화번호',
        emailPlaceholder: '전화번호를 입력하세요!',
        emailErrorText: '오류 메시지!',//请输入邮箱
        emailNullVerify: '전화번호를 입력해주세요!',//请输入邮箱
        verificationCode: '인증 코드',
        verificationPlace: '인증 코드',
        sendTitle: '휴대폰번호 확인',
        sendText: '재검증',
        verificationCodeError: '인증 코드는 비워둘 수 없습니다.',
        passwordLabel: '비밀번호',
        passPlaceholder: '비밀번호를 입력하세요',
        confirmPassLabel: '비밀번호 확인',
        confirmPlace: '자리표시자 비밀번호 확인',
        passNullVerify: '비밀번호를 입력하세요',//请输入密码
        passErrorVerify: '비밀번호가 6자 미만입니다!',//密码小于6位
        passCompareErrorVerify: '비밀번호가 일치하지 않습니다!',
        passCompareErrorNull: '비밀번호를 다시 입력하세요',
        registerHint: '이미 계정이 있나요?',
        registerHintLogin: '로그인',
        submitBtnTitle: '등록',
        submitErrorMessage: '잘못된 인증 코드'
        // emailErrorVerify: '邮箱格式错误!',//邮箱格式错误
    },
    "appShopList": {
        navTitle: '쇼핑 목록',
        searchPlace: '검색...'
    },
    'appGoodsDetail': {
        salesTitle: '판매',
        conditionLabel: '조건:',
        conditionContent: '카테고리 지원 결제',
        agreement: '구매자 보호 계약',
        specSelect: '사양 선택',
        payLabel1: '잔액',
        payLabel2: '페이팔',
        payLabel3: '비자',
        commentTitle: '댓글',
        frightLabel: '화물',
        frightContent: '평균 8일 이내에 배송',
        deliverTime: '배송 시간 및 날짜 평가',
        productQuantity: '수량',
        soldQuantity: "판매량",
        collections: '주문 수량',
        since: '이후 회원',
        shipper: '빠른 배송업체',
        responder: '빠른 응답자',
        reliable: '신뢰할 수 있는',
        btnContact: '연락처',
        enter: '입력',
        followBtn: '팔로우',
        guessLike: '좋아하는 것 같아요',
        buyNow: '지금 구매',
        dialogOverviewTitle: '개요',
        overConditionLabel: '조건',
        overConditionContent: '새 항목',
        overBrandLabel: '브랜드',
        overCategoryLabel: '카테고리',
        overPayMethodsLabel: '지원되는 결제',
        payTitle: '지원되는 결제',
        payHint: '색상이 너무 예뻐서 보기에도 좋고 스타일리쉬해서 다양한 옷에 매치하기 좋아요. 너무 예뻐요 캐주얼하고 패셔너블한 옷과 매치하기 딱 좋아요',
        overCategoryEnter: '입력',
        dialogFreightTitle: '화물',
        dialogAgreementTitle: '구매자 보호 계약',
        commitContent: '시스템 기본 칭찬',
        footerLeft: '장바구니에 추가',
        footerRight: '지금 구매'
    },
    'specificationDialog': {
        inventory: '인벤토리',
        countLabel: '숫자',
        specLabel: '기본값',
        options1: '장바구니에 추가',
        options2: '지금 구매',

    },
    "appShopsDetail": {
        select1: '종합',
        select2: '판매량',
        select4: '가격이 높은 순으로',
        select5: '낮은 가격순',
        select6: '점수',
        follow: '팔로우',
        timeDay: '배송 시간 및 요일 평가',
        productQuantity: '수량',
        shippedQuantity: "판매량",
        collections: '주문 수량',
        commodity: '상품',
        classAll: '모두'
    },
    // 产品评论页
    'appProductReviews': {
        navTitle: '댓글',
        tags1: '모두',
        tags2: '별 5개',
        tags3: '별 4개',
        tags4: '별 3개',
        tags5: '별 2개',
        tags6: '별 1개',
        tags7: '미디어 포함',
        commitContent: '시스템 기본 칭찬'
    },
    // app升级配置文件语言
    configLanguage: {
        upgradePrompt: '버전 업그레이드를 확인해주세요. ',
        loadingTitle: '다운로드 중...',
        systemUpgrade: '시스템 업그레이드',
        upgradeImmediately: '확인',
    },
    // 公共提示
    messageLanguage: {
        loadingTitle: "로드 중", // 로드 중
        finishedText: "없음", //없음
        loadingText: "로드 중", // 로드 중
        nullText: '아직 데이터가 없습니다',
        loadingFinished: "로드가 완료되었습니다",
        listNullText: '더 이상은 없습니다',
        pullIngText: '새로고침하려면 아래로 당기세요',
        loosingText: '새로 고침을 해제하세요',
        pullingSuccess: '풀다운 로딩 성공'
    },
    // 'appSearchHistory': {
    //   navTitle: 'Search',
    //   searchPlace: 'Search...',
    //   recommendTitle: 'Recommend'
    // },
    // 'appClassList': {
    //   searchPlace: 'Search...',
    //   select1: 'Comprehensive',
    //   select2: 'Sales Volume',
    //   select3: 'New product',
    //   select4: 'Price High to Low',
    //   select5: 'Price Low to High',
    //   select6: 'Score',
    //   paymentTitle: 'payment',
    //   listTag1: 'Free Shipping',
    //   favoriteTitle: 'Favorite Quantity &',
    //   shopBtn: 'View Closets',
    //   recommendNavTitle: 'Recommend',
    //   usedNavTitle: 'Used',
    //   shopNavTitle: 'Shop'
    // },

    // 'appPreSale': {
    //   navTitle: 'Presale',
    //   tabs1: 'Started',
    //   tabs2: 'Not Started',
    //   tabs3: 'Ended',
    //   searchPlace: 'Search...',
    //   select1: 'Comprehensive',
    //   select2: 'Sales Volume',
    //   select3: 'New product',
    //   select4: 'Price High to Low',
    //   select5: 'Price Low to High',
    //   select6: 'Score',

    //   preSale: 'Presale',
    //   freeShipping: 'Free Shipping',
    //   payment: 'payment',
    //   preSale: 'presale',
    //   freeShipping: "Free Shipping"
    // },
    // "appGroupBuying": {
    //   navTitle: 'Group Buying',
    //   searchPlace: 'Search...',
    //   select1: 'Comprehensive',
    //   select2: 'Sales Volume',
    //   select3: 'New product',
    //   select4: 'Price High to Low',
    //   select5: 'Price Low to High',
    //   select6: 'Score',

    //   preSale: 'Presale',
    //   freeShipping: 'Free Shipping',
    //   payment: 'payment',
    //   btn1: 'Group Buying',
    //   btn2: "Free Shipping"
    // },
    // "appFlashSale": {
    //   navTitle: 'Flash Sale',
    //   searchPlace: 'Search...',
    //   select1: 'Comprehensive',
    //   select2: 'Sales Volume',
    //   select3: 'New product',
    //   select4: 'Price High to Low',
    //   select5: 'Price Low to High',
    //   select6: 'Score',

    //   preSale: 'Presale',
    //   freeShipping: 'Free Shipping',
    //   payment: 'payment',
    //   preSale: 'presale',
    //   freeShipping: "Free Shipping"
    // },
};
export default obj;
