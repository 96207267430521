// 俄语
let obj = {};
obj = {
    // 语言
    languageVersion: [
        'English',  // 英语
        '日本語', // 日语
        'Português', // 葡萄牙语
        '繁體中文', // 繁体中文
        'Français', // 法语
        'Deutsch', // 德语
        'Türk', // 土耳其语
        'Español', // 西班牙语
        'عربي', // 阿拉伯语
        '한국인', // 韩语
        'Suomi', // 芬兰语
        'Русский', // 俄语
        'italiano', // 意大利语
        'แบบไทย', // 泰语
        'Nederlands', // 荷兰语
    ],
    'footer': {
        home: "Дом",
    },
    'setDrawer': {
        label1: 'Многоязычные настройки',
        label2: 'Регистрация магазина',
        label3: 'Вход в магазин',
        label4: 'Служба поддержки клиентов',
        label5: 'Загрузить приложение для Android',
        label6: 'Загрузить приложение для iOS',
        optionsBtn: 'Отмена'
    },
    'appHome': {
        searchPlace: 'Искать...',
        recommendTitle: 'Рекомендую',
        viewAll: 'просмотреть все',
        usedTitle: 'Б/у',
        shopTitle: 'Магазин',
        shopOption: 'Просмотреть сейчас',
        hotTitle: 'Горячий список',
        classTag1: 'Дом',
        classTag2: 'Женщины',
        classTag3: 'Мужчины',
        classTag4: 'Дети',
        classTag5: 'Электроника',
        classTag6: 'Домашние животные',
        buyNow: 'Купить сейчас',

        // ranking: 'Sales Ranking',
        // preSaleTitle: 'Presale',
        // preSaleTabs1: 'Started',
        // preSaleTabs2: 'Not started',
        // preSaleTabs3: 'Ended',
        // groupTitle: 'Group Buying',
        // peopleGroupBuying: '人参与团购',
        // flashSaleTitle: 'Flash Sale',
    },
    'appSetLanguage': {
        navTitle: 'Многоязычные настройки',
        affirm: 'подтвердить',
    },
    "appService": {
        navTitle: 'Служба поддержки клиентов',
        customerService: 'Эксклюзивное обслуживание клиентов',
        helloTitle: 'Привет',
        welcomeTitle: 'Я твой<, вы можете связаться со мной по следующим каналам',
        iconInformation: 'Пожалуйста, выберите коммуникационное программное обеспечение для консультации',
        whatsApp: 'whatsApp ',
        telegram: 'telegram ',
        line: 'line',
    },
    // 移动分类页
    'appClassification': {
        navTitle: 'Классификация товаров',
        viewAll: 'Просмотреть все',
    },
    'appHotList': {
        navTitle: 'Горячий список',
        ranking: 'Рейтинг продаж',

    },
    'appHotRanking': {
        navTitle: 'Рейтинг продаж Accent',
        searchPlace: 'Искать...',
        payment: 'платеж',
        optionBtn1: 'Собрать',
        optionBtn2: 'Купить сейчас',
        errorMessage: 'Пожалуйста, войдите в систему',
    },
    'appUsedList': {
        navTitle: 'Используется',
        recommendNavTitle: 'Рекомендую',
        classNavTitle: 'Классификация',
        searchNavTitle: 'Поиск',
        searchPlace: 'Искать...',
        select1: 'Комплексный',
        select2: 'Объем продаж',
        select3: 'Новый продукт',
        select4: 'Цена от высокой до низкой',
        select5: 'Цена от низкой до высокой',
        select6: 'Оценка',
        classAll: 'Все',
        listTag1: 'Бесплатная доставка',
        paymentTitle: 'Платеж'
    },
    'appRecommendList': {
        navTitle: 'Рекомендую',
        searchPlace: 'Искать...',
        select1: 'Комплексный',
        select2: 'Объем продаж',
        select3: 'Новый продукт',
        select4: 'Цена от высокой до низкой',
        select5: 'Цена от низкой до высокой',
        select6: 'Оценка',
        classAll: 'Все',
        listTag1: 'Бесплатная доставка',
        paymentTitle: 'Платеж'
    },
    'appLogin': {
        navTitle: 'Войти',
        navTitleHint: 'Войдите сейчас и начните торговать с друзьями со всего мира!',
        singUp: 'Зарегистрироваться',
        emailLabel: 'Номер телефона',
        emailPlaceholder: 'Пожалуйста, введите номер телефона',
        emailErrorText: 'Подсказка об ошибке',
        emailNullVerify: 'Пожалуйста, введите номер телефона', //请输入邮箱
        passwordLabel: 'Ваш пароль',
        passPlaceholder: 'Пожалуйста, введите пароль',
        passNullVerify: 'Пожалуйста, введите свой пароль',//请输入密码
        passErrorVerify: 'Пароль содержит менее 6 символов', //密码小于6位
        placeholder: 'Пожалуйста, введите',
        emailRegistration: 'Регистрация номера телефона',
        forgetPassTitle: 'Забыли пароль?',
        submitBtnTitle: 'Войти',
        errorMessage: 'Неверное имя пользователя или пароль',
        // emailErrorVerify: '邮箱格式错误！',//邮箱格式错误
    },
    'appForgotPass': {
        navTitle: 'Забыли пароль',
        navTitleHint: 'Пожалуйста, запишите номер своего мобильного телефона, чтобы получить код подтверждения для установки нового пароля.',
        emailLabel: 'Номер телефона',
        emailPlaceholder: 'Пожалуйста, введите номер телефона',
        emailErrorText: 'Пожалуйста, введите номер телефона', //请输入邮箱
        emailNullVerify: 'Пожалуйста, введите номер телефона', //请输入邮箱
        emailErrorVerify: 'Неверный номер мобильного телефона', //邮箱格式错误
        verificationCode: 'Код проверки',
        verificationPlace: 'Код подтверждения',
        verificationCodeError: 'Пожалуйста, введите код подтверждения',
        sendTitle: 'Подтвердите номер мобильного телефона',
        sendText: 'Повторная проверка',
        passwordLabel: 'Пароль',
        passPlaceholder: 'Пожалуйста, введите пароль',
        confirmPassLabel: 'Подтвердить пароль',
        confirmPlace: 'заполнитель для подтверждения пароля',
        passNullVerify: 'Введите свой пароль',//请输入密码
        passErrorVerify: 'Пароль содержит менее 6 символов', //密码小于6位
        passCompareErrorVerify: 'Пароли несовместимы',
        passCompareErrorNull: 'Пожалуйста, введите пароль еще раз',
        emailRegistration: 'Регистрация номера телефона',
        forgetPassTitle: 'Забыли пароль?',
        submitBtnTitle: 'Сбросить пароль',
        submitErrorMessage: 'Неправильный код подтверждения'
    },
    'appRegister': {
        navTitle: 'Регистрация',
        navTitleHint: 'Создайте учетную запись, чтобы получить доступ ко всем функциям!',
        firstNameLabel: 'Имя',
        firstNamePlace: 'Пожалуйста, введите',
        firstNameErrorVerify: 'Пожалуйста, введите свое имя',
        lastNameLabel: 'Фамилия',
        lastNameErrorVerify: 'Пожалуйста, введите свою фамилию',
        nickNameLabel: 'Псевдоним',
        nickNameErrorVerify: 'Пожалуйста, введите свой никнейм',
        namePlace: 'Пожалуйста, введите',
        gender: 'Пол',
        gender1: 'Мальчик',
        gender2: 'Девочка',
        gender3: 'Секретность',
        genderError: 'Пожалуйста, введите свой пол',
        emailLabel: 'Номер телефона',
        emailPlaceholder: 'Пожалуйста, введите номер телефона!',
        emailErrorText: 'Сообщение об ошибке!',//请输入邮箱
        emailNullVerify: 'Пожалуйста, введите номер телефона!',//请输入邮箱
        verificationCode: 'Код проверки',
        verificationPlace: 'Код подтверждения',
        sendTitle: 'Подтвердите номер мобильного телефона',
        sendText: 'Повторная проверка',
        verificationCodeError: 'Код подтверждения не может быть пустым',
        passwordLabel: 'Пароль',
        passPlaceholder: 'Пожалуйста, введите пароль',
        confirmPassLabel: 'Подтвердить пароль',
        confirmPlace: 'заполнитель для подтверждения пароля',
        passNullVerify: 'введите свой пароль',//请输入密码
        passErrorVerify: 'Пароль содержит менее 6 символов！',//密码小于6位
        passCompareErrorVerify: 'Пароли несовместимы！',
        passCompareErrorNull: 'Пожалуйста, введите пароль еще раз',
        registerHint: 'Уже есть учетная запись?',
        registerHintLogin: 'Войти',
        submitBtnTitle: 'Зарегистрироваться',
        submitErrorMessage: 'Неправильный код подтверждения'
        // emailErrorVerify: '邮箱格式错误!',//邮箱格式错误
    },
    "appShopList": {
        navTitle: 'Список магазинов',
        searchPlace: 'Искать...'
    },
    'appGoodsDetail': {
        salesTitle: 'Продажи',
        conditionLabel: 'Условие:',
        conditionContent: 'Платежи, поддерживаемые по категориям',
        agreement: 'Соглашение о защите покупателя',
        specSelect: 'Выбрать спецификации',
        payLabel1: 'Баланс',
        payLabel2: 'PayPal',
        payLabel3: 'Виза',
        commentTitle: 'Комментарий',
        frightLabel: 'Груз',
        frightContent: 'Доставка в среднем в течение 8 дней',
        deliverTime: 'Оценить время и дни доставки',
        productQuantity: 'Количество ',
        soldQuantity: "Объем продаж",
        collections: 'Количество заказа',
        since: 'Участник с',
        shipper: 'Быстрый грузоотправитель',
        responder: 'Быстрый ответчик',
        reliable: 'Надежный',
        btnContact: 'Контакт',
        enter: 'Ввод',
        followBtn: 'Следовать',
        guessLike: 'Думаю, тебе это понравится',
        buyNow: 'Купить сейчас',
        dialogOverviewTitle: 'Обзор',
        overConditionLabel: 'Условие',
        overConditionContent: 'Новый',
        overBrandLabel: 'Бренд',
        overCategoryLabel: 'Категория',
        overPayMethodsLabel: 'Поддерживаемые платежи',
        payTitle: 'Поддерживаемые платежи',
        payHint: 'Цвет красивый, выглядит красиво и стильно. Его можно носить со всем широким ассортиментом одежды. Он очень красивый. Он идеально подходит для сочетания с повседневной и модной одеждой',
        overCategoryEnter: 'Ввод',
        dialogFreightTitle: 'Грузовые перевозки',
        dialogAgreementTitle: 'Соглашение о защите покупателя',
        commitContent: 'Похвала системы по умолчанию',
        footerLeft: 'Добавить в корзину',
        footerRight: 'Купить сейчас'
    },
    'specificationDialog': {
        inventory: 'Инвентарь',
        countLabel: 'Число',
        specLabel: 'По умолчанию',
        options1: 'Добавить в корзину',
        options2: 'Купить сейчас',

    },
    "appShopsDetail": {
        select1: 'Комплексный',
        select2: 'Объем продаж',
        select4: 'Цена от высокой до низкой',
        select5: 'Цена от низкой до высокой',
        select6: 'Оценка',
        follow: 'Следовать',
        timeDay: 'Оценить время и дни доставки',
        productQuantity: 'Количество ',
        shippedQuantity: "Объем продаж",
        collections: 'Количество заказа',
        commodity: 'Товар',
        classAll: 'Все'
    },
    // 产品评论页
    'appProductReviews': {
        navTitle: 'Комментарий',
        tags1: 'все',
        tags2: '5 звезд',
        tags3: '4 звезды',
        tags4: '3 звезды',
        tags5: '2 звезды',
        tags6: '1 звезда',
        tags7: 'С медиа',
        commitContent: 'Похвала системы по умолчанию'
    },
    // app升级配置文件语言
    configLanguage: {
        upgradePrompt: 'Обновление версии, пожалуйста, подтвердите? ',
        loadingTitle: 'Загрузка...',
        systemUpgrade: 'Обновление системы',
        upgradeImmediately: 'Подтвердить',
    },
    // 公共提示
    messageLanguage: {
        loadingTitle: "Загрузка", // Загрузка
        finishedText: "Нет", //Нет
        loadingText: "Загрузка", // Загрузка
        nullText: 'Данных пока нет',
        loadingFinished: "Загрузка завершена",
        listNullText: 'Больше нет',
        pullIngText: 'Потяните вниз, чтобы обновить',
        loosingText: 'Отпустите для обновления',
        pullingSuccess: 'Растягивающая загрузка прошла успешно'
    },
    // 'appSearchHistory': {
    //   navTitle: 'Search',
    //   searchPlace: 'Search...',
    //   recommendTitle: 'Recommend'
    // },
    // 'appClassList': {
    //   searchPlace: 'Search...',
    //   select1: 'Comprehensive',
    //   select2: 'Sales Volume',
    //   select3: 'New product',
    //   select4: 'Price High to Low',
    //   select5: 'Price Low to High',
    //   select6: 'Score',
    //   paymentTitle: 'payment',
    //   listTag1: 'Free Shipping',
    //   favoriteTitle: 'Favorite Quantity &',
    //   shopBtn: 'View Closets',
    //   recommendNavTitle: 'Recommend',
    //   usedNavTitle: 'Used',
    //   shopNavTitle: 'Shop'
    // },

    // 'appPreSale': {
    //   navTitle: 'Presale',
    //   tabs1: 'Started',
    //   tabs2: 'Not Started',
    //   tabs3: 'Ended',
    //   searchPlace: 'Search...',
    //   select1: 'Comprehensive',
    //   select2: 'Sales Volume',
    //   select3: 'New product',
    //   select4: 'Price High to Low',
    //   select5: 'Price Low to High',
    //   select6: 'Score',

    //   preSale: 'Presale',
    //   freeShipping: 'Free Shipping',
    //   payment: 'payment',
    //   preSale: 'presale',
    //   freeShipping: "Free Shipping"
    // },
    // "appGroupBuying": {
    //   navTitle: 'Group Buying',
    //   searchPlace: 'Search...',
    //   select1: 'Comprehensive',
    //   select2: 'Sales Volume',
    //   select3: 'New product',
    //   select4: 'Price High to Low',
    //   select5: 'Price Low to High',
    //   select6: 'Score',

    //   preSale: 'Presale',
    //   freeShipping: 'Free Shipping',
    //   payment: 'payment',
    //   btn1: 'Group Buying',
    //   btn2: "Free Shipping"
    // },
    // "appFlashSale": {
    //   navTitle: 'Flash Sale',
    //   searchPlace: 'Search...',
    //   select1: 'Comprehensive',
    //   select2: 'Sales Volume',
    //   select3: 'New product',
    //   select4: 'Price High to Low',
    //   select5: 'Price Low to High',
    //   select6: 'Score',

    //   preSale: 'Presale',
    //   freeShipping: 'Free Shipping',
    //   payment: 'payment',
    //   preSale: 'presale',
    //   freeShipping: "Free Shipping"
    // },
};
export default obj;
