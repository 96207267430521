// 葡萄牙语
let obj = {};
obj = {
    // 语言
    languageVersion: [
        'English',  // 英语
        '日本語', // 日语
        'Português', // 葡萄牙语
        '繁體中文', // 繁体中文
        'Français', // 法语
        'Deutsch', // 德语
        'Türk', // 土耳其语
        'Español', // 西班牙语
        'عربي', // 阿拉伯语
        '한국인', // 韩语
        'Suomi', // 芬兰语
        'Русский', // 俄语
        'italiano', // 意大利语
        'แบบไทย', // 泰语
        'Nederlands', // 荷兰语
    ],
    'footer': {
        home: "Lar",
    },
    'setDrawer': {
        label1: 'Configurações multilíngues',
        label2: 'Registro da loja',
        label3: 'Login da loja',
        label4: 'Atendimento ao Cliente',
        label5: 'Baixar aplicativo Android',
        label6: 'Baixar aplicativo iOS',
        optionsBtn: 'Cancelar'
    },
    'appHome': {
        searchPlace: 'Pesquisar...',
        recommendTitle: 'Recomendar',
        viewAll: 'ver tudo',
        usedTitle: 'Usado',
        shopTitle: 'Loja',
        shopOption: 'Ver agora',
        hotTitle: 'Lista de favoritos',
        classTag1: 'Casa',
        classTag2: 'Mulheres',
        classTag3: 'Homens',
        classTag4: 'Crianças',
        classTag5: 'Eletrônicos',
        classTag6: 'Animais de estimação',
        buyNow: 'Compre agora',

        // ranking: 'Sales Ranking',
        // preSaleTitle: 'Presale',
        // preSaleTabs1: 'Started',
        // preSaleTabs2: 'Not started',
        // preSaleTabs3: 'Ended',
        // groupTitle: 'Group Buying',
        // peopleGroupBuying: '人参与团购',
        // flashSaleTitle: 'Flash Sale',
    },
    'appSetLanguage': {
        navTitle: 'Configurações multilíngues',
        affirm: 'confirmar',
    },
    "appService": {
        navTitle: 'Atendimento ao Cliente',
        customerService: 'Atendimento exclusivo ao cliente',
        helloTitle: 'Olá',
        welcomeTitle: 'Eu sou seu<, você pode entrar em contato comigo através dos seguintes canais',
        iconInformation: 'Selecione o software de comunicação para consulta',
        whatsApp: 'whatsApp ',
        telegram: 'telegram ',
        line: 'line',
    },
    // 移动分类页
    'appClassification': {
        navTitle: 'Classificação de mercadorias',
        viewAll: 'Ver tudo',
    },
    'appHotList': {
        navTitle: 'Lista de favoritos',
        ranking: 'Classificação de vendas',
    },
    'appHotRanking': {
        navTitle: 'Classificação de vendas de destaques',
        searchPlace: 'Pesquisar...',
        payment: 'pagamento',
        optionBtn1: 'Coletar',
        optionBtn2: 'Compre agora',
        errorMessage: 'Por favor faça login',
    },
    'appUsedList': {
        navTitle: 'Usado',
        recommendNavTitle: 'Recomendar',
        classNavTitle: 'Classificação',
        searchNavTitle: 'Pesquisar',
        searchPlace: 'Pesquisar...',
        select1: 'Abrangente',
        select2: 'Volume de vendas',
        select3: 'Novo produto',
        select4: 'Preço alto a baixo',
        select5: 'Preço baixo a alto',
        select6: 'Pontuação',
        classAll: 'Todos',
        listTag1: 'Frete Grátis',
        paymentTitle: 'Pagamento'
    },
    'appRecommendList': {
        navTitle: 'Recomendar',
        searchPlace: 'Pesquisar...',
        select1: 'Abrangente',
        select2: 'Volume de vendas',
        select3: 'Novo produto',
        select4: 'Preço alto a baixo',
        select5: 'Preço baixo a alto',
        select6: 'Pontuação',
        classAll: 'Todos',
        listTag1: 'Frete Grátis',
        paymentTitle: 'Pagamento'
    },
    'appLogin': {
        navTitle: 'Login',
        navTitleHint: 'Faça login agora e comece a negociar com amigos de todo o mundo!',
        singUp: 'Inscreva-se',
        emailLabel: 'Número de telefone',
        emailPlaceholder: 'Por favor, insira o número de telefone',
        emailErrorText: 'Prompt de erro',
        emailNullVerify: 'Por favor, insira o número de telefone', //请输入邮箱
        passwordLabel: 'Sua senha',
        passPlaceholder: 'Por favor, insira a senha',
        passNullVerify: 'Por favor, digite sua senha', //请输入密码
        passErrorVerify: 'A senha tem menos de 6 caracteres', //密码小于6位
        placeholder: 'Por favor, insira',
        emailRegistration: 'Registro de número de telefone',
        forgetPassTitle: 'Esqueceu a senha?',
        submitBtnTitle: 'Login',
        errorMessage: 'Nome de usuário ou senha incorretos',
        // emailErrorVerify: '邮箱格式错误！',//邮箱格式错误
    },
    'appForgotPass': {
        navTitle: 'Esqueci minha senha',
        navTitleHint: 'Por favor, anote o número do seu celular para receber um código de confirmação para definir uma nova senha.',
        emailLabel: 'Número de telefone',
        emailPlaceholder: 'Por favor, insira o número de telefone',
        emailErrorText: 'Por favor, insira o número de telefone', //请输入邮箱
        emailNullVerify: 'Por favor, insira o número de telefone', //请输入邮箱
        emailErrorVerify: 'Número de celular errado', //邮箱格式错误
        verificationCode: 'Código de verificação',
        verificationPlace: 'Código de verificação',
        verificationCodeError: 'Por favor, insira o código de verificação',
        sendTitle: 'Confirmar número de celular',
        sendText: 'Revalidação',
        passwordLabel: 'Senha',
        passPlaceholder: 'Por favor, insira a senha',
        confirmPassLabel: 'Confirmar senha',
        confirmPlace: 'espaço reservado para confirmar senha',
        passNullVerify: 'Digite sua senha', //请输入密码
        passErrorVerify: 'A senha tem menos de 6 caracteres', //密码小于6位
        passCompareErrorVerify: 'As senhas são inconsistentes',
        passCompareErrorNull: 'Por favor, digite a senha novamente',
        emailRegistration: 'Registro de número de telefone',
        forgetPassTitle: 'Esqueceu a senha?',
        submitBtnTitle: 'Redefinir senha',
        submitErrorMessage: 'Código de verificação incorreto'
    },
    'appRegister': {
        navTitle: 'Registrar',
        navTitleHint: 'Crie uma conta para acessar todos os recursos!',
        firstNameLabel: 'Nome',
        firstNamePlace: 'Por favor, insira',
        firstNameErrorVerify: 'Por favor, digite seu primeiro nome',
        lastNameLabel: 'Sobrenome',
        lastNameErrorVerify: 'Por favor, digite seu sobrenome',
        nickNameLabel: 'Apelido',
        nickNameErrorVerify: 'Por favor, digite seu apelido',
        namePlace: 'Por favor, insira',
        gender: 'Gênero',
        gender1: 'Menino',
        gender2: 'Menina',
        gender3: 'Sigilo',
        genderError: 'Por favor, insira seu gênero',
        emailLabel: 'Número de telefone',
        emailPlaceholder: 'Por favor, insira o número de telefone!',
        emailErrorText: 'Mensagem de erro!', //请输入邮箱
        emailNullVerify: 'Por favor, insira o número de telefone!', //请输入邮箱
        verificationCode: 'Código de verificação',
        verificationPlace: 'Código de verificação',
        sendTitle: 'Confirmar número de celular',
        sendText: 'Revalidação',
        verificationCodeError: 'O código de verificação não pode estar vazio',
        passwordLabel: 'Senha',
        passPlaceholder: 'Por favor, insira a senha',
        confirmPassLabel: 'Confirmar senha',
        confirmPlace: 'espaço reservado para confirmar senha',
        passNullVerify: 'digite sua senha', //请输入密码
        passErrorVerify: 'A senha tem menos de 6 caracteres！', //密码小于6位
        passCompareErrorVerify: 'As senhas são inconsistentes！',
        passCompareErrorNull: 'Por favor, digite a senha novamente',
        registerHint: 'Já tem uma conta?',
        registerHintLogin: 'Login',
        submitBtnTitle: 'Registrar',
        submitErrorMessage: 'Código de verificação incorreto'
        // emailErrorVerify: '邮箱格式错误!',//邮箱格式错误
    },
    "appShopList": {
        navTitle: 'Lista de compras',
        searchPlace: 'Pesquisar...'
    },
    'appGoodsDetail': {
        salesTitle: 'Vendas',
        conditionLabel: 'Condição:',
        conditionContent: 'Pagamentos suportados por categoria',
        agreement: 'Acordo de Proteção ao Comprador',
        specSelect: 'Selecionar especificações',
        payLabel1: 'Saldo',
        payLabel2: 'PayPal',
        payLabel3: 'Visto',
        commentTitle: 'Comentário',
        frightLabel: 'Frete',
        frightContent: 'Enviado em média em 8 dias',
        deliverTime: 'Avaliar prazo e dias de entrega',
        productQuantity: 'Quantidade',
        soldQuantity: "Volume de vendas",
        collections: 'Quantidade do pedido',
        since: 'Membro desde',
        shipper: 'expedidor rápido',
        responder: 'Respondente rápido',
        reliable: 'Confiável',
        btnContact: 'Contato',
        enter: 'Entrar',
        followBtn: 'Seguir',
        guessLike: 'Acho que você gostou',
        buyNow: 'Compre agora',
        dialogOverviewTitle: 'Visão geral',
        overConditionLabel: 'Condição',
        overConditionContent: 'Novo',
        overBrandLabel: 'Marca',
        overCategoryLabel: 'Categoria',
        overPayMethodsLabel: 'Pagamentos suportados',
        payTitle: 'Pagamentos suportados',
        payHint: 'A cor é linda, parece elegante e elegante. Pode ser usado com uma ampla variedade de roupas. tornando-o muito bonito. É perfeito para combinar com roupas casuais e elegantes',
        overCategoryEnter: 'Entrar',
        dialogFreightTitle: 'Frete',
        dialogAgreementTitle: 'Acordo de Proteção ao Comprador',
        commitContent: 'Elogio padrão do sistema',
        footerLeft: 'Adicionar ao carrinho',
        footerRight: 'Compre agora'
    },
    'specificationDialog': {
        inventory: 'Inventário',
        countLabel: 'Número',
        specLabel: 'Padrão',
        options1: 'Adicionar ao carrinho',
        options2: 'Compre agora',

    },
    "appShopsDetail": {
        select1: 'Abrangente',
        select2: 'Volume de vendas',
        select4: 'Preço alto a baixo',
        select5: 'Preço baixo a alto',
        select6: 'Pontuação',
        follow: 'Siga',
        timeDay: 'Avaliar prazo e dias de entrega',
        productQuantity: 'Quantidade',
        shippedQuantity: "Volume de vendas",
        collections: 'Quantidade do pedido',
        commodity: 'Mercadoria',
        classAll: 'Todos'
    },
    // 产品评论页
    'appProductReviews': {
        navTitle: 'Comentário',
        tags1: 'todos',
        tags2: '5 estrelas',
        tags3: '4 estrelas',
        tags4: '3 estrelas',
        tags5: '2 estrelas',
        tags6: '1 estrela',
        tags7: 'Com mídia',
        commitContent: 'Elogio padrão do sistema'
    },
    // app升级配置文件语言
    configLanguage: {
        upgradePrompt: 'Atualização de versão, confirme? ',
        loadingTitle: 'Baixando...',
        systemUpgrade: 'Atualização do sistema',
        upgradeImmediately: 'Confirmar',
    },
    // 公共提示
    messageLanguage: {
        loadingTitle: "Carregando", // Carregando
        finishedText: "Nenhum", //Nenhum
        loadingText: "Carregando", // Carregando
        nullText: 'Ainda não há dados',
        loadingFinished: "Carregamento concluído",
        listNullText: 'Não mais',
        pullIngText: 'Puxe para baixo para atualizar',
        loosingText: 'Libere para atualizar',
        pullingSuccess: 'Carregamento suspenso bem-sucedido'
    },
    // 'appSearchHistory': {
    //   navTitle: 'Search',
    //   searchPlace: 'Search...',
    //   recommendTitle: 'Recommend'
    // },
    // 'appClassList': {
    //   searchPlace: 'Search...',
    //   select1: 'Comprehensive',
    //   select2: 'Sales Volume',
    //   select3: 'New product',
    //   select4: 'Price High to Low',
    //   select5: 'Price Low to High',
    //   select6: 'Score',
    //   paymentTitle: 'payment',
    //   listTag1: 'Free Shipping',
    //   favoriteTitle: 'Favorite Quantity &',
    //   shopBtn: 'View Closets',
    //   recommendNavTitle: 'Recommend',
    //   usedNavTitle: 'Used',
    //   shopNavTitle: 'Shop'
    // },

    // 'appPreSale': {
    //   navTitle: 'Presale',
    //   tabs1: 'Started',
    //   tabs2: 'Not Started',
    //   tabs3: 'Ended',
    //   searchPlace: 'Search...',
    //   select1: 'Comprehensive',
    //   select2: 'Sales Volume',
    //   select3: 'New product',
    //   select4: 'Price High to Low',
    //   select5: 'Price Low to High',
    //   select6: 'Score',

    //   preSale: 'Presale',
    //   freeShipping: 'Free Shipping',
    //   payment: 'payment',
    //   preSale: 'presale',
    //   freeShipping: "Free Shipping"
    // },
    // "appGroupBuying": {
    //   navTitle: 'Group Buying',
    //   searchPlace: 'Search...',
    //   select1: 'Comprehensive',
    //   select2: 'Sales Volume',
    //   select3: 'New product',
    //   select4: 'Price High to Low',
    //   select5: 'Price Low to High',
    //   select6: 'Score',

    //   preSale: 'Presale',
    //   freeShipping: 'Free Shipping',
    //   payment: 'payment',
    //   btn1: 'Group Buying',
    //   btn2: "Free Shipping"
    // },
    // "appFlashSale": {
    //   navTitle: 'Flash Sale',
    //   searchPlace: 'Search...',
    //   select1: 'Comprehensive',
    //   select2: 'Sales Volume',
    //   select3: 'New product',
    //   select4: 'Price High to Low',
    //   select5: 'Price Low to High',
    //   select6: 'Score',

    //   preSale: 'Presale',
    //   freeShipping: 'Free Shipping',
    //   payment: 'payment',
    //   preSale: 'presale',
    //   freeShipping: "Free Shipping"
    // },
};
export default obj;
