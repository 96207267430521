import Vue from 'vue'
import { languageList } from '@/utils/language/language'

import {
  Toast
} from "vant";
let messageLanguage
import axios from 'axios'
import baseUrl from '@/utils/projectConfig'
const ServerRequestUrl = baseUrl.baseUrl
const service = axios.create({
  baseURL: ServerRequestUrl
})
Vue.nextTick(() => {
  messageLanguage = languageList('messageLanguage').loadingTitle

})
let loadingCount = 0
// request拦截器==>对请求参数做处理
service.interceptors.request.use(config => {
  loadingCount += 1

  config.headers.Authorization = window.localStorage.getItem('authenticationToKen_');
  config.headers['Accept-Language'] = baseUrl.lang[window.localStorage.getItem('languageType')]
  console.log(loadingCount, 'loadingCount')
  if (loadingCount == 1) {
    Toast.loading({
      forbidClick: true,
      duration: 2500,
      message: messageLanguage + '...'
    })
  }
  return config
}, error => { // 请求错误处理
  Promise.reject(error)
})

// respone拦截器==>对响应做处理
service.interceptors.response.use(
  response => {
    // 成功请求到数据

    // 这里根据后端提供的数据进行对应的处理 
    if (response.data.code === 200) {
      loadingCount--

      if (loadingCount == 0) {
        setTimeout(() => {
          Toast.clear()
        }, 1300)
      }
    } else if (response.data.code == 1002) {
      loadingCount--
      if (loadingCount == 0) {
        Toast.clear()
      }
      sessionStorage.clear();
      localStorage.clear()
    } else if (response.data.code == 400) {
      loadingCount--
      if (loadingCount == 0) {
        Toast.clear()
      }
      sessionStorage.clear();
      localStorage.clear()
    } else {
      loadingCount--
      if (loadingCount == 0) {
        Toast.clear()
      }
      sessionStorage.clear();
      localStorage.clear()
    }
    return response.data
  },
  error => {
    Toast.clear()
    let text = JSON.parse(JSON.stringify(error))
    Toast.fail({
      forbidClick: true,
      duration: 2500,
      message: text.message
    })

    return Promise.reject(error)
  }
)
export default service