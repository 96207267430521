import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)
let routes = [
  {
    path: "/",
    name: "appIndex",
    redirect: "/appIndex",
  },

  {
    path: "/appIndex",
    name: "appIndex",
    component: () => import(/* webpackChunkName: "appIndex" */ "@/views/app-index.vue"),
    redirect: "/appHome",
    meta: { title: '首页', keepAlive: true },
    children: [
      {
        path: "/appHome",
        name: "appHomePageIndex",
        component: () => import(/* webpackChunkName: "appHome" */ "@/views/appHome/appHome-index.vue"),
        meta: { title: '首页', keepAlive: true },
      },
      {
        path: "/appService",
        name: "appServiceIndex",
        component: () => import(/* webpackChunkName: "appService" */ "@/views/appService/appService-index.vue"),
        meta: { title: '客服', keepAlive: true },
      },
      {
        path: "/appShopList",
        name: "appShopList",
        component: () => import(/* webpackChunkName: "appShopList" */ "@/views/appShopList/appShopList.vue"),
        meta: { title: '店铺列表', keepAlive: true },
      },
      {
        path: "/appSetLanguage",
        name: "appSetLanguageIndex",
        component: () => import(/* webpackChunkName: "appSetLanguage" */ "@/views/appSetLanguage/setLanguage-index.vue"),
        meta: { title: '客服', keepAlive: true },
      },
      {
        path: "/appClassification",
        name: "appClassificationIndex",
        component: () => import(/* webpackChunkName: "appClassification" */ "@/views/appClassification/appClassification-index.vue"),
        meta: { title: '分类', keepAlive: true },
      },


      {
        path: "/appHotList",
        name: "appHotListPage",
        component: () => import(/* webpackChunkName: "appHotList" */ "@/views/appHotList/app-hot-list.vue"),
        meta: { title: '热销', },
      },
      // {
      //   path: "/appClassList",
      //   name: "appClassListPage",
      //   component: () => import(/* webpackChunkName: "appClassList" */ "@/views/appClassList/appClassList.vue"),
      //   meta: { title: '分类列表页' },
      // },
      // {
      //   path: "/appSearchHistory",
      //   name: "appSearchHistoryIndex",
      //   component: () => import(/* webpackChunkName: "appSearchHistory" */ "@/views/appSearch-history/search-history.vue"),
      //   meta: { title: '搜索', keepAlive: true },
      // },
      // {
      //   path: "/appPreSale",
      //   name: "appPreSaleIndex",
      //   component: () => import(/* webpackChunkName: "appHotList" */ "@/views/appPreSale/app-preSale-index.vue"),
      //   meta: { title: '预售', },
      // },
      // {
      //   path: "/appGroupBuying",
      //   name: "appGroupBuyingIndex",
      //   component: () => import(/* webpackChunkName: "appHotList" */ "@/views/appGroupBuying/app-groupBuying.vue"),
      //   meta: { title: '团购', },
      // },
      // {
      //   path: "/appFlashSale",
      //   name: "appFlashSalePage",
      //   component: () => import(/* webpackChunkName: "appHotList" */ "@/views/appFlashSale/app-flashSale.vue"),
      //   meta: { title: '限时抢购', },
      // },
      {
        path: "/appHotRanking",
        name: "appHotRanking",
        component: () => import(/* webpackChunkName: "appHotRanking" */ "@/views/appHotRanking/app-hotRanking.vue"),
        meta: { title: '热销排名', },
      },
      {
        path: "/appUsedList",
        name: "appUsedList",
        component: () => import(/* webpackChunkName: "appUsedList" */ "@/views/appUsedList/used-list.vue"),
        meta: { title: '使用过', },
      },

      {
        path: "/appLogin",
        name: "appLoginIndex",
        component: () => import(/* webpackChunkName: "appLogin" */ "@/views/appLogin/app-login-index.vue"),
        meta: { title: '登录页', keepAlive: true },
      },
      {
        path: "/appForgotPass",
        name: "appForgotPassIndex",
        component: () => import(/* webpackChunkName: "appForgotPass" */ "@/views/appForgotPass/app-forgot-pass.vue"),
        meta: { title: '忘记密码', keepAlive: true },
      },
      {
        path: "/appRegister",
        name: "appRegisterIndex",
        component: () => import(/* webpackChunkName: "appRegister" */ "@/views/appRegister/app-register-index.vue"),
        meta: { title: '注册', keepAlive: true },
      },
      {
        path: "/appGoodsDetail",
        name: "appGoodsDetailIndex",
        component: () => import(/* webpackChunkName: "appGoodsDetail" */ "@/views/appGoodsDetail/app-goods-detail.vue"),
        meta: { title: '商品详情', keepAlive: false },
      },
      {
        path: "/appProductReviews",
        name: "appReviewsPageIndex",
        component: () => import(/* webpackChunkName: "appProductReviews" */ "@/views/appProductReviews/app-reviews-index.vue"),
        meta: { title: '产品评论', keepAlive: true },
      },
      {
        path: "/appShopsDetail",
        name: "appShopsDetail",
        component: () => import(/* webpackChunkName: "appShops" */ "@/views/appShopsPage/app-shops-index.vue"),
        meta: { title: '店铺详情', keepAlive: true },
      },
    ]
  },


]
const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  document.body.scrollTop = 0
  document.documentElement.scrollTop = 0
  next()
})
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}
export default router