// 泰语
let obj = {};
obj = {
    // 语言
    languageVersion: [
        'English',  // 英语
        '日本語', // 日语
        'Português', // 葡萄牙语
        '繁體中文', // 繁体中文
        'Français', // 法语
        'Deutsch', // 德语
        'Türk', // 土耳其语
        'Español', // 西班牙语
        'عربي', // 阿拉伯语
        '한국인', // 韩语
        'Suomi', // 芬兰语
        'Русский', // 俄语
        'italiano', // 意大利语
        'แบบไทย', // 泰语
        'Nederlands', // 荷兰语
    ],
    'footer': {
        home: "Home",
    },
    'setDrawer': {
        label1: 'การตั้งค่าหลายภาษา',
        label2: 'การลงทะเบียนร้านค้า',
        label3: 'เข้าสู่ระบบร้านค้า',
        label4: 'ฝ่ายบริการลูกค้า',
        label5: 'ดาวน์โหลดแอปพลิเคชัน Android',
        label6: 'ดาวน์โหลดแอปพลิเคชัน iOS',
        optionsBtn: 'ยกเลิก'
    },
    'appHome': {
        searchPlace: 'ค้นหา...',
        recommendTitle: 'แนะนำ',
        viewAll: 'ดูทั้งหมด',
        usedTitle: 'ใช้แล้ว',
        shopTitle: 'ร้านค้า',
        shopOption: 'ดูเลย',
        hotTitle: 'รายการยอดนิยม',
        classTag1: 'บ้าน',
        classTag2: 'ผู้หญิง',
        classTag3: 'ผู้ชาย',
        classTag4: 'เด็กๆ',
        classTag5: 'อิเล็กทรอนิกส์',
        classTag6: 'สัตว์เลี้ยง',
        buyNow: 'ซื้อเลย',

        // ranking: 'Sales Ranking',
        // preSaleTitle: 'Presale',
        // preSaleTabs1: 'Started',
        // preSaleTabs2: 'Not started',
        // preSaleTabs3: 'Ended',
        // groupTitle: 'Group Buying',
        // peopleGroupBuying: '人参与团购',
        // flashSaleTitle: 'Flash Sale',
    },
    'appSetLanguage': {
        navTitle: 'การตั้งค่าหลายภาษา',
        affirm: 'ยืนยัน'
    },
    "appService": {
        navTitle: 'ฝ่ายบริการลูกค้า',
        customerService: 'บริการลูกค้าพิเศษ',
        helloTitle: 'สวัสดี',
        welcomeTitle: 'ฉันเป็นของคุณ< สามารถติดต่อฉันได้ตามช่องทางต่อไปนี้',
        iconInformation: 'โปรดเลือกซอฟต์แวร์การสื่อสารเพื่อรับคำปรึกษา',
        whatsApp: 'whatsApp ',
        telegram: 'telegram ',
        line: 'line',
    },
    // 移动分类页
    'appClassification': {
        navTitle: 'การจำแนกประเภทสินค้าโภคภัณฑ์',
        viewAll: 'ดูทั้งหมด',
    },
    'appHotList': {
        navTitle: 'รายการยอดนิยม',
        ranking: 'อันดับยอดขาย',
    },
    'appHotRanking': {
        navTitle: 'อันดับยอดขายตามสำเนียง',
        searchPlace: 'ค้นหา...',
        payment: 'การชำระเงิน',
        optionBtn1: 'รวบรวม',
        optionBtn2: 'ซื้อเลย',
        errorMessage: 'กรุณาลงชื่อเข้าใช้',
    },
    'appUsedList': {
        navTitle: 'ใช้แล้ว',
        recommendNavTitle: 'แนะนำ',
        classNavTitle: 'การจำแนกประเภท',
        searchNavTitle: 'ค้นหา',
        searchPlace: 'ค้นหา...',
        select1: 'ครอบคลุม',
        select2: 'ปริมาณการขาย',
        select3: 'ผลิตภัณฑ์ใหม่',
        select4: 'ราคาสูงไปต่ำ',
        select5: 'ราคาต่ำไปสูง',
        select6: 'คะแนน',
        classAll: 'ทั้งหมด',
        listTag1: 'จัดส่งฟรี',
        paymentTitle: 'การชำระเงิน'
    },
    'appRecommendList': {
        navTitle: 'แนะนำ',
        searchPlace: 'ค้นหา...',
        select1: 'ครอบคลุม',
        select2: 'ปริมาณการขาย',
        select3: 'ผลิตภัณฑ์ใหม่',
        select4: 'ราคาสูงไปต่ำ',
        select5: 'ราคาต่ำไปสูง',
        select6: 'คะแนน',
        classAll: 'ทั้งหมด',
        listTag1: 'จัดส่งฟรี',
        paymentTitle: 'การชำระเงิน'
    },
    'appLogin': {
        navTitle: 'เข้าสู่ระบบ',
        navTitleHint: 'เข้าสู่ระบบตอนนี้และเริ่มซื้อขายกับเพื่อน ๆ จากทั่วทุกมุมโลก!',
        singUp: 'สมัคร',
        emailLabel: 'หมายเลขโทรศัพท์',
        emailPlaceholder: 'กรุณากรอกหมายเลขโทรศัพท์',
        emailErrorText: 'แจ้งข้อผิดพลาด',
        emailNullVerify: 'กรุณากรอกหมายเลขโทรศัพท์',//请输入邮箱
        passwordLabel: 'รหัสผ่านของคุณ',
        passPlaceholder: 'กรุณาใส่รหัสผ่าน',
        passNullVerify: 'กรุณากรอกรหัสผ่าน',//请输入密码
        passErrorVerify: 'รหัสผ่านน้อยกว่า 6 ตัวอักษร',//密码小于6位
        placeholder: 'กรุณาป้อนข้อมูล',
        emailRegistration: 'การลงทะเบียนหมายเลขโทรศัพท์',
        forgetPassTitle: 'ลืมรหัสผ่าน?',
        submitBtnTitle: 'เข้าสู่ระบบ',
        errorMessage: 'ชื่อผู้ใช้หรือรหัสผ่านไม่ถูกต้อง',
        // emailErrorVerify: '邮箱格式错误！',//邮箱格式错误
    },
    'appForgotPass': {
        navTitle: 'ลืมรหัสผ่าน',
        navTitleHint: 'โปรดจดหมายเลขโทรศัพท์มือถือของคุณเพื่อรับรหัสยืนยันในการตั้งรหัสผ่านใหม่',
        emailLabel: 'หมายเลขโทรศัพท์',
        emailPlaceholder: 'กรุณากรอกหมายเลขโทรศัพท์',
        emailErrorText: 'กรุณากรอกหมายเลขโทรศัพท์',//请输入邮箱
        emailNullVerify: 'กรุณากรอกหมายเลขโทรศัพท์',//请输入邮箱
        emailErrorVerify: 'หมายเลขโทรศัพท์มือถือผิด',//邮箱格式错误
        verificationCode: 'รหัสยืนยัน',
        verificationPlace: 'รหัสยืนยัน',
        verificationCodeError: 'กรุณากรอกรหัสยืนยัน',
        sendTitle: 'ยืนยันหมายเลขโทรศัพท์มือถือ',
        sendText: 'การตรวจสอบซ้ำ',
        passwordLabel: 'รหัสผ่าน',
        passPlaceholder: 'กรุณาใส่รหัสผ่าน',
        confirmPassLabel: 'ยืนยันรหัสผ่าน',
        confirmPlace: 'ตัวยึดตำแหน่งยืนยันรหัสผ่าน',
        passNullVerify: 'ป้อนรหัสผ่านของคุณ',//请输入密码
        passErrorVerify: 'รหัสผ่านน้อยกว่า 6 ตัวอักษร',//密码小于6位
        passCompareErrorVerify: 'รหัสผ่านไม่สอดคล้องกัน',
        passCompareErrorNull: 'กรุณาใส่รหัสผ่านอีกครั้ง',
        emailRegistration: 'การลงทะเบียนหมายเลขโทรศัพท์',
        forgetPassTitle: 'ลืมรหัสผ่าน?',
        submitBtnTitle: 'รีเซ็ตรหัสผ่าน',
        submitErrorMessage: 'รหัสยืนยันไม่ถูกต้อง'
    },
    'appRegister': {
        navTitle: 'ลงทะเบียน',
        navTitleHint: 'สร้างบัญชีเพื่อเข้าถึงคุณสมบัติทั้งหมด!',
        firstNameLabel: 'ชื่อ',
        firstNamePlace: 'กรุณาระบุ',
        firstNameErrorVerify: 'กรุณากรอกชื่อของคุณ',
        lastNameLabel: 'นามสกุล',
        lastNameErrorVerify: 'กรุณากรอกนามสกุลของคุณ',
        nickNameLabel: 'ชื่อเล่น',
        nickNameErrorVerify: 'กรุณากรอกชื่อเล่นของคุณ',
        namePlace: 'กรุณาระบุ',
        gender: 'เพศ',
        gender1: 'เด็กผู้ชาย',
        gender2: 'เด็กผู้หญิง',
        gender3: 'ความลับ',
        genderError: 'กรุณาระบุเพศของคุณ',
        emailLabel: 'หมายเลขโทรศัพท์',
        emailPlaceholder: 'กรุณากรอกหมายเลขโทรศัพท์!',
        emailErrorText: 'ข้อความแสดงข้อผิดพลาด!',//请输入邮箱
        emailNullVerify: 'กรุณากรอกหมายเลขโทรศัพท์!',//请输入邮箱
        verificationCode: 'รหัสยืนยัน',
        verificationPlace: 'รหัสยืนยัน',
        sendTitle: 'ยืนยันหมายเลขโทรศัพท์มือถือ',
        sendText: 'การตรวจสอบซ้ำ',
        verificationCodeError: 'รหัสยืนยันไม่สามารถเว้นว่างได้',
        confirmPassLabel: 'รหัสผ่าน',
        passPlaceholder: 'กรุณาใส่รหัสผ่าน',
        passwordLabel: 'ยืนยันรหัสผ่าน',
        confirmPlace: 'ตัวยึดตำแหน่งยืนยันรหัสผ่าน',
        passNullVerify: 'ป้อนรหัสผ่านของคุณ',//请输入密码
        passErrorVerify: 'รหัสผ่านมีความยาวน้อยกว่า 6 ตัวอักษร！',//密码小于6位
        passCompareErrorVerify: 'รหัสผ่านไม่สอดคล้องกัน!',
        passCompareErrorNull: 'กรุณาใส่รหัสผ่านอีกครั้ง',
        registerHint: 'มีบัญชีอยู่แล้ว?',
        registerHintLogin: 'เข้าสู่ระบบ',
        submitBtnTitle: 'ลงทะเบียน',
        submitErrorMessage: 'รหัสยืนยันไม่ถูกต้อง'
        // emailErrorVerify: '邮箱格式错误!',//邮箱格式错误
    },
    "appShopList": {
        navTitle: 'รายการร้านค้า',
        searchPlace: 'ค้นหา...'
    },
    'appGoodsDetail': {
        salesTitle: 'การขาย',
        conditionLabel: 'เงื่อนไข:',
        conditionContent: 'การชำระเงินที่รองรับหมวดหมู่',
        agreement: 'ข้อตกลงคุ้มครองผู้ซื้อ',
        specSelect: 'เลือกข้อมูลจำเพาะ',
        payLabel1: 'ยอดคงเหลือ',
        payLabel2: 'เพย์พาล',
        payLabel3: 'วีซ่า',
        commentTitle: 'ความคิดเห็น',
        frightLabel: 'ค่าขนส่ง',
        frightContent: 'จัดส่งภายใน 8 วันโดยเฉลี่ย',
        deliverTime: 'ประเมินเวลาและวันจัดส่ง',
        productQuantity: 'ปริมาณ',
        soldQuantity: "ปริมาณการขาย",
        collections: 'ปริมาณการสั่งซื้อ',
        since: 'สมาชิกตั้งแต่',
        shipper: 'ผู้จัดส่งที่รวดเร็ว',
        responder: 'ตอบกลับอย่างรวดเร็ว',
        reliable: 'เชื่อถือได้',
        btnContact: 'ติดต่อ',
        enter: 'ป้อน',
        followBtn: 'ติดตาม',
        guessLike: 'เดาว่าคุณชอบมัน',
        buyNow: 'ซื้อเลย',
        dialogOverviewTitle: 'ภาพรวม',
        overConditionLabel: 'เงื่อนไข',
        overConditionContent: 'ใหม่',
        overBrandLabel: 'แบรนด์',
        overCategoryLabel: 'หมวดหมู่',
        overPayMethodsLabel: 'การชำระเงินที่รองรับ',
        payTitle: 'การชำระเงินที่รองรับ',
        payHint: 'สีสวยมาก ดูดีมีสไตล์ ใส่ได้กับเสื้อผ้าหลายแบบ สวยงามมาก เหมาะกับใส่คู่กับเสื้อผ้าลำลองและแฟชั่น',
        overCategoryEnter: 'ป้อน',
        dialogFreightTitle: 'ค่าขนส่ง',
        dialogAgreementTitle: 'ข้อตกลงคุ้มครองผู้ซื้อ',
        commitContent: 'การสรรเสริญเริ่มต้นของระบบ',
        footerLeft: 'หยิบลงตะกร้า',
        footerRight: 'ซื้อเลย'
    },
    'specificationDialog': {
        inventory: 'สินค้าคงคลัง',
        countLabel: 'หมายเลข',
        specLabel: 'ค่าเริ่มต้น',
        options1: 'หยิบลงตะกร้า',
        options2: 'ซื้อเลย'

    },
    "appShopsDetail": {
        select1: 'ครอบคลุม',
        select2: 'ปริมาณการขาย',
        select4: 'ราคาสูงไปต่ำ',
        select5: 'ราคาต่ำไปสูง',
        select6: 'คะแนน',
        follow: 'ติดตาม',
        timeDay: 'ประเมินเวลาและวันที่จัดส่ง',
        productQuantity: 'ปริมาณ',
        shippedQuantity: "ปริมาณการขาย",
        collections: 'ปริมาณการสั่งซื้อ',
        commodity: 'สินค้าโภคภัณฑ์',
        classAll: 'ทั้งหมด'
    },
    // 产品评论页
    'appProductReviews': {
        navTitle: 'ความคิดเห็น',
        tags1: 'ทั้งหมด',
        tags2: '5 ดาว',
        tags3: '4 ดาว',
        tags4: '3 ดาว',
        tags5: '2 ดาว',
        tags6: '1 ดาว',
        tags7: 'มีสื่อ',
        commitContent: 'การสรรเสริญเริ่มต้นของระบบ'
    },
    // app升级配置文件语言
    configLanguage: {
        upgradePrompt: 'อัปเกรดเวอร์ชัน โปรดยืนยันหรือไม่?',
        loadingTitle: 'กำลังดาวน์โหลด...',
        systemUpgrade: 'อัปเกรดระบบ',
        upgradeImmediately: 'ยืนยัน'
    },
    // 公共提示
    messageLanguage: {
        loadingTitle: "กำลังโหลด", // กำลังโหลด
        finishedText: "ไม่มี", //ไม่มี
        loadingText: "กำลังโหลด", // กำลังโหลด
        nullText: 'ยังไม่มีข้อมูล',
        loadingFinished: "กำลังโหลดเสร็จสมบูรณ์",
        listNullText: 'ไม่มีอีกแล้ว',
        pullIngText: 'ดึงลงเพื่อรีเฟรช',
        loosingText: 'ปล่อยเพื่อรีเฟรช',
        pullingSuccess: 'การโหลดแบบดึงลงสำเร็จ'
    },
    // 'appSearchHistory': {
    //   navTitle: 'Search',
    //   searchPlace: 'Search...',
    //   recommendTitle: 'Recommend'
    // },
    // 'appClassList': {
    //   searchPlace: 'Search...',
    //   select1: 'Comprehensive',
    //   select2: 'Sales Volume',
    //   select3: 'New product',
    //   select4: 'Price High to Low',
    //   select5: 'Price Low to High',
    //   select6: 'Score',
    //   paymentTitle: 'payment',
    //   listTag1: 'Free Shipping',
    //   favoriteTitle: 'Favorite Quantity &',
    //   shopBtn: 'View Closets',
    //   recommendNavTitle: 'Recommend',
    //   usedNavTitle: 'Used',
    //   shopNavTitle: 'Shop'
    // },

    // 'appPreSale': {
    //   navTitle: 'Presale',
    //   tabs1: 'Started',
    //   tabs2: 'Not Started',
    //   tabs3: 'Ended',
    //   searchPlace: 'Search...',
    //   select1: 'Comprehensive',
    //   select2: 'Sales Volume',
    //   select3: 'New product',
    //   select4: 'Price High to Low',
    //   select5: 'Price Low to High',
    //   select6: 'Score',

    //   preSale: 'Presale',
    //   freeShipping: 'Free Shipping',
    //   payment: 'payment',
    //   preSale: 'presale',
    //   freeShipping: "Free Shipping"
    // },
    // "appGroupBuying": {
    //   navTitle: 'Group Buying',
    //   searchPlace: 'Search...',
    //   select1: 'Comprehensive',
    //   select2: 'Sales Volume',
    //   select3: 'New product',
    //   select4: 'Price High to Low',
    //   select5: 'Price Low to High',
    //   select6: 'Score',

    //   preSale: 'Presale',
    //   freeShipping: 'Free Shipping',
    //   payment: 'payment',
    //   btn1: 'Group Buying',
    //   btn2: "Free Shipping"
    // },
    // "appFlashSale": {
    //   navTitle: 'Flash Sale',
    //   searchPlace: 'Search...',
    //   select1: 'Comprehensive',
    //   select2: 'Sales Volume',
    //   select3: 'New product',
    //   select4: 'Price High to Low',
    //   select5: 'Price Low to High',
    //   select6: 'Score',

    //   preSale: 'Presale',
    //   freeShipping: 'Free Shipping',
    //   payment: 'payment',
    //   preSale: 'presale',
    //   freeShipping: "Free Shipping"
    // },
};
export default obj;
