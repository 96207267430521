// 法语

let obj = {};
obj = {
  // 语言
  languageVersion: [
    'English',  // 英语
    '日本語', // 日语
    'Português', // 葡萄牙语
    '繁體中文', // 繁体中文
    'Français', // 法语
    'Deutsch', // 德语
    'Türk', // 土耳其语
    'Español', // 西班牙语
    'عربي', // 阿拉伯语
    '한국인', // 韩语
    'Suomi', // 芬兰语
    'Русский', // 俄语
    'italiano', // 意大利语
    'แบบไทย', // 泰语
    'Nederlands', // 荷兰语
  ],
  'footer': {
    home: "Maison",
  },
  'setDrawer': {
    label1: 'Paramètres multilingues',
    label2: 'Enregistrement du magasin',
    label3: 'Connexion au magasin',
    label4: 'Service Client',
    label5: "Télécharger l'application Android",
    label6: "Télécharger l'application iOS",
    optionsBtn: 'Annuler'
  },
  'appHome': {
    searchPlace: 'Rechercher...',
    recommendTitle: 'Recommander',
    viewAll: 'afficher tout',
    usedTitle: 'Utilisé',
    shopTitle: 'Boutique',
    shopOption: 'Voir maintenant',
    hotTitle: 'Liste chaude',
    classTag1: 'Accueil',
    classTag2: 'Femmes',
    classTag3: 'Hommes',
    classTag4: 'Enfants',
    classTag5: 'Électronique',
    classTag6: 'Animaux de compagnie',
    buyNow: 'Acheter maintenant',
    // buyNow: 'Buy Now',
    // ranking: 'Sales Ranking',
    // preSaleTitle: 'Presale',
    // preSaleTabs1: 'Started',
    // preSaleTabs2: 'Not started',
    // preSaleTabs3: 'Ended',
    // groupTitle: 'Group Buying',
    // peopleGroupBuying: '人参与团购',
    // flashSaleTitle: 'Flash Sale',
  },
  'appSetLanguage': {
    navTitle: 'Paramètres multilingues',
    affirmer: 'confirmer',
  },
  "appService": {
    navTitle: 'Service client',
    customerService: 'Service client exclusif',
    helloTitle: 'Bonjour',
    welcomeTitle: 'Je suis à toi<, vous pouvez me contacter via les canaux suivants',
    iconInformation: 'Veuillez sélectionner le logiciel de communication à consulter',
    whatsApp: 'whatsApp ',
    telegram: 'telegram ',
    line: 'line',
  },
  // 移动分类页
  'appClassification': {
    navTitle: 'Classification des produits',
    viewAll: 'Voir tout',
  },
  'appHotList': {
    navTitle: 'Liste chaude',
    ranking: 'Classement des ventes',
  },
  'appHotRanking': {
    navTitle: 'Classement des ventes Accents',
    searchPlace: 'Rechercher...',
    payment: 'paiement',
    optionBtn1: 'Collecter',
    optionBtn2: 'Acheter maintenant',
    errorMessage: 'Veuillez vous connecter',
  },
  'appUsedList': {
    navTitle: 'Utilisé',
    recommendNavTitle: 'Recommander',
    classNavTitle: 'Classification',
    searchNavTitle: 'Rechercher',
    searchPlace: 'Rechercher...',
    select1: 'Complet',
    select2: 'Volume des ventes',
    select3: 'Nouveau produit',
    select4: 'Prix élevé à faible',
    select5: 'Prix bas à haut',
    select6: 'Partition',
    classAll: 'Tous',
    listTag1: 'Livraison gratuite',
    paymentTitle: 'Paiement'
  },
  'appRecommendList': {
    navTitle: 'Recommander',
    searchPlace: 'Rechercher...',
    select1: 'Complet',
    select2: 'Volume des ventes',
    select3: 'Nouveau produit',
    select4: 'Prix élevé à faible',
    select5: 'Prix bas à haut',
    select6: 'Partition',
    classAll: 'Tous',
    listTag1: 'Livraison gratuite',
    paymentTitle: 'Paiement'
  },
  'appLogin': {
    navTitle: 'Connexion',
    navTitleHint: 'Connectez-vous maintenant et commencez à trader avec des amis du monde entier !',
    singUp: 'Inscrivez-vous',
    emailLabel: 'Numéro de téléphone',
    emailPlaceholder: 'Veuillez entrer un numéro de téléphone',
    emailErrorText: "Invite d'erreur",
    emailNullVerify: 'Veuillez entrer un numéro de téléphone',//请输入邮箱
    passwordLabel: 'Votre mot de passe',
    passPlaceholder: 'Veuillez saisir le mot de passe',
    passNullVerify: 'Veuillez entrer votre mot de passe',//请输入密码
    passErrorVerify: 'Le mot de passe contient moins de 6 caractères',//密码小于6位
    placeholder: "Veuillez saisir",
    emailRegistration: 'Enregistrement du numéro de téléphone',
    forgetPassTitle: 'Mot de passe oublié ?',
    submitBtnTitle: 'Connexion',
    errorMessage: "Nom d'utilisateur ou mot de passe incorrect",
    // emailErrorVerify: '邮箱格式错误！',//邮箱格式错误
  },
  'appForgotPass': {
    navTitle: 'Mot de passe oublié',
    navTitleHint: 'Veuillez noter votre numéro de téléphone portable pour recevoir un code de confirmation permettant de définir un nouveau mot de passe.',
    emailLabel: 'Numéro de téléphone',
    emailPlaceholder: 'Veuillez entrer un numéro de téléphone',
    emailErrorText: 'Veuillez entrer un numéro de téléphone',//请输入邮箱
    emailNullVerify: 'Veuillez entrer un numéro de téléphone',//请输入邮箱
    emailErrorVerify: "Mauvais numéro de mobile", //
    verificationCode: 'Code de vérification',
    verificationPlace: 'Code de vérification',
    verificationCodeError: 'Veuillez entrer le code de vérification',
    sendTitle: 'Confirmer le numéro de mobile',
    sendText: 'Revalidation',
    passwordLabel: 'Mot de passe',
    passPlaceholder: 'Veuillez saisir le mot de passe',
    confirmPassLabel: 'Confirmer le mot de passe',
    confirmPlace: 'espace réservé pour confirmer le mot de passe',
    passNullVerify: 'Entrez votre mot de passe', //请输入密码
    passErrorVerify: 'Le mot de passe contient moins de 6 caractères',//密码小于6位
    passCompareErrorVerify: 'Les mots de passe sont incohérents',
    passCompareErrorNull: 'Veuillez saisir à nouveau le mot de passe',
    emailRegistration: 'Enregistrement du numéro de téléphone',
    forgetPassTitle: 'Mot de passe oublié ?',
    submitBtnTitle: 'Réinitialiser le mot de passe',
    submitErrorMessage: 'Code de vérification incorrect'
  },
  'appRegister': {
    navTitle: "S'inscrire",
    navTitleHint: 'Créez un compte pour accéder à toutes les fonctionnalités !',
    firstNameLabel: 'Prénom',
    firstNamePlace: 'Veuillez entrer',
    firstNameErrorVerify: 'Veuillez entrer votre prénom',
    lastNameLabel: 'Nom de famille',
    lastNameErrorVerify: 'Veuillez entrer votre nom de famille',
    nickNameLabel: 'Surnom',
    nickNameErrorVerify: 'Veuillez entrer votre pseudo',
    namePlace: 'Veuillez saisir',
    gender: 'Genre',
    gender1: 'Garçon',
    gender2: 'Fille',
    gender3: "Secret",
    genderError: 'Veuillez entrer votre sexe',
    emailLabel: 'Numéro de téléphone',
    emailPlaceholder: 'Veuillez entrer un numéro de téléphone !',
    emailErrorText: "Message d'erreur !",//请输入邮箱
    emailNullVerify: 'Veuillez entrer un numéro de téléphone !',//请输入邮箱
    verificationCode: 'Code de vérification',
    verificationPlace: 'Code de vérification',
    sendTitle: 'Confirmer le numéro de mobile',
    sendText: 'Revalidation',
    verificationCodeError: 'Le code de vérification ne peut pas être vide',
    passwordLabel: 'Mot de passe',
    passPlaceholder: 'Veuillez saisir le mot de passe',
    confirmPassLabel: 'Confirmer le mot de passe',
    confirmPlace: 'espace réservé pour confirmer le mot de passe',
    passNullVerify: 'entrez votre mot de passe', //请输入密码
    passErrorVerify: 'Le mot de passe contient moins de 6 caractères！',//密码小于6位
    passCompareErrorVerify: 'Les mots de passe sont incohérents！',
    passCompareErrorNull: 'Veuillez saisir à nouveau le mot de passe',
    registerHint: 'Vous avez déjà un compte ?',
    registerHintLogin: 'Connexion',
    submitBtnTitle: "S'inscrire",
    submitErrorMessage: 'Code de vérification incorrect'
    // emailErrorVerify: '邮箱格式错误!',//邮箱格式错误
  },
  "appShopList": {
    navTitle: 'Liste des boutiques',
    searchPlace: 'Rechercher...'
  },
  'appGoodsDetail': {
    salesTitle: 'Ventes',
    conditionLabel: 'Condition :',
    conditionContent: 'Paiements pris en charge par catégorie',
    agreement: "Accord de protection de l'acheteur",
    specSelect: 'Sélectionner les spécifications',
    payLabel1: 'Solde',
    payLabel2: 'PayPal',
    payLabel3: 'Visa',
    commentTitle: 'Commentaire',
    frightLabel: 'Fret',
    frightContent: 'Expédié sous 8 jours en moyenne',
    deliverTime: 'Évaluer le délai et les jours de livraison',
    productQuantity: 'Quantité',
    soldQuantity: "Volume des ventes",
    collections: 'Quantité de la commande',
    since: 'Membre depuis',
    shipper: 'Expéditeur rapide',
    responder: 'Répondeur rapide',
    reliable: 'Fiable',
    btnContact: 'Contact',
    enter: 'Entrer',
    followBtn: 'Suivre',
    guessLike: "Je suppose que vous l'aimez",
    buyNow: 'Acheter maintenant',
    dialogOverviewTitle: "Vue d'ensemble",
    overConditionLabel: 'Condition',
    overConditionContent: 'Nouveau',
    overBrandLabel: 'Marque',
    overCategoryLabel: 'Catégorie',
    overPayMethodsLabel: 'Paiements pris en charge',
    payTitle: 'Paiements pris en charge',
    payHint: "La couleur est belle, elle a l'air élégante et élégante. Elle peut être portée avec une large gamme de vêtements. Elle est donc très belle. Elle est parfaite pour être associée à des vêtements décontractés et à la mode",
    overCategoryEnter: 'Entrer',
    dialogFreightTitle: 'Fret',
    dialogAgreementTitle: "Accord de protection de l'acheteur",
    commitContent: 'Éloge par défaut du système',
    footerLeft: 'Ajouter au panier',
    footerRight: "Acheter maintenant"
  },
  'specificationDialog': {
    inventory: 'Inventaire',
    countLabel: 'Nombre',
    specLabel: 'Par défaut',
    options1: 'Ajouter au panier',
    options2: "Acheter maintenant",
  },
  "appShopsDetail": {
    select1: 'Complet',
    select2: 'Volume des ventes',
    select4: 'Prix élevé à faible',
    select5: 'Prix bas à haut',
    select6: 'Partition',
    follow: 'Suivre',
    timeDay: 'Évaluer le délai et les jours de livraison',
    productQuantity: 'quantité de produit',
    shippedQuantity: 'Ventes',
    collections: "Nombre total de commandes",
    commodity: "Marchandise",
    classAll: 'Tout'
  },
  // 产品评论页
  'appProductReviews': {
    navTitle: 'Commentaire',
    tags1: 'tous',
    tags2: '5 étoiles',
    tags3: '4 étoiles',
    tags4: '3 étoiles',
    tags5: '2 étoiles',
    tags6: '1 étoile',
    tags7: 'Avec les médias',
    commitContent: 'Éloge par défaut du système'
  },
  // app升级配置文件语言
  configLanguage: {
    upgradePrompt: "Mise à niveau de la version, veuillez confirmer ? ",
    loadingTitle: 'Téléchargement...',
    systemUpgrade: 'Mise à niveau du système',
    upgradeImmediately: 'Confirmer',
  },
  // 公共提示
  messageLanguage: {
    loadingTitle: "Chargement", // Chargement
    finishedText: "Aucun", //Aucun
    loadingText: "Chargement", // Chargement
    nullText: "Aucune donnée pour l'instant",
    loadingFinished: "Chargement terminé",
    listNullText: 'Plus rien',
    pullIngText: 'Déroulez pour actualiser',
    loosingText: 'Relâcher pour actualiser',
    pullingSuccess: 'Chargement déroulant réussi'
  },
  // 'appSearchHistory': {
  //   navTitle: 'Search',
  //   searchPlace: 'Search...',
  //   recommendTitle: 'Recommend'
  // },
  // 'appClassList': {
  //   searchPlace: 'Search...',
  //   select1: 'Comprehensive',
  //   select2: 'Sales Volume',
  //   select3: 'New product',
  //   select4: 'Price High to Low',
  //   select5: 'Price Low to High',
  //   select6: 'Score',
  //   paymentTitle: 'payment',
  //   listTag1: 'Free Shipping',
  //   favoriteTitle: 'Favorite Quantity &',
  //   shopBtn: 'View Closets',
  //   recommendNavTitle: 'Recommend',
  //   usedNavTitle: 'Used',
  //   shopNavTitle: 'Shop'
  // },

  // 'appPreSale': {
  //   navTitle: 'Presale',
  //   tabs1: 'Started',
  //   tabs2: 'Not Started',
  //   tabs3: 'Ended',
  //   searchPlace: 'Search...',
  //   select1: 'Comprehensive',
  //   select2: 'Sales Volume',
  //   select3: 'New product',
  //   select4: 'Price High to Low',
  //   select5: 'Price Low to High',
  //   select6: 'Score',

  //   preSale: 'Presale',
  //   freeShipping: 'Free Shipping',
  //   payment: 'payment',
  //   preSale: 'presale',
  //   freeShipping: "Free Shipping"
  // },
  // "appGroupBuying": {
  //   navTitle: 'Group Buying',
  //   searchPlace: 'Search...',
  //   select1: 'Comprehensive',
  //   select2: 'Sales Volume',
  //   select3: 'New product',
  //   select4: 'Price High to Low',
  //   select5: 'Price Low to High',
  //   select6: 'Score',

  //   preSale: 'Presale',
  //   freeShipping: 'Free Shipping',
  //   payment: 'payment',
  //   btn1: 'Group Buying',
  //   btn2: "Free Shipping"
  // },
  // "appFlashSale": {
  //   navTitle: 'Flash Sale',
  //   searchPlace: 'Search...',
  //   select1: 'Comprehensive',
  //   select2: 'Sales Volume',
  //   select3: 'New product',
  //   select4: 'Price High to Low',
  //   select5: 'Price Low to High',
  //   select6: 'Score',

  //   preSale: 'Presale',
  //   freeShipping: 'Free Shipping',
  //   payment: 'payment',
  //   preSale: 'presale',
  //   freeShipping: "Free Shipping"
  // },
};
export default obj;