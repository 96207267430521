import Vue from 'vue'
import Vuex from 'vuex'
//需要使用插件一次
Vue.use(Vuex)
import createPersistedState from 'vuex-persistedstate';

const state = {
  app_ClassData: [],//分类选择数据
  app_ClassActive: null,//分类数据选择项
}
const mutations = {
  // 分类数据选择
  app_ClassSelectFn (state, data) {
    state.app_ClassData = data
  },
  // 分类数据选择项
  app_ClassActiveFn (state, data) {
    state.app_ClassActive = data
  }

}
const actions = {
  async web_getClass_list ({ commit }) {
    const res = await classIfyApi.getClassIfy_List()
    commit('webGetClassTree', res);
  },
}

const getters = {}


//对外暴露Store类的一个实例
export default new Vuex.Store({
  plugins: [createPersistedState({ storage: window.sessionStorage })],
  state,
  mutations,
  actions,
  getters
})