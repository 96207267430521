// 意大利语

let obj = {};
obj = {
  // 语言
  languageVersion: [
    'English',  // 英语
    '日本語', // 日语
    'Português', // 葡萄牙语
    '繁體中文', // 繁体中文
    'Français', // 法语
    'Deutsch', // 德语
    'Türk', // 土耳其语
    'Español', // 西班牙语
    'عربي', // 阿拉伯语
    '한국인', // 韩语
    'Suomi', // 芬兰语
    'Русский', // 俄语
    'italiano', // 意大利语
    'แบบไทย', // 泰语
    'Nederlands', // 荷兰语
  ],
  'footer': {
    home: "Casa",
  },
  'setDrawer': {
    label1: 'Impostazioni multilingue',
    label2: 'Registrazione del negozio',
    label3: 'Accesso al negozio',
    label4: "Servizio clienti",
    label5: "Scarica l'applicazione Android",
    label6: "Scarica l'applicazione ios",
    optionsBtn: 'Annulla'
  },
  'appHome': {
    searchPlace: 'Cerca...',
    recommendTitle: 'Consiglia',
    viewAll: 'visualizza tutto',
    usedTitle: 'Usato',
    shopTitle: 'Negozio',
    shopOption: 'Visualizza ora',
    hotTitle: 'Hot List',
    classTag1: 'Casa',
    classTag2: 'Donne',
    classTag3: 'Uomini',
    classTag4: 'Bambini',
    classTag5: 'Elettronica',
    classTag6: 'Animali domestici',
    buyNow: 'Acquista ora',

    // buyNow: 'Buy Now',
    // ranking: 'Sales Ranking',
    // preSaleTitle: 'Presale',
    // preSaleTabs1: 'Started',
    // preSaleTabs2: 'Not started',
    // preSaleTabs3: 'Ended',
    // groupTitle: 'Group Buying',
    // peopleGroupBuying: '人参与团购',
    // flashSaleTitle: 'Flash Sale',
  },
  'appSetLanguage': {
    navTitle: 'Impostazioni multilingue',
    affirm: 'confermare',
  },
  "appService": {
    navTitle: "Servizio clienti",
    customerService: 'Servizio clienti esclusivo',
    helloTitle: 'Ciao',
    welcomeTitle: 'Sono tuo<, puoi contattarmi attraverso i seguenti canali',
    iconInformation: 'Seleziona il software di comunicazione per la consultazione',
    whatsApp: 'whatsApp',
    telegram: 'telegramma',
    line: 'linea',
  },
  // 移动分类页
  'appClassification': {
    navTitle: "Classificazione delle merci",
    viewAll: 'Visualizza tutto',
  },
  'appHotList': {
    navTitle: 'Hot List',
    ranking: 'Classifica delle vendite',
  },
  'appHotRanking': {
    navTitle: 'Classifica delle vendite degli accenti',
    searchPlace: 'Cerca...',
    payment: 'pagamento',
    optionBtn1: 'Raccogli',
    optionBtn2: 'Acquista ora',
    errorMessage: 'Accedi',
  },
  'appUsedList': {
    navTitle: 'Usato',
    recommendNavTitle: 'Consiglia',
    classNavTitle: 'Classificazione',
    searchNavTitle: 'Cerca',
    searchPlace: 'Cerca...',
    select1: 'Completo',
    select2: 'Volume delle vendite',
    select3: 'Nuovo prodotto',
    select4: 'Prezzo dal più alto al più basso',
    select5: 'Prezzo dal più basso al più alto',
    select6: 'Punteggio',
    classAll: 'Tutti',
    listTag1: 'Spedizione gratuita',
    paymentTitle: 'Pagamento'
  },
  'appRecommendList': {
    navTitle: 'Consiglia',
    searchPlace: 'Cerca...',
    select1: 'Completo',
    select2: 'Volume delle vendite',
    select3: 'Nuovo prodotto',
    select4: 'Prezzo dal più alto al più basso',
    select5: 'Prezzo dal più basso al più alto',
    select6: 'Punteggio',
    classAll: 'Tutti',
    listTag1: 'Spedizione gratuita',
    paymentTitle: 'Pagamento'
  },
  'appLogin': {
    navTitle: "Accedi",
    navTitleHint: 'Accedi ora e inizia a fare trading con amici da tutto il mondo!',
    singUp: 'Iscriviti',
    emailLabel: 'Numero di telefono',
    emailPlaceholder: 'Inserisci il numero di telefono',
    emailErrorText: 'Richiesta di errore',
    emailNullVerify: 'Inserisci il numero di telefono',//请输入邮箱
    passwordLabel: "La tua password",
    passPlaceholder: 'Inserisci la password',
    passNullVerify: 'Inserisci la tua password',//请输入密码
    passErrorVerify: 'La password contiene meno di 6 caratteri',//密码小于6位
    placeholder: 'Inserisci',
    emailRegistration: 'Registrazione del numero di telefono',
    forgetPassTitle: 'Password dimenticata?',
    submitBtnTitle: 'Accedi',
    errorMessage: 'Nome utente o password errati',
    // emailErrorVerify: '邮箱格式错误！',//邮箱格式错误
  },
  'appForgotPass': {
    navTitle: 'Password dimenticata',
    navTitleHint: 'Scrivi il tuo numero di cellulare per ricevere un codice di conferma per impostare una nuova password.',
    emailLabel: 'Numero di telefono',
    emailPlaceholder: 'Inserisci il numero di telefono',
    emailErrorText: 'Inserisci il numero di telefono',//请输入邮箱
    emailNullVerify: 'Inserisci il numero di telefono',//请输入邮箱
    emailErrorVerify: 'Numero di cellulare errato',//邮箱格式错误
    verificationCode: 'Codice di verifica',
    verificationPlace: 'Codice di verifica',
    verificationCodeError: 'Inserisci il codice di verifica',
    sendTitle: 'Conferma numero di cellulare',
    sendText: 'Riconvalida',
    passwordLabel: 'Password',
    passPlaceholder: 'Inserisci la password',
    confirmPassLabel: 'Conferma password',
    confirmPlace: 'segnaposto conferma password',
    passNullVerify: 'Inserisci la tua password',//请输入密码
    passErrorVerify: 'La password contiene meno di 6 caratteri',//密码小于6位
    passCompareErrorVerify: 'Le password non sono coerenti',
    passCompareErrorNull: 'Inserisci nuovamente la password',
    emailRegistration: 'Registrazione del numero di telefono',
    forgetPassTitle: 'Password dimenticata?',
    submitBtnTitle: 'Reimposta password',
    submitErrorMessage: "Codice di verifica errato"
  },
  'appRegister': {
    navTitle: 'Registrati',
    navTitleHint: 'Crea un account per accedere a tutte le funzionalità!',
    firstNameLabel: 'Nome',
    firstNamePlace: 'Inserisci',
    firstNameErrorVerify: 'Inserisci il tuo nome',
    lastNameLabel: 'Cognome',
    lastNameErrorVerify: 'Inserisci il tuo cognome',
    nickNameLabel: 'Nick Nome',
    nickNameErrorVerify: 'Inserisci il tuo nickname',
    namePlace: 'Inserisci',
    gender: "Sesso",
    gender1: 'Ragazzo',
    gender2: 'Ragazza',
    gender3: 'Segretezza',
    genderError: 'Inserisci il tuo sesso',
    emailLabel: 'Numero di telefono',
    emailPlaceholder: 'Inserisci il numero di telefono!',
    emailErrorText: 'Messaggio di errore!',//请输入邮箱
    emailNullVerify: 'Inserisci il numero di telefono!',//请输入邮箱
    verificationCode: 'Codice di verifica',
    verificationPlace: 'Codice di verifica',
    sendTitle: 'Conferma numero di cellulare',
    sendText: 'Riconvalida',
    verificationCodeError: 'Il codice di verifica non può essere vuoto',
    passwordLabel: 'Password',
    passPlaceholder: 'Inserisci la password',
    confirmPassLabel: 'Conferma password',
    confirmPlace: 'segnaposto conferma password',
    passNullVerify: 'inserisci la tua password',//请输入密码
    passErrorVerify: 'La password contiene meno di 6 caratteri!',//密码小于6位
    passCompareErrorVerify: 'Le password non sono coerenti!',
    passCompareErrorNull: 'Inserisci nuovamente la password',
    registerHint: 'Hai già un account?',
    registerHintLogin: 'Accedi',
    submitBtnTitle: 'Registrati',
    submitErrorMessage: "Codice di verifica errato"
    // emailErrorVerify: '邮箱格式错误!',//邮箱格式错误
  },
  "appShopList": {
    navTitle: 'Lista dei negozi',
    searchPlace: 'Cerca...'
  },
  'appGoodsDetail': {
    salesTitle: 'Vendite',
    conditionLabel: 'Condizione:',
    conditionContent: 'Pagamenti supportati dalla categoria',
    agreement: "Accordo sulla protezione dell'acquirente",
    specSelect: 'Seleziona specifiche',
    payLabel1: 'Saldo',
    payLabel2: 'PayPal',
    payLabel3: 'Visto',
    commentTitle: 'Commento',
    frightLabel: 'Merci',
    frightContent: 'Spedisce in media entro 8 giorni',
    deliverTime: 'Valuta tempi e giorni di consegna',
    productQuantity: 'Quantità ',
    soldQuantity: "Volume delle vendite",
    collections: "Quantità dell'ordine",
    since: 'Membro dal',
    shipper: "Mittente veloce",
    responder: "Risponditore rapido",
    reliable: "Affidabile",
    btnContact: 'Contatto',
    enter: 'Invio',
    followBtn: 'Segui',
    guessLike: 'Immagino che ti piaccia',
    buyNow: 'Acquista ora',
    dialogOverviewTitle: 'Panoramica',
    overConditionLabel: 'Condizione',
    overConditionContent: 'Nuovo',
    overBrandLabel: 'Marca',
    overCategoryLabel: 'Categoria',
    overPayMethodsLabel: "Pagamenti supportati",
    payTitle: "Pagamenti supportati",
    payHint: "Il colore è bellissimo, sembra bello ed elegante. Può essere indossato con un'ampia gamma di vestiti.Rendendolo molto bello.È perfetto per essere abbinato ad abiti casual e alla moda",
    overCategoryEnter: 'Invio',
    dialogFreightTitle: 'Trasporto',
    dialogAgreementTitle: "Contratto sulla tutela dell'acquirente",
    commitContent: 'Elogio predefinito del sistema',
    footerLeft: 'Aggiungi al carrello',
    footerRight: 'Acquista ora'
  },
  'specificationDialog': {
    inventory: 'Inventario',
    countLabel: 'Numero',
    specLabel: 'Predefinito',
    options1: 'Aggiungi al carrello',
    options2: 'Acquista ora',

  },
  "appShopsDetail": {
    select1: 'Completo',
    select2: 'Volume delle vendite',
    select4: 'Prezzo dal più alto al più basso',
    select5: 'Prezzo dal più basso al più alto',
    select6: 'Punteggio',
    follow: 'Segui',
    timeDay: 'Valuta tempi e giorni di consegna',
    productQuantity: 'Quantità ',
    shippedQuantity: "Volume delle vendite",
    collections: "Quantità dell'ordine",
    commodity: 'Merci',
    classAll: 'Tutti'
  },
  // 产品评论页
  'appProductReviews': {
    navTitle: 'Commento',
    tags1: 'tutti',
    tags2: '5 stelle',
    tags3: '4 stelle',
    tags4: '3 stelle',
    tags5: '2 stelle',
    tags6: '1 stella',
    tags7: 'Con contenuti multimediali',
    commitContent: 'Elogio predefinito del sistema'
  },
  // app升级配置文件语言
  configLanguage: {
    upgradePrompt: 'Aggiornamento versione, confermare? ',
    loadingTitle: 'Scaricamento in corso...',
    systemUpgrade: 'Aggiornamento del sistema',
    upgradeImmediately: 'Conferma',
  },
  // 公共提示
  messageLanguage: {
    loadingTitle: "Caricamento in corso", // Caricamento in corso
    finishedText: "None", //None
    loadingText: "Caricamento in corso", // Caricamento in corso
    nullText: 'Ancora nessun dato',
    loadingFinished: "Caricamento completato",
    listNullText: 'Non più',
    pullIngText: 'Scorri verso il basso per aggiornare',
    loosingText: 'Rilascia per aggiornare',
    pullingSuccess: 'Caricamento pull-down riuscito'
  },
  // 'appSearchHistory': {
  //   navTitle: 'Search',
  //   searchPlace: 'Search...',
  //   recommendTitle: 'Recommend'
  // },
  // 'appClassList': {
  //   searchPlace: 'Search...',
  //   select1: 'Comprehensive',
  //   select2: 'Sales Volume',
  //   select3: 'New product',
  //   select4: 'Price High to Low',
  //   select5: 'Price Low to High',
  //   select6: 'Score',
  //   paymentTitle: 'payment',
  //   listTag1: 'Free Shipping',
  //   favoriteTitle: 'Favorite Quantity &',
  //   shopBtn: 'View Closets',
  //   recommendNavTitle: 'Recommend',
  //   usedNavTitle: 'Used',
  //   shopNavTitle: 'Shop'
  // },

  // 'appPreSale': {
  //   navTitle: 'Presale',
  //   tabs1: 'Started',
  //   tabs2: 'Not Started',
  //   tabs3: 'Ended',
  //   searchPlace: 'Search...',
  //   select1: 'Comprehensive',
  //   select2: 'Sales Volume',
  //   select3: 'New product',
  //   select4: 'Price High to Low',
  //   select5: 'Price Low to High',
  //   select6: 'Score',

  //   preSale: 'Presale',
  //   freeShipping: 'Free Shipping',
  //   payment: 'payment',
  //   preSale: 'presale',
  //   freeShipping: "Free Shipping"
  // },
  // "appGroupBuying": {
  //   navTitle: 'Group Buying',
  //   searchPlace: 'Search...',
  //   select1: 'Comprehensive',
  //   select2: 'Sales Volume',
  //   select3: 'New product',
  //   select4: 'Price High to Low',
  //   select5: 'Price Low to High',
  //   select6: 'Score',

  //   preSale: 'Presale',
  //   freeShipping: 'Free Shipping',
  //   payment: 'payment',
  //   btn1: 'Group Buying',
  //   btn2: "Free Shipping"
  // },
  // "appFlashSale": {
  //   navTitle: 'Flash Sale',
  //   searchPlace: 'Search...',
  //   select1: 'Comprehensive',
  //   select2: 'Sales Volume',
  //   select3: 'New product',
  //   select4: 'Price High to Low',
  //   select5: 'Price Low to High',
  //   select6: 'Score',

  //   preSale: 'Presale',
  //   freeShipping: 'Free Shipping',
  //   payment: 'payment',
  //   preSale: 'presale',
  //   freeShipping: "Free Shipping"
  // },
};
export default obj;
