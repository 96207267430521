// 繁体中文

let obj = {};
obj = {
  // 语言
  languageVersion: [
    'English',  // 英语
    '日本語', // 日语
    'Português', // 葡萄牙语
    '繁體中文', // 繁体中文
    'Français', // 法语
    'Deutsch', // 德语
    'Türk', // 土耳其语
    'Español', // 西班牙语
    'عربي', // 阿拉伯语
    '한국인', // 韩语
    'Suomi', // 芬兰语
    'Русский', // 俄语
    'italiano', // 意大利语
    'แบบไทย', // 泰语
    'Nederlands', // 荷兰语
  ],
  'footer': {
    home: "首頁",
  },
  'setDrawer': {
    label1: '多語言設定',
    label2: '商店註冊',
    label3: '商店登入',
    label4: '客戶服務',
    label5: '下載 Android 應用程式',
    label6: '下載ios應用程式',
    optionsBtn: '取消'
  },
  'appHome': {
    searchPlace: '搜尋...',
    recommendTitle: '推薦',
    viewAll: '查看全部',
    usedTitle: '已使用',
    shopTitle: '商店',
    shopOption: '立即查看',
    hotTitle: '熱門清單',
    classTag1: '家',
    classTag2: '女性',
    classTag3: '男士',
    classTag4: '孩子們',
    classTag5: '電子',
    classTag6: '寵物',
    buyNow: '立即購買',

    // ranking: 'Sales Ranking',
    // preSaleTitle: 'Presale',
    // preSaleTabs1: 'Started',
    // preSaleTabs2: 'Not started',
    // preSaleTabs3: 'Ended',
    // groupTitle: 'Group Buying',
    // peopleGroupBuying: '人参与团购',
    // flashSaleTitle: 'Flash Sale',
  },
  'appSetLanguage': {
    navTitle: '多語言設定',
    affirm: '確認',
  },
  "appService": {
    navTitle: '顧客服務',
    customerService: '專屬客戶服務',
    helloTitle: '你好',
    welcomeTitle: '我是你的<，你可以透過以下管道聯絡我',
    iconInformation: '請選擇通訊軟體進行諮詢',
    whatsApp: 'whatsApp ',
    telegram: 'telegram ',
    line: 'line',
  },
  // 移动分类页
  'appClassification': {
    navTitle: '商品分類',
    viewAll: '查看全部',
  },
  'appHotList': {
    navTitle: '熱門名單',
    ranking: '銷售排名',
  },
  'appHotRanking': {
    navTitle: '銷售排名',
    searchPlace: '搜尋...',
    payment: 'payment',
    optionBtn1: '收集',
    optionBtn2: '立即購買',
    errorMessage: '請登入',
  },
  'appUsedList': {
    navTitle: '已使用',
    recommendNavTitle: '推薦',
    classNavTitle: '分類',
    searchNavTitle: '搜尋',
    searchPlace: '搜尋...',
    select1: '綜合',
    select2: '銷量',
    select3: '新產品',
    select4: '價格由高到低',
    select5: '價格從低到高',
    select6: '分數',
    classAll: '全部',
    listTag1: '免運費',
    paymentTitle: '付款'
  },
  'appRecommendList': {
    navTitle: '推薦',
    searchPlace: '搜尋...',
    select1: '綜合',
    select2: '銷量',
    select3: '新產品',
    select4: '價格由高到低',
    select5: '價格從低到高',
    select6: '分數',
    classAll: '全部',
    listTag1: '免運費',
    paymentTitle: '付款'
  },
  'appLogin': {
    navTitle: '登入',
    navTitleHint: '立即登入並開始與來自世界各地的朋友進行交易！',
    singUp: '註冊',
    emailLabel: '電話號碼',
    emailPlaceholder: '請輸入電話號碼',
    emailErrorText: '錯誤提示',
    emailNullVerify: '請輸入電話號碼',//請輸入信箱
    passwordLabel: '您的密碼',
    passPlaceholder: '請輸入密碼',
    passNullVerify: '請輸入密碼',//請輸入密碼
    passErrorVerify: '密碼小於6位',//密碼小於6位
    佔位符: '請輸入',
    emailRegistration: '電話號碼註冊',
    forgetPassTitle: '忘記密碼？',
    SubmitBtnTitle: '登入',
    errorMessage: '使用者名稱或密碼不正確',
    // emailErrorVerify: '邮箱格式错误！',//邮箱格式错误
  },
  'appForgotPass': {
    navTitle: '忘記密碼',
    navTitleHint: '請寫下您的手機號碼，以便接收確認碼以設定新密碼。',
    emailLabel: '電話號碼',
    emailPlaceholder: '請輸入電話號碼',
    emailErrorText: '請輸入電話號碼',//請輸入信箱
    emailNullVerify: '請輸入電話號碼',//請輸入信箱
    emailErrorVerify: '手機號碼錯誤',//信箱格式錯誤
    verifyCode: '驗證碼',
    verifyPlace: '驗證碼',
    verifyCodeError: '請輸入驗證碼',
    sendTitle: '確認手機號碼',
    sendText: '重新驗證',
    passwordLabel: '密碼',
    passPlaceholder: '請輸入密碼',
    verifyPassLabel: '確認密碼',
    informPlace: '佔位符確認密碼',
    passNullVerify: '請輸入密碼',//請輸入密碼
    passErrorVerify: '密碼小於6個字元',//密碼小於6位
    passCompareErrorVerify: '密碼不一致',
    passCompareErrorNull: '請再輸入密碼',
    emailRegistration: '電話號碼註冊',
    forgetPassTitle: '忘記密碼？',
    commitBtnTitle: '重設密碼',
    commitErrorMessage: '驗證碼不正確',
  },
  'appRegister': {
    navTitle: '註冊',
    navTitleHint: '建立一個帳戶以存取所有功能！',
    firstNameLabel: '名字',
    firstNamePlace: '請輸入',
    firstNameErrorVerify: '請輸入您的名字',
    lastNameLabel: '姓氏',
    lastNameErrorVerify: '請輸入您的姓氏',
    nickNameLabel: '暱稱',
    nickNameErrorVerify: '請輸入您的暱稱',
    namePlace: '請輸入',
    gender: '性別',
    gender1: '男孩',
    gender2: '女孩',
    gender3: '保密',
    genderError: '請輸入您的性別',
    emailLabel: '電話號碼',
    emailPlaceholder: '請輸入電話號碼！',
    emailErrorText: '錯誤訊息！',//請輸入郵箱
    emailNullVerify: '請輸入電話號碼！',//請輸入郵箱
    verificationCode: '驗證碼',
    verificationPlace: '驗證碼',
    sendTitle: '確認手機號碼',
    sendText: '重新驗證',
    verificationCodeError: '驗證碼不能為空',
    passwordLabel: '密碼',
    passPlaceholder: '請輸入密碼',
    confirmPassLabel: '確認密碼',
    confirmPlace: '佔位符確認密碼',
    passNullVerify: '請輸入密碼',//請輸入密碼
    passErrorVerify: '密碼小於6個字元！',//密碼小於6位
    passCompareErrorVerify: '密碼不一致！',
    passCompareErrorNull: '請再輸入密碼',
    registerHint: '已有帳戶？',
    registerHintLogin: '登入',
    submitBtnTitle: '註冊',
    submitErrorMessage: '驗證碼不正確'
    // emailErrorVerify: '邮箱格式错误!',//邮箱格式错误
  },
  "appShopList": {
    navTitle: '商店清單',
    searchPlace: '搜尋...'
  },
  'appGoodsDetail': {
    salesTitle: '銷售',
    conditionLabel: '條件：',
    conditionContent: '類別支援的付款',
    agreement: '買方保護協議',
    specSelect: '選擇規格',
    payLabel1: '餘額',
    payLabel2: '貝寶',
    payLabel3: '簽證',
    commentTitle: '評論',
    frightLabel: '貨運',
    frightContent: '平均在 8 天內出貨',
    deliverTime: '評估交貨時間和天數',
    productQuantity: '數量',
    soldQuantity: "銷售量",
    collections: '訂單數量',
    since: '會員以來',
    shipper: '快速托運人',
    responder: '快速響應者',
    reliable: '可靠',
    btnContact: '聯絡',
    enter: '輸入',
    followBtn: '關注',
    guessLike: '猜你喜歡它',
    buyNow: '立即購買',
    dialogOverviewTitle: '概述',
    overConditionLabel: '條件',
    overConditionContent: '新',
    overBrandLabel: '品牌',
    overCategoryLabel: '類別',
    overPayMethodsLabel: '支援的付款',
    payTitle: '支持的付款',
    payHint: "顏色很漂亮，看起來很時尚，可以搭配各種衣服。使它非常漂亮，非常適合搭配休閒和時尚的衣服",
    overCategoryEnter: '輸入',
    dialogFreightTitle: '運費',
    dialogAgreementTitle: '買家保護協議',
    commitContent: '系統預設好評',
    footerLeft: '加入購物車',
    footerRight: '立即購買'
  },
  'specificationDialog': {
    inventory: '庫存',
    specLabel: '預設',
    countLabel: '數量',
    options1: '加入購物車',
    options2: '立即購買',

  },
  "appShopsDetail": {
    select1: '綜合',
    select2: '銷量',
    select4: '價格由高到低',
    select5: '價格從低到高',
    select6: '分數',
    follow: '跟隨',
    timeDay: '評估交貨時間和天數',
    productQuantity: '數量',
    shippedQuantity: '銷售量',
    collections: '訂單數量',
    commodity: '商品',
    classAll: '全部'
  },
  // 产品评论页
  'appProductReviews': {
    navTitle: '評論',
    tags1: '全部',
    tags2: '5星',
    tags3: '4星',
    tags4: '3星',
    tags5: '2星',
    tags6: '1星',
    tags7: '與媒體',
    commitContent: '系統預設好評'
  },
  // app升级配置文件语言
  configLanguage: {
    upgradePrompt: '版本升級，請確認？',
    loadingTitle: '正在下載...',
    systemUpgrade: '系統升級',
    upgradeImmediately: '確認',
  },
  // 公共提示
  messageLanguage: {
    loadingTitle: "正在載入", // 正在載入
    finishedText: "無", //無
    loadingText: "正在載入", // 正在載入
    nullText: '還沒有資料',
    loadingFinished: "載入完成",
    listNullText: '沒有了',
    pullIngText: '下拉刷新',
    loosingText: '釋放刷新',
    pullingSuccess: '下拉載入成功'
  },
  // 'appSearchHistory': {
  //   navTitle: 'Search',
  //   searchPlace: 'Search...',
  //   recommendTitle: 'Recommend'
  // },
  // 'appClassList': {
  //   searchPlace: 'Search...',
  //   select1: 'Comprehensive',
  //   select2: 'Sales Volume',
  //   select3: 'New product',
  //   select4: 'Price High to Low',
  //   select5: 'Price Low to High',
  //   select6: 'Score',
  //   paymentTitle: 'payment',
  //   listTag1: 'Free Shipping',
  //   favoriteTitle: 'Favorite Quantity &',
  //   shopBtn: 'View Closets',
  //   recommendNavTitle: 'Recommend',
  //   usedNavTitle: 'Used',
  //   shopNavTitle: 'Shop'
  // },

  // 'appPreSale': {
  //   navTitle: 'Presale',
  //   tabs1: 'Started',
  //   tabs2: 'Not Started',
  //   tabs3: 'Ended',
  //   searchPlace: 'Search...',
  //   select1: 'Comprehensive',
  //   select2: 'Sales Volume',
  //   select3: 'New product',
  //   select4: 'Price High to Low',
  //   select5: 'Price Low to High',
  //   select6: 'Score',

  //   preSale: 'Presale',
  //   freeShipping: 'Free Shipping',
  //   payment: 'payment',
  //   preSale: 'presale',
  //   freeShipping: "Free Shipping"
  // },
  // "appGroupBuying": {
  //   navTitle: 'Group Buying',
  //   searchPlace: 'Search...',
  //   select1: 'Comprehensive',
  //   select2: 'Sales Volume',
  //   select3: 'New product',
  //   select4: 'Price High to Low',
  //   select5: 'Price Low to High',
  //   select6: 'Score',

  //   preSale: 'Presale',
  //   freeShipping: 'Free Shipping',
  //   payment: 'payment',
  //   btn1: 'Group Buying',
  //   btn2: "Free Shipping"
  // },
  // "appFlashSale": {
  //   navTitle: 'Flash Sale',
  //   searchPlace: 'Search...',
  //   select1: 'Comprehensive',
  //   select2: 'Sales Volume',
  //   select3: 'New product',
  //   select4: 'Price High to Low',
  //   select5: 'Price Low to High',
  //   select6: 'Score',

  //   preSale: 'Presale',
  //   freeShipping: 'Free Shipping',
  //   payment: 'payment',
  //   preSale: 'presale',
  //   freeShipping: "Free Shipping"
  // },
};
export default obj;
