// 德语

let obj = {};
obj = {
  // 语言
  languageVersion: [
    'English',  // 英语
    '日本語', // 日语
    'Português', // 葡萄牙语
    '繁體中文', // 繁体中文
    'Français', // 法语
    'Deutsch', // 德语
    'Türk', // 土耳其语
    'Español', // 西班牙语
    'عربي', // 阿拉伯语
    '한국인', // 韩语
    'Suomi', // 芬兰语
    'Русский', // 俄语
    'italiano', // 意大利语
    'แบบไทย', // 泰语
    'Nederlands', // 荷兰语
  ],
  'footer': {
    home: "Heim",
  },
  'setDrawer': {
    label1: 'Mehrsprachige Einstellungen',
    label2: 'Store-Registrierung',
    label3: 'Store-Login',
    label4: "Kundenservice",
    label5: "Android-Anwendung herunterladen",
    label6: "iOS-Anwendung herunterladen",
    optionsBtn: 'Abbrechen'
  },
  'appHome': {
    searchPlace: 'Suchen...',
    recommendTitle: 'Empfehlen',
    viewAll: 'Alle anzeigen',
    usedTitle: 'Gebraucht',
    shopTitle: 'Shop',
    shopOption: 'Jetzt ansehen',
    hotTitle: 'Hot List',
    classTag1: 'Home',
    classTag2: 'Frauen',
    classTag3: 'Männer',
    classTag4: 'Kinder',
    classTag5: 'Elektronik',
    classTag6: 'Haustiere',
    buyNow: 'Jetzt kaufen',
    // buyNow: 'Buy Now',
    // ranking: 'Sales Ranking',
    // preSaleTitle: 'Presale',
    // preSaleTabs1: 'Started',
    // preSaleTabs2: 'Not started',
    // preSaleTabs3: 'Ended',
    // groupTitle: 'Group Buying',
    // peopleGroupBuying: '人参与团购',
    // flashSaleTitle: 'Flash Sale',
  },
  'appSetLanguage': {
    navTitle: "Mehrsprachige Einstellungen",
    affirm: 'bestätigen',
  },
  "appService": {
    navTitle: "Kundenservice",
    customerService: 'Exklusiver Kundenservice',
    helloTitle: 'Hallo',
    welcomeTitle: "Ich gehöre dir<, du kannst mich über die folgenden Kanäle kontaktieren",
    iconInformation: "Bitte wählen Sie Kommunikationssoftware zur Beratung aus",
    whatsApp: 'whatsApp ',
    telegram: 'telegram ',
    line: 'line',
  },
  // 移动分类页
  'appClassification': {
    navTitle: "Warenklassifizierung",
    viewAll: 'Alle anzeigen',
  },
  'appHotList': {
    navTitle: 'Hot List',
    ranking: 'Verkaufsranking',
  },
  'appHotRanking': {
    navTitle: "Accents Sales Ranking",
    searchPlace: 'Suchen...',
    payment: 'Zahlung',
    optionBtn1: 'Sammeln',
    optionBtn2: 'Jetzt kaufen',
    errorMessage: "Bitte melden Sie sich an",
  },
  'appUsedList': {
    navTitle: 'Gebraucht',
    recommendNavTitle: 'Empfehlen',
    classNavTitle: 'Klassifizierung',
    searchNavTitle: 'Suchen',
    searchPlace: 'Suchen...',
    select1: 'Umfassend',
    select2: 'Umsatzvolumen',
    select3: 'Neues Produkt',
    select4: 'Preis hoch nach niedrig',
    select5: 'Preis niedrig nach hoch',
    select6: 'Punktzahl',
    classAll: 'Alle',
    listTag1: "Kostenloser Versand",
    paymentTitle: 'Zahlung'
  },
  'appRecommendList': {
    navTitle: "Empfehlen",
    searchPlace: 'Suchen...',
    select1: 'Umfassend',
    select2: 'Umsatzvolumen',
    select3: 'Neues Produkt',
    select4: 'Preis hoch nach niedrig',
    select5: 'Preis niedrig nach hoch',
    select6: 'Punktzahl',
    classAll: 'Alle',
    listTag1: "Kostenloser Versand",
    paymentTitle: 'Zahlung'
  },
  'appLogin': {
    navTitle: 'Anmelden',
    navTitleHint: 'Melden Sie sich jetzt an und beginnen Sie mit Freunden aus der ganzen Welt zu handeln!',
    singUp: 'Anmelden',
    emailLabel: 'Telefonnummer',
    emailPlaceholder: 'Bitte Telefonnummer eingeben',
    emailErrorText: 'Fehlermeldung',
    emailNullVerify: 'Bitte geben Sie die Telefonnummer ein',//请输入邮箱
    passwordLabel: 'Ihr Passwort',
    passPlaceholder: 'Bitte Passwort eingeben',
    passNullVerify: 'Bitte geben Sie Ihr Passwort ein',//请输入密码
    passErrorVerify: 'Das Passwort besteht aus weniger als 6 Zeichen',//密码小于于6位
    placeholder: "Bitte Eingabe",
    emailRegistration: 'Registrierung der Telefonnummer',
    forgetPassTitle: 'Passwort vergessen?',
    submitBtnTitle: 'Anmelden',
    errorMessage: "Benutzername oder Passwort falsch",
    // emailErrorVerify: '邮箱格式错误！',//邮箱格式错误
  },
  'appForgotPass': {
    navTitle: "Passwort vergessen",
    navTitleHint: 'Bitte notieren Sie sich Ihre Mobiltelefonnummer, um einen Bestätigungscode zum Festlegen eines neuen Passworts zu erhalten.',
    emailLabel: 'Telefonnummer',
    emailPlaceholder: 'Bitte Telefonnummer eingeben',
    emailErrorText: 'Bitte geben Sie die Telefonnummer ein',//请输入邮箱
    emailNullVerify: 'Bitte geben Sie die Telefonnummer ein',//请输入邮箱
    emailErrorVerify: "Falsche Handynummer",//邮箱格式错误
    verificationCode: 'Bestätigungscode',
    verificationPlace: 'Verifizierungscode',
    verificationCodeError: "Bitte geben Sie den Verifizierungscode ein",
    sendTitle: 'Handynummer bestätigen',
    sendText: 'Revalidierung',
    passwordLabel: 'Passwort',
    passPlaceholder: 'Bitte Passwort eingeben',
    confirmPassLabel: 'Passwort bestätigen',
    confirmPlace: 'Platzhalter Passwort bestätigen',
    passNullVerify: 'Geben Sie Ihr Passwort ein',//请输入密码
    passErrorVerify: 'Passwort ist weniger als 6 Zeichen',//密码小于于6位
    passCompareErrorVerify: "Passwörter sind inkonsistent",
    passCompareErrorNull: 'Bitte geben Sie das Passwort erneut ein',
    emailRegistration: 'Registrierung der Telefonnummer',
    forgetPassTitle: 'Passwort vergessen?',
    submitBtnTitle: 'Passwort zurücksetzen',
    submitErrorMessage: "Falscher Bestätigungscode"

  },
  'appRegister': {
    navTitle: 'Registrieren',
    navTitleHint: 'Erstellen Sie ein Konto, um auf alle Funktionen zuzugreifen!',
    firstNameLabel: 'Vorname',
    firstNamePlace: 'Bitte eingeben',
    firstNameErrorVerify: 'Bitte geben Sie Ihren Vornamen ein',
    lastNameLabel: 'Nachname',
    lastNameErrorVerify: 'Bitte geben Sie Ihren Nachnamen ein',
    nickNameLabel: 'Spitzname',
    nickNameErrorVerify: 'Bitte geben Sie Ihren Spitznamen ein',
    namePlace: 'Bitte eingeben',
    gender: 'Geschlecht',
    gender1: 'Junge',
    gender2: 'Mädchen',
    gender3: 'Geheimhaltung',
    genderError: "Bitte geben Sie Ihr Geschlecht ein",
    emailLabel: 'Telefonnummer',
    emailPlaceholder: 'Bitte Telefonnummer eingeben!',
    emailErrorText: 'Fehlermeldung!',//请输入邮箱
    emailNullVerify: 'Bitte Telefonnummer eingeben!',//请输入邮箱
    verificationCode: 'Bestätigungscode',
    verificationPlace: 'Verifizierungscode',
    sendTitle: 'Handynummer bestätigen',
    sendText: 'Revalidierung',
    verificationCodeError: "Bestätigungscode darf nicht leer sein",
    passwordLabel: 'Passwort',
    passPlaceholder: 'Bitte Passwort eingeben',
    confirmPassLabel: 'Passwort bestätigen',
    confirmPlace: 'Platzhalter Passwort bestätigen',
    passNullVerify: 'Geben Sie Ihr Passwort ein',//请输入密码
    passErrorVerify: 'Passwort ist weniger als 6 Zeichen！',//密码小于于6位
    passCompareErrorVerify: "Passwörter sind inkonsistent！",
    passCompareErrorNull: 'Bitte geben Sie das Passwort erneut ein',
    registerHint: "Haben Sie bereits ein Konto?",
    registerHintLogin: 'Anmelden',
    submitBtnTitle: 'Registrieren',
    submitErrorMessage: "Falscher Bestätigungscode"
    // emailErrorVerify: '邮箱格式错误!',//邮箱格式错误
  },
  "appShopList": {
    navTitle: 'Shop-Liste',
    searchPlace: 'Suchen...'
  },
  'appGoodsDetail': {
    salesTitle: 'Sales',
    conditionLabel: 'Bedingung:',
    conditionContent: 'Kategorieunterstützte Zahlungen',
    agreement: "Käuferschutzvereinbarung",
    specSelect: 'Spezifikationen auswählen',
    payLabel1: 'Saldo',
    payLabel2: 'PayPal',
    payLabel3: 'Visa',
    commentTitle: 'Kommentar',
    frightLabel: 'Fracht',
    frightContent: "Versand im Durchschnitt innerhalb von 8 Tagen",
    deliverTime: 'Lieferzeit und -tage auswerten',
    productQuantity: 'Menge',
    soldQuantity: "Verkaufsvolumen",
    collections: 'Bestellmenge',
    since: 'Mitglied seit',
    shipper: "Schneller Versender",
    responder: "Schneller Responder",
    reliable: 'Zuverlässig',
    btnContact: 'Kontakt',
    enter: 'Enter',
    followBtn: 'Folgen',
    guessLike: "Ich schätze, es gefällt dir",
    buyNow: 'Jetzt kaufen',
    dialogOverviewTitle: 'Übersicht',
    overConditionLabel: 'Bedingung',
    overConditionContent: 'Neu',
    overBrandLabel: 'Marke',
    overCategoryLabel: 'Kategorie',
    overPayMethodsLabel: "Unterstützte Zahlungen",
    payTitle: "Unterstützte Zahlungen",
    payHint: "Die Farbe ist wunderschön, sie sieht elegant und elegant aus.Sie kann mit allen Kleidungsstücken getragen werden.Dadurch ist sie sehr schön.Sie eignet sich perfekt für die Kombination mit lässiger und modischer Kleidung",
    overCategoryEnter: 'Enter',
    dialogFreightTitle: 'Fracht',
    dialogAgreementTitle: "Käuferschutzvereinbarung",
    commitContent: 'Systemstandardlob',
    footerLeft: "Zum Warenkorb hinzufügen",
    footerRight: "Jetzt kaufen"
  },
  'specificationDialog': {
    inventory: 'Inventar',
    countLabel: 'Nummer',
    specLabel: 'Standard',
    options1: "In den Warenkorb",
    options2: "Jetzt kaufen",
  },
  "appShopsDetail": {
    select1: 'Umfassend',
    select2: 'Umsatzvolumen',
    select4: 'Preis hoch nach niedrig',
    select5: 'Preis niedrig nach hoch',
    select6: 'Punktzahl',
    follow: 'Folgen',
    timeDay: 'Lieferzeit und -tage auswerten',
    productQuantity: 'Menge',
    shippedQuantity: "Verkaufsvolumen",
    collections: 'Bestellmenge',
    commodity: 'Ware',
    classAll: 'Alle'
  },
  // 产品评论页
  'appProductReviews': {
    navTitle: 'Kommentar',
    tags1: 'alle',
    tags2: '5 Sterne',
    tags3: '4 Sterne',
    tags4: '3 Sterne',
    tags5: '2 Sterne',
    tags6: '1 Stern',
    tags7: 'Mit Medien',
    commitContent: "Systemstandard- Lob"
  },
  // app升级配置文件语言
  configLanguage: {
    upgradePrompt: "Versionsaktualisierung, bitte bestätigen?",
    loadingTitle: 'Herunterladen...',
    systemUpgrade: 'System-Upgrade',
    upgradeImmediately: 'Bestätigen',
  },
  // 公共提示
  messageLanguage: {
    loadingTitle: "Loading", // Loading
    finishedText: "None", //None
    loadingText: "Loading", // Loading
    nullText: 'Noch keine Daten',
    loadingFinished: "Ladevorgang abgeschlossen",
    listNullText: 'Nicht mehr',
    pullIngText: 'Zum Aktualisieren nach unten ziehen',
    loosingText: 'Zum Aktualisieren freigeben',
    pullingSuccess: "Pulldown- Ladevorgang erfolgreich"
  },
  // 'appSearchHistory': {
  //   navTitle: 'Search',
  //   searchPlace: 'Search...',
  //   recommendTitle: 'Recommend'
  // },
  // 'appClassList': {
  //   searchPlace: 'Search...',
  //   select1: 'Comprehensive',
  //   select2: 'Sales Volume',
  //   select3: 'New product',
  //   select4: 'Price High to Low',
  //   select5: 'Price Low to High',
  //   select6: 'Score',
  //   paymentTitle: 'payment',
  //   listTag1: 'Free Shipping',
  //   favoriteTitle: 'Favorite Quantity &',
  //   shopBtn: 'View Closets',
  //   recommendNavTitle: 'Recommend',
  //   usedNavTitle: 'Used',
  //   shopNavTitle: 'Shop'
  // },

  // 'appPreSale': {
  //   navTitle: 'Presale',
  //   tabs1: 'Started',
  //   tabs2: 'Not Started',
  //   tabs3: 'Ended',
  //   searchPlace: 'Search...',
  //   select1: 'Comprehensive',
  //   select2: 'Sales Volume',
  //   select3: 'New product',
  //   select4: 'Price High to Low',
  //   select5: 'Price Low to High',
  //   select6: 'Score',

  //   preSale: 'Presale',
  //   freeShipping: 'Free Shipping',
  //   payment: 'payment',
  //   preSale: 'presale',
  //   freeShipping: "Free Shipping"
  // },
  // "appGroupBuying": {
  //   navTitle: 'Group Buying',
  //   searchPlace: 'Search...',
  //   select1: 'Comprehensive',
  //   select2: 'Sales Volume',
  //   select3: 'New product',
  //   select4: 'Price High to Low',
  //   select5: 'Price Low to High',
  //   select6: 'Score',

  //   preSale: 'Presale',
  //   freeShipping: 'Free Shipping',
  //   payment: 'payment',
  //   btn1: 'Group Buying',
  //   btn2: "Free Shipping"
  // },
  // "appFlashSale": {
  //   navTitle: 'Flash Sale',
  //   searchPlace: 'Search...',
  //   select1: 'Comprehensive',
  //   select2: 'Sales Volume',
  //   select3: 'New product',
  //   select4: 'Price High to Low',
  //   select5: 'Price Low to High',
  //   select6: 'Score',

  //   preSale: 'Presale',
  //   freeShipping: 'Free Shipping',
  //   payment: 'payment',
  //   preSale: 'presale',
  //   freeShipping: "Free Shipping"
  // },
};
export default obj;