import Vue from 'vue'
import App from './App.vue'
import router from './router/index.js'
import '@/fonts/fonts.css'
import store from '@/store'
import 'lib-flexible/flexible'
// 引入
import VueLazyload from 'vue-lazyload'
import '@/icons'
// Vue.use(VueLazyload) 
Vue.use(VueLazyload, {
  // 加载失败后加载的图片
  error: require('./assets/logo.png'),
  loading: require('./assets/logo.png'),
})
import '@/utils/vant'
import appNotify from '@/views/component/app-notify.vue'
import defaultPage from './components/defaultPage.vue'
Vue.component('appNotify', appNotify)
Vue.component('defaultPage', defaultPage)
import './utils/appback.js'
import './utils/version' 

import { languageList, languageType } from '@/utils/language/language'
Vue.prototype.$language = languageList
Vue.prototype.$languageType = languageType


import global_msg from './utils/global'

Vue.prototype.$globalCommon = global_msg;
Vue.prototype.$JumpShops = global_msg.$JumpShops;

Vue.config.productionTip = false
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')