// 西班牙语
let obj = {};
obj = {
    // 语言
    languageVersion: [
        'English',  // 英语
        '日本語', // 日语
        'Português', // 葡萄牙语
        '繁體中文', // 繁体中文
        'Français', // 法语
        'Deutsch', // 德语
        'Türk', // 土耳其语
        'Español', // 西班牙语
        'عربي', // 阿拉伯语
        '한국인', // 韩语
        'Suomi', // 芬兰语
        'Русский', // 俄语
        'italiano', // 意大利语
        'แบบไทย', // 泰语
        'Nederlands', // 荷兰语
    ],
    'footer': {
        home: "Hogar",
    },
    'setDrawer': {
        label1: 'Configuración multilingüe',
        label2: 'Registro de tienda',
        label3: 'Inicio de sesión en tienda',
        label4: 'Servicio al cliente',
        label5: 'Descargar aplicación para Android',
        label6: 'Descargar aplicación ios',
        optionsBtn: 'Cancelar'
    },
    'appHome': {
        searchPlace: 'Buscar...',
        recommendTitle: 'Recomendar',
        viewAll: 'ver todo',
        usedTitle: 'Usado',
        shopTitle: 'Tienda',
        shopOption: 'Ver ahora',
        hotTitle: 'Lista Caliente',
        classTag1: 'Inicio',
        classTag2: 'Mujeres',
        classTag3: 'Hombres',
        classTag4: 'Niños',
        classTag5: 'Electrónica',
        classTag6: 'Mascotas',
        buyNow: 'Comprar Ahora',

        // ranking: 'Sales Ranking',
        // preSaleTitle: 'Presale',
        // preSaleTabs1: 'Started',
        // preSaleTabs2: 'Not started',
        // preSaleTabs3: 'Ended',
        // groupTitle: 'Group Buying',
        // peopleGroupBuying: '人参与团购',
        // flashSaleTitle: 'Flash Sale',
    },
    'appSetLanguage': {
        navTitle: 'Configuración multilingüe',
        affirm: 'confirmar',
    },
    "appService": {
        navTitle: 'Servicio al cliente',
        customerService: 'Servicio de atención al cliente exclusivo',
        helloTitle: 'Hola',
        welcomeTitle: 'Soy tuyo<, puedes contactarme a través de los siguientes canales',
        iconInformation: 'Seleccione el software de comunicación para consulta',
        whatsApp: 'whatsApp ',
        telegram: 'telegram ',
        line: 'line',
    },
    // 移动分类页
    'appClassification': {
        navTitle: 'Clasificación de productos básicos',
        viewAll: 'Ver todo',
    },
    'appHotList': {
        navTitle: 'Lista caliente',
        ranking: 'Ranking de ventas',
    },
    'appHotRanking': {
        navTitle: 'Ranking de ventas de acentos',
        searchPlace: 'Buscar...',
        payment: 'pago',
        optionBtn1: 'Recoger',
        optionBtn2: 'Comprar ahora',
        errorMessage: 'Inicia sesión',
    },
    'appUsedList': {
        navTitle: 'Usado',
        recommendNavTitle: 'Recomendar',
        classNavTitle: 'Clasificación',
        searchNavTitle: 'Buscar',
        searchPlace: 'Buscar...',
        select1: 'Completo',
        select2: 'Volumen de ventas',
        select3: 'Nuevo producto',
        select4: 'Precio mayor a menor',
        select5: 'Precio de menor a mayor',
        select6: 'Puntuación',
        classAll: 'Todos',
        listTag1: 'Envío gratuito',
        paymentTitle: 'Pago'
    },
    'appRecommendList': {
        navTitle: 'Recomendar',
        searchPlace: 'Buscar...',
        select1: 'Completo',
        select2: 'Volumen de ventas',
        select3: 'Nuevo producto',
        select4: 'Precio mayor a menor',
        select5: 'Precio de menor a mayor',
        select6: 'Puntuación',
        classAll: 'Todos',
        listTag1: 'Envío gratuito',
        paymentTitle: 'Pago'
    },
    'appLogin': {
        navTitle: 'Iniciar sesión',
        navTitleHint: '¡Inicie sesión ahora y comience a operar con amigos de todo el mundo!',
        singUp: 'Registrarse',
        emailLabel: 'Número de teléfono',
        emailPlaceholder: 'Ingrese el número de teléfono',
        emailErrorText: 'Mensaje de error',
        emailNullVerify: 'Ingrese el número de teléfono',//请输入邮箱
        passwordLabel: 'Su contraseña',
        passPlaceholder: 'Por favor ingrese la contraseña',
        passNullVerify: 'Ingrese su contraseña',//请输入密码
        passErrorVerify: 'La contraseña tiene menos de 6 caracteres',//密码小于6位
        placeholder: 'Por favor ingrese',
        emailRegistration: 'Registro de número de teléfono',
        forgetPassTitle: '¿Olvidaste tu contraseña?',
        submitBtnTitle: 'Iniciar sesión',
        errorMessage: 'Nombre de usuario o contraseña incorrectos',
        // emailErrorVerify: '邮箱格式错误！',//邮箱格式错误
    },
    'appForgotPass': {
        navTitle: 'Olvidé mi contraseña',
        navTitleHint: 'Escriba su número de teléfono móvil para recibir un código de confirmación para establecer una nueva contraseña.',
        emailLabel: 'Número de teléfono',
        emailPlaceholder: 'Ingrese el número de teléfono',
        emailErrorText: 'Ingrese el número de teléfono',//请输入邮箱
        emailNullVerify: 'Ingrese el número de teléfono',//请输入邮箱
        emailErrorVerify: 'Número de móvil incorrecto',//邮箱格式错误
        verificationCode: 'Código de verificación',
        verificationPlace: 'Código de verificación',
        verificationCodeError: 'Ingrese el código de verificación',
        sendTitle: 'Confirmar número de móvil',
        sendText: 'Revalidación',
        passwordLabel: 'Contraseña',
        passPlaceholder: 'Por favor ingrese la contraseña',
        confirmPassLabel: 'Confirmar contraseña',
        confirmPlace: 'marcador de posición para confirmar contraseña',
        passNullVerify: 'Ingrese su contraseña',//请输入密码
        passErrorVerify: 'La contraseña tiene menos de 6 caracteres',//密码小于6位
        passCompareErrorVerify: 'Las contraseñas son inconsistentes',
        passCompareErrorNull: 'Ingrese la contraseña nuevamente',
        emailRegistration: 'Registro de número de teléfono',
        forgetPassTitle: '¿Olvidaste tu contraseña?',
        submitBtnTitle: 'Restablecer contraseña',
        submitErrorMessage: 'Código de verificación incorrecto'
    },
    'appRegister': {
        navTitle: 'Registrarse',
        navTitleHint: '¡Crea una cuenta para acceder a todas las funciones!',
        firstNameLabel: 'Nombre',
        firstNamePlace: 'Por favor ingrese',
        firstNameErrorVerify: 'Por favor ingrese su nombre',
        lastNameLabel: 'Apellido',
        lastNameErrorVerify: 'Por favor ingrese su apellido',
        nickNameLabel: 'Apodo',
        nickNameErrorVerify: 'Por favor ingresa tu apodo',
        namePlace: 'Por favor ingrese',
        gender: 'Género',
        gender1: 'Niño',
        gender2: 'Niña',
        gender3: 'Secreto',
        genderError: 'Por favor ingresa tu género',
        emailLabel: 'Número de teléfono',
        emailPlaceholder: '¡Ingrese el número de teléfono!',
        emailErrorText: '¡Mensaje de error!',//请输入邮箱
        emailNullVerify: '¡Ingrese el número de teléfono!',//请输入邮箱
        verificationCode: 'Código de verificación',
        verificationPlace: 'Código de verificación',
        sendTitle: 'Confirmar número de móvil',
        sendText: 'Revalidación',
        verificationCodeError: 'El código de verificación no puede estar vacío',
        passwordLabel: 'Contraseña',
        passPlaceholder: 'Por favor ingrese la contraseña',
        confirmPassLabel: 'Confirmar contraseña',
        confirmPlace: 'marcador de posición para confirmar contraseña',
        passNullVerify: 'ingrese su contraseña',//请输入密码
        passErrorVerify: '¡La contraseña tiene menos de 6 caracteres!',//密码小于6位
        passCompareErrorVerify: '¡Las contraseñas son inconsistentes!',
        passCompareErrorNull: 'Ingrese la contraseña nuevamente',
        registerHint: '¿Ya tienes una cuenta?',
        registerHintLogin: 'Iniciar sesión',
        submitBtnTitle: 'Registrarse',
        submitErrorMessage: 'Código de verificación incorrecto'
        // emailErrorVerify: '邮箱格式错误!',//邮箱格式错误
    },
    "appShopList": {
        navTitle: 'Lista de tiendas',
        searchPlace: 'Buscar...'
    },
    'appGoodsDetail': {
        salesTitle: 'Ventas',
        conditionLabel: 'Condición:',
        conditionContent: 'Pagos admitidos por categoría',
        agreement: 'Acuerdo de protección del comprador',
        specSelect: 'Seleccionar especificaciones',
        payLabel1: 'Saldo',
        payLabel2: 'PayPal',
        payLabel3: 'Visa',
        commentTitle: 'Comentario',
        frightLabel: 'Flete',
        frightContent: 'Se envía en un promedio de 8 días',
        deliverTime: 'Evaluar tiempo y días de entrega',
        productQuantity: 'Cantidad',
        soldQuantity: "Volumen de ventas",
        collections: 'Cantidad de pedido',
        since: 'Miembro desde',
        shipper: 'remitente rápido',
        responder: 'Respuesta rápida',
        reliable: 'confiable',
        btnContact: 'Contacto',
        enter: 'Entrar',
        followBtn: 'Seguir',
        guessLike: 'Supongo que te gusta',
        buyNow: 'Comprar Ahora',
        dialogOverviewTitle: 'Descripción general',
        overConditionLabel: 'Condición',
        overConditionContent: 'Nuevo',
        overBrandLabel: 'Marca',
        overCategoryLabel: 'Categoría',
        overPayMethodsLabel: 'Pagos admitidos',
        payTitle: 'Pagos admitidos',
        payHint: 'El color es hermoso, se ve vouno y elegante. Se puede usar con toda una amplia gama de ropa. Lo hace muy hermoso. Es perfecto para combinar con ropa informal y de moda',
        overCategoryEnter: 'Entrar',
        dialogFreightTitle: 'Flete',
        dialogAgreementTitle: 'Acuerdo de protección del comprador',
        commitContent: 'Elogio predeterminado del sistema',
        footerLeft: 'Agregar al carrito',
        footerRight: 'Comprar ahora'
    },
    'specificationDialog': {
        inventory: 'Inventario',
        countLabel: 'Número',
        specLabel: 'Predeterminado',
        options1: 'Agregar al carrito',
        options2: 'Comprar ahora',

    },
    "appShopsDetail": {
        select1: 'Completo',
        select2: 'Volumen de ventas',
        select4: 'Precio mayor a menor',
        select5: 'Precio de menor a mayor',
        select6: 'Puntuación',
        follow: 'sigue',
        timeDay: 'Evaluar tiempo y días de entrega',
        productQuantity: 'Cantidad',
        shippedQuantity: "Volumen de ventas",
        collections: 'Cantidad de pedido',
        commodity: 'Producto',
        classAll: 'Todos'
    },
    // 产品评论页
    'appProductReviews': {
        navTitle: 'Comentario',
        tags1: 'todos',
        tags2: '5 estrellas',
        tags3: '4 estrellas',
        tags4: '3 estrellas',
        tags5: '2 estrellas',
        tags6: '1 estrella',
        tags7: 'Con medios',
        commitContent: 'Elogio predeterminado del sistema'
    },
    // app升级配置文件语言
    configLanguage: {
        upgradePrompt: 'Actualización de versión, ¿confirmar? ',
        loadingTitle: 'Descargando...',
        systemUpgrade: 'Actualización del sistema',
        upgradeImmediately: 'Confirmar',
    },
    // 公共提示
    messageLanguage: {
        loadingTitle: "Cargando", // Cargando
        finishedText: "Ninguno", //Ninguno
        loadingText: "Cargando", // Cargando
        nullText: 'Aún no hay datos',
        loadingFinished: "Carga completa",
        listNullText: 'No más',
        pullIngText: 'Desplegar hacia abajo para actualizar',
        loosingText: 'Liberar para actualizar',
        pullingSuccess: 'Carga desplegable exitosa'
    },
    // 'appSearchHistory': {
    //   navTitle: 'Search',
    //   searchPlace: 'Search...',
    //   recommendTitle: 'Recommend'
    // },
    // 'appClassList': {
    //   searchPlace: 'Search...',
    //   select1: 'Comprehensive',
    //   select2: 'Sales Volume',
    //   select3: 'New product',
    //   select4: 'Price High to Low',
    //   select5: 'Price Low to High',
    //   select6: 'Score',
    //   paymentTitle: 'payment',
    //   listTag1: 'Free Shipping',
    //   favoriteTitle: 'Favorite Quantity &',
    //   shopBtn: 'View Closets',
    //   recommendNavTitle: 'Recommend',
    //   usedNavTitle: 'Used',
    //   shopNavTitle: 'Shop'
    // },

    // 'appPreSale': {
    //   navTitle: 'Presale',
    //   tabs1: 'Started',
    //   tabs2: 'Not Started',
    //   tabs3: 'Ended',
    //   searchPlace: 'Search...',
    //   select1: 'Comprehensive',
    //   select2: 'Sales Volume',
    //   select3: 'New product',
    //   select4: 'Price High to Low',
    //   select5: 'Price Low to High',
    //   select6: 'Score',

    //   preSale: 'Presale',
    //   freeShipping: 'Free Shipping',
    //   payment: 'payment',
    //   preSale: 'presale',
    //   freeShipping: "Free Shipping"
    // },
    // "appGroupBuying": {
    //   navTitle: 'Group Buying',
    //   searchPlace: 'Search...',
    //   select1: 'Comprehensive',
    //   select2: 'Sales Volume',
    //   select3: 'New product',
    //   select4: 'Price High to Low',
    //   select5: 'Price Low to High',
    //   select6: 'Score',

    //   preSale: 'Presale',
    //   freeShipping: 'Free Shipping',
    //   payment: 'payment',
    //   btn1: 'Group Buying',
    //   btn2: "Free Shipping"
    // },
    // "appFlashSale": {
    //   navTitle: 'Flash Sale',
    //   searchPlace: 'Search...',
    //   select1: 'Comprehensive',
    //   select2: 'Sales Volume',
    //   select3: 'New product',
    //   select4: 'Price High to Low',
    //   select5: 'Price Low to High',
    //   select6: 'Score',

    //   preSale: 'Presale',
    //   freeShipping: 'Free Shipping',
    //   payment: 'payment',
    //   preSale: 'presale',
    //   freeShipping: "Free Shipping"
    // },
};
export default obj;
