<template>
  <div class="default-img">
    <svg-icon icon-class="app-default"></svg-icon>
    <!-- <P>{{ language.defaultTitle }}</P> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      language: this.$language('messageLanguage'),
    }
  },
}
</script>

<style lang="scss" scoped>
.default-img {
  width: 100%;
  height: 100%;
  padding-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  svg {
    width: 50% !important;
    height: 50% !important;
  }
  p {
    font-size: 32px;
    color: $size8f;
  }
}
</style>
