// 安卓app下载地址
const downloadAndroidAppUrl = 'https://overseas-mall.oss-cn-hangzhou.aliyuncs.com/download/get-trx.apk'
// 苹果app下载地址
const downloadIosAppUrl = 'https://overseas-mall.oss-cn-hangzhou.aliyuncs.com/download/get-trx.apk'

const baseUrl = 'https://om-manage.suanlajizhen.com/mall_v250'// api请求
const imgUrl = 'https://image.nametw.com/' // 图片域名
// 店铺域名
const storeHost = 'http://localhost:8080/';
// 语言转换
const lang = {
  null: "english",
  English: "english",
  日本語: "japanese",
  Português: "portuguese",
  繁體中文: "chineseTraditional",
  Français: "french",
  Deutsch: "german",
  Türk: "turkish",
  Español: "spanish",
  عربي: "arabic",
  한국인: 'korean',
  Suomi: 'finland',
  Русский: 'russian',
  italiano: 'italian',
  แบบไทย: 'thai',
  Nederlands: 'dutch',
}

/*******************************************************************/
// 店铺注册页
const shopRegisterWebUrl = '#/join-index?roteType=1'

// 店铺登录页
const shopLoginWebUrl = '#/login/loginIndex'

export default {
  imgUrl,
  baseUrl,
  lang,
  downloadAndroidAppUrl,
  downloadIosAppUrl,
  storeHost,
  shopRegisterWebUrl,
  shopLoginWebUrl

}
