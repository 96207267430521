// 荷兰语

let obj = {};
obj = {
  // 语言
  languageVersion: [
    'English',  // 英语
    '日本語', // 日语
    'Português', // 葡萄牙语
    '繁體中文', // 繁体中文
    'Français', // 法语
    'Deutsch', // 德语
    'Türk', // 土耳其语
    'Español', // 西班牙语
    'عربي', // 阿拉伯语
    '한국인', // 韩语
    'Suomi', // 芬兰语
    'Русский', // 俄语
    'italiano', // 意大利语
    'แบบไทย', // 泰语
    'Nederlands', // 荷兰语
  ],
  'footer': {
    home: "Thuis",
  },
  'setDrawer': {
    label1: 'Meertalige instellingen',
    label2: 'Winkelregistratie',
    label3: 'Winkel inloggen',
    label4: 'klantenservice',
    label5: 'Download de Android-app',
    label6: 'ios-app downloaden',
    optionsBtn: 'Annuleren'
  },
  'appHome': {
    searchPlace: 'Zoeken...',
    recommendTitle: 'Aanbevelen',
    viewAll: 'alles bekijken',
    usedTitle: 'Gebruikt',
    shopTitle: 'Winkel',
    shopOption: 'Nu bekijken',
    hotTitle: 'Hotlijst',
    classTag1: 'Thuis',
    classTag2: 'Vrouwen',
    classTag3: 'Mannen',
    classTag4: 'Kinderen',
    classTag5: 'Elektronica',
    classTag6: 'Huisdieren',
    buyNow: 'Koop nu',

    // buyNow: 'Buy Now',
    // ranking: 'Sales Ranking',
    // preSaleTitle: 'Presale',
    // preSaleTabs1: 'Started',
    // preSaleTabs2: 'Not started',
    // preSaleTabs3: 'Ended',
    // groupTitle: 'Group Buying',
    // peopleGroupBuying: '人参与团购',
    // flashSaleTitle: 'Flash Sale',
  },
  'appSetLanguage': {
    navTitle: 'Meertalige instellingen',
    affirm: 'bevestigen',
  },
  "appService": {
    navTitle: 'Klantenservice',
    customerService: 'Exclusieve klantenservice',
    helloTitle: 'Hallo',
    welcomeTitle: 'Ik ben van jou<, je kunt contact met mij opnemen via de volgende kanalen',
    iconInformation: 'Selecteer communicatiesoftware voor advies',
    whatsApp: 'whatsApp ',
    telegram: 'telegram ',
    line: 'line',
  },
  // 移动分类页
  'appClassification': {
    navTitle: 'Commodity Classification',
    viewAll: 'Alles bekijken',
  },
  'appHotList': {
    navTitle: 'Hotlijst',
    ranking: 'Verkooprangschikking',
  },
  'appHotRanking': {
    navTitle: 'Accenten verkoopranglijst',
    searchPlace: 'Zoeken...',
    payment: 'betaling',
    optionBtn1: 'Verzamelen',
    optionBtn2: 'Koop nu',
    errorMessage: 'Log in',
  },
  'appUsedList': {
    navTitle: 'Gebruikt',
    recommendNavTitle: 'Aanbevelen',
    classNavTitle: 'Classificatie',
    searchNavTitle: 'Zoeken',
    searchPlace: 'Zoeken...',
    select1: 'Uitgebreid',
    select2: 'Verkoopvolume',
    select3: 'Nieuw product',
    select4: 'Prijs hoog naar laag',
    select5: 'Prijs laag naar hoog',
    select6: 'Score',
    classAll: 'Alles',
    listTag1: 'Gratis verzending',
    paymentTitle: 'Betaling'
  },
  'appRecommendList': {
    navTitle: 'Aanbevelen',
    searchPlace: 'Zoeken...',
    select1: 'Uitgebreid',
    select2: 'Verkoopvolume',
    select3: 'Nieuw product',
    select4: 'Prijs hoog naar laag',
    select5: 'Prijs laag naar hoog',
    select6: 'Score',
    classAll: 'Alles',
    listTag1: 'Gratis verzending',
    paymentTitle: 'Betaling'
  },
  'appLogin': {
    navTitle: 'Inloggen',
    navTitleHint: 'Log nu in en begin met handelen met vrienden van over de hele wereld!',
    singUp: 'Aanmelden',
    emailLabel: 'Telefoonnummer',
    emailPlaceholder: 'Voer telefoonnummer in',
    emailErrorText: 'Foutprompt',
    emailNullVerify: 'Voer telefoonnummer in',//请输入邮箱
    passwordLabel: 'Uw wachtwoord',
    passPlaceholder: 'Voer wachtwoord in',
    passNullVerify: 'Voer uw wachtwoord in',//请输入密码
    passErrorVerify: 'Het wachtwoord is minder dan 6 tekens',//密码小于6位
    placeholder: 'Voer alstublieft in',
    emailRegistration: 'Telefoonnummerregistratie',
    forgetPassTitle: 'Wachtwoord vergeten?',
    submitBtnTitle: 'Inloggen',
    errorMessage: 'Onjuiste gebruikersnaam of wachtwoord',
    // emailErrorVerify: '邮箱格式错误！',//邮箱格式错误
  },
  'appForgotPass': {
    navTitle: 'Wachtwoord vergeten',
    navTitleHint: 'Schrijf uw mobiele telefoonnummer op om een bevestigingscode te ontvangen waarmee u een nieuw wachtwoord kunt instellen.',
    emailLabel: 'Telefoonnummer',
    emailPlaceholder: 'Voer telefoonnummer in',
    emailErrorText: 'Voer telefoonnummer in',//请输入邮箱
    emailNullVerify: 'Voer telefoonnummer in',//请输入邮箱
    emailErrorVerify: 'Verkeerd mobiel nummer',//邮箱格式错误
    verificationCode: 'Verificatiecode',
    verificationPlace: 'Verificatiecode',
    verificationCodeError: 'Voer de verificatiecode in',
    sendTitle: 'Bevestig mobiel nummer',
    sendText: 'Hervalidatie',
    passwordLabel: 'Wachtwoord',
    passPlaceholder: 'Voer wachtwoord in',
    confirmPassLabel: 'Wachtwoord bevestigen',
    confirmPlace: 'placeholder bevestig wachtwoord',
    passNullVerify: 'Voer uw wachtwoord in',//请输入密码
    passErrorVerify: 'Wachtwoord is minder dan 6 tekens',//密码小于6位
    passCompareErrorVerify: 'Wachtwoorden zijn inconsistent',
    passCompareErrorNull: 'Voer het wachtwoord opnieuw in',
    emailRegistration: 'Telefoonnummerregistratie',
    forgetPassTitle: 'Wachtwoord vergeten?',
    submitBtnTitle: 'Wachtwoord opnieuw instellen',
    submitErrorMessage: 'Onjuiste verificatiecode'
  },
  'appRegister': {
    navTitle: 'Register',
    navTitleHint: 'Create an account to access all features!',
    firstNameLabel: 'First Name',
    firstNamePlace: 'Please enter',
    firstNameErrorVerify: 'Please enter your first name',
    lastNameLabel: 'Last Name',
    lastNameErrorVerify: 'Please enter your last name',
    nickNameLabel: 'Nick Name',
    nickNameErrorVerify: 'Please enter your nick name',
    namePlace: 'Please enter',
    gender: 'Gender',
    gender1: 'Boy',
    gender2: 'Girl',
    gender3: 'Secrecy',
    genderError: 'Please enter your gender',
    emailLabel: 'Phone number',
    emailPlaceholder: 'Please enter phone number!',
    emailErrorText: 'Error message!',//请输入邮箱
    emailNullVerify: 'Please enter phone number!',//请输入邮箱
    verificationCode: 'Verification Code',
    verificationPlace: 'Verification Code',
    sendTitle: 'Confirm mobile number',
    sendText: 'Revalidation',
    verificationCodeError: 'Verification codecannot be empty',
    passwordLabel: 'Password',
    passPlaceholder: 'Please input password',
    confirmPassLabel: 'Confirm Password',
    confirmPlace: 'placeholder confirm password',
    passNullVerify: 'enter your password',//请输入密码
    passErrorVerify: 'Password is less than 6 characters！',//密码小于6位
    passCompareErrorVerify: 'Passwords are inconsistent！',
    passCompareErrorNull: 'Please enter password again',
    registerHint: 'Already have an account?',
    registerHintLogin: 'Login',
    submitBtnTitle: 'Register',
    submitErrorMessage: 'Incorrect verification code'
    // emailErrorVerify: '邮箱格式错误!',//邮箱格式错误
  },
  "appShopList": {
    navTitel: 'Winkellijst',
    searchPlace: 'Zoeken...'
  },
  'appGoodsDetail': {
    salesTitle: 'Verkoop',
    conditionLabel: 'Voorwaarde:',
    conditionContent: 'Categorie-ondersteunde betalingen',
    agreement: 'Kopersbeschermingsovereenkomst',
    specSelect: 'Selecteer specificaties',
    payLabel1: 'Saldo',
    payLabel2: 'PayPal',
    payLabel3: 'Visum',
    commentTitle: 'Commentaar',
    frightLabel: 'Vracht',
    frightContent: 'Verzonden gemiddeld binnen 8 dagen',
    deliverTime: 'Evalueer de levertijd en dagen',
    productQuantity: 'Aantal ',
    soldQuantity: "Verkoopvolume",
    collections: 'Aantal bestellingen',
    since: 'Lid sinds',
    shipper: 'Snelle verzender',
    responder: 'Snelle responder',
    reliable: 'Betrouwbaar',
    btnContact: 'Contact',
    enter: 'Invoeren',
    followBtn: 'Volgen',
    guessLike: 'Ik denk dat je het leuk vindt',
    buyNow: 'Koop nu',
    dialogOverviewTitle: 'Overzicht',
    overConditionLabel: 'Conditie',
    overConditionContent: 'Nieuw',
    overBrandLabel: 'Merk',
    overCategoryLabel: 'Categorie',
    overPayMethodsLabel: 'Ondersteunde betalingen',
    payTitle: 'Ondersteunde betalingen',
    payHint: 'De kleur is mooi, hij ziet er mooi en stijlvol uit. Hij kan bij alle soorten kleding worden gedragen. Hierdoor wordt hij heel mooi. Hij is perfect om te combineren met calsua en modieuze kleding',
    overCategoryEnter: 'Enter',
    dialogFreightTitle: 'Vracht',
    dialogAgreementTitle: 'Kopersbeschermingsovereenkomst',
    commitContent: 'Systeem standaard lof',
    footerLeft: 'Toevoegen aan winkelwagen',
    footerRight: 'Koop nu'
  },
  'specificationDialog': {
    inventory: 'Inventaris',
    countLabel: 'Nummer',
    specLabel: 'Standaard',
    options1: 'Toevoegen aan winkelwagen',
    options2: 'Koop nu',

  },
  "appShopsDetail": {
    select1: 'Uitgebreid',
    select2: 'Verkoopvolume',
    select4: 'Prijs hoog naar laag',
    select5: 'Prijs laag naar hoog',
    select6: 'Score',
    follow: 'Volgen',
    timeDay: 'Evalueer levertijd en dagen',
    productQuantity: 'Aantal ',
    shippedQuantity: "Verkoopvolume",
    collections: 'Aantal bestellingen',
    commodity: 'Goederen',
    classAll: 'Alles'
  },
  // 产品评论页
  'appProductReviews': {
    navTitle: 'Commentaar',
    tags1: 'alle',
    tags2: '5 sterren',
    tags3: '4 sterren',
    tags4: '3 sterren',
    tags5: '2 sterren',
    tags6: '1 ster',
    tags7: 'Met media',
    commitContent: 'Systeem standaard lof'
  },
  // app升级配置文件语言
  configLanguage: {
    upgradePrompt: 'Versie-upgrade, bevestig alstublieft? ',
    loadingTitle: 'Downloaden...',
    systemUpgrade: 'Systeemupgrade',
    upgradeImmediately: 'Bevestigen',
  },
  // 公共提示
  messageLanguage: {
    loadingTitle: "Laden", // Laden
    finishedText: "Geen", //Geen
    loadingText: "Laden", // Laden
    nullText: 'Nog geen gegevens',
    loadingFinished: "Laden voltooid",
    listNullText: 'Niet meer',
    pullIngText: 'Naar beneden trekken om te vernieuwen',
    loosingText: 'Laat los om te vernieuwen',
    pullingSuccess: 'Pull-down laden succesvol'
  },
  // 'appSearchHistory': {
  //   navTitle: 'Search',
  //   searchPlace: 'Search...',
  //   recommendTitle: 'Recommend'
  // },
  // 'appClassList': {
  //   searchPlace: 'Search...',
  //   select1: 'Comprehensive',
  //   select2: 'Sales Volume',
  //   select3: 'New product',
  //   select4: 'Price High to Low',
  //   select5: 'Price Low to High',
  //   select6: 'Score',
  //   paymentTitle: 'payment',
  //   listTag1: 'Free Shipping',
  //   favoriteTitle: 'Favorite Quantity &',
  //   shopBtn: 'View Closets',
  //   recommendNavTitle: 'Recommend',
  //   usedNavTitle: 'Used',
  //   shopNavTitle: 'Shop'
  // },

  // 'appPreSale': {
  //   navTitle: 'Presale',
  //   tabs1: 'Started',
  //   tabs2: 'Not Started',
  //   tabs3: 'Ended',
  //   searchPlace: 'Search...',
  //   select1: 'Comprehensive',
  //   select2: 'Sales Volume',
  //   select3: 'New product',
  //   select4: 'Price High to Low',
  //   select5: 'Price Low to High',
  //   select6: 'Score',

  //   preSale: 'Presale',
  //   freeShipping: 'Free Shipping',
  //   payment: 'payment',
  //   preSale: 'presale',
  //   freeShipping: "Free Shipping"
  // },
  // "appGroupBuying": {
  //   navTitle: 'Group Buying',
  //   searchPlace: 'Search...',
  //   select1: 'Comprehensive',
  //   select2: 'Sales Volume',
  //   select3: 'New product',
  //   select4: 'Price High to Low',
  //   select5: 'Price Low to High',
  //   select6: 'Score',

  //   preSale: 'Presale',
  //   freeShipping: 'Free Shipping',
  //   payment: 'payment',
  //   btn1: 'Group Buying',
  //   btn2: "Free Shipping"
  // },
  // "appFlashSale": {
  //   navTitle: 'Flash Sale',
  //   searchPlace: 'Search...',
  //   select1: 'Comprehensive',
  //   select2: 'Sales Volume',
  //   select3: 'New product',
  //   select4: 'Price High to Low',
  //   select5: 'Price Low to High',
  //   select6: 'Score',

  //   preSale: 'Presale',
  //   freeShipping: 'Free Shipping',
  //   payment: 'payment',
  //   preSale: 'presale',
  //   freeShipping: "Free Shipping"
  // },
};
export default obj;
